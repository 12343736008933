import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Grid,
  Button,
  ListItemText,
} from "@material-ui/core/";
import Ionicon from "react-ionicons";

const FeedbackUrl = (props) => {
  useEffect(() => {
    window.close();
  }, []);

  return <div>From Interswitch</div>;
};

export default FeedbackUrl;
