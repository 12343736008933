import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import FeedbackUrl from "../components/redirect.feedback";
import Dashboard from "./../components/dashboard";
import Login from "./login";

const isAuth = Boolean(localStorage.getItem("jwTtoken"));

const AuthRoute = ({ component: Component, ...rest }) => {
  if (isAuth) {
    window.location.replace("/ordering");
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    );
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (!isAuth) {
    window.location.replace("/login");
  } else {
    return (
      <>
        <Route
          {...rest}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      </>
    );
  }
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Redirect from="/" exact to="/ordering" />
        <AuthRoute path="/login" component={Login} />
        <PrivateRoute path="/ordering" component={Dashboard} />
        <PrivateRoute path="/feedback" component={FeedbackUrl} />
      </Switch>
    </Router>
  );
};

export default Routes;
