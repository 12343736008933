import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import DadShop from "./dadshop";
import ProductDialog from "./product.dialog";
import Loader from "react-loader-spinner";

// Configs
import { BASE_URL } from "../app.config";

const DaDMenu = (props) => {
  const classes = useStyles();

  const [menu, setMenu] = useState();
  const [specials, setSpecials] = useState();
  const [brandSpecials, setBrandSpecials] = useState([]);
  const [specialsMenu, setSpecialsMenu] = useState([]);
  const [brandCategories, setBrandCategories] = useState([]);
  const [brandCategoriesItems, setBrandCategoriesItems] = useState([]);
  const [brand, setBrand] = useState({});
  const [product, setProduct] = useState();
  const [value, setValue] = useState(props.value);
  const [productDialog, setProductDialog] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const getData = async (url, data) => {
    const response = await fetch(url, {
      method: "GET",
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return result;
  };

  const addProduct = (product) => {
    setProduct(product);
    setProductDialog(true);
  };

  const clearProduct = () => {
    setProduct(null);
    setProductDialog(false);
  };

  useEffect(() => {
    if (props.menu.brand.length === 1) {
      setMenu(props.menu);
      setSpecials(props.specials);
      addBrandMenu(props.menu, props.specials, props.brands[props.value]);
    }
  }, [props.menu, props.specials, props.brands, props.value]);

  const addBrandMenu = (_menu, _specials, _value) => {
    try {
      if (_menu.brand) {
        const bnd = _menu.brand.filter((f) => f.uuid === _value.uuid)[0];

        setBrand(bnd);

        try {
          if (bnd) {
            if (_specials.length > 0 && bnd.specials) {
              let _brandSpecials = [];
              let _specialsMenu = [];
              bnd.specials.map((_brandSpecial) =>
                _specials
                  .filter((f) => f.uuid === _brandSpecial)
                  .map((bs) => _brandSpecials.push(bs))
              );

              _brandSpecials.map((special) =>
                _specialsMenu.push(
                  ..._menu.items.filter((f) => f.uuid === special.items)
                )
              );

              setBrandSpecials(_brandSpecials);
              setSpecialsMenu(_specialsMenu);
            } else {
              setBrandSpecials([]);
              setSpecialsMenu([]);
            }

            if (_menu) {
              setBrandCategories(
                _menu.categories.filter((f) => f.brand === bnd.uuid)
              );

              _menu.categories
                .filter((f) => f.brand === bnd.uuid)
                .map((category) => {
                  setBrandCategoriesItems((bci) => [
                    ...bci,
                    ..._menu.items.filter((f) =>
                      category.items.includes(f.uuid)
                    ),
                  ]);
                  return true;
                });
              setLoading(false);
            }
          }
        } catch (error) {}
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getMenu = async (index) => {
    try {
      const bnd = props.brands[index];

      if (bnd && !menu.brand.find(({ uuid }) => uuid === bnd.uuid)) {
        const mode =
          props.deliveryMode === "instore" ? "in_store" : props.deliveryMode;
        const url = `${BASE_URL}/menu/${bnd.uuid}/${mode}/callcenter/${props.complex.uuid}`;
        const response = await getData(url);

        if (response) {
          if (response.specials) {
            setSpecials((specials) => [...specials, ...response.specials]);
          }

          const mu = {
            brand: [...menu.brand, ...(response.brand ?? [])],
            categories: [...menu.categories, ...(response.categories ?? [])],
            items: [...menu.items, ...(response.items ?? [])],
            groups: [...menu.groups, ...(response.groups ?? [])],
            sizes: [...menu.sizes, ...(response.sizes ?? [])],
            options: [...menu.options, ...(response.options ?? [])],
          };

          mu.groups = await getUniqueItems(mu.groups);
          mu.options = await getUniqueItems(mu.options);
          mu.items = await getUniqueItems(mu.items);
          mu.sizes = await getUniqueItems(mu.sizes);
          mu.categories = await getUniqueItems(mu.categories);

          const specials = response.specials ?? [];

          setMenu(mu);
          addBrandMenu(mu, specials, bnd);
          props.setMenu(mu);
        } else {
          setLoading(false);
        }
      } else {
        addBrandMenu(menu, specials, bnd);
      }
    } catch (error) {}
  };

  const getUniqueItems = async (items) => {
    const array = [];

    items.forEach((item) => {
      const added = array.find(({ uuid }) => uuid === item.uuid);

      if (!added) {
        array.push(item);
      }
    });

    return array;
  };

  const handleChange = (event, _value) => {
    if (value !== _value && props.brands[_value].available) {
      setLoading(true);
      setBrand({});
      setSpecialsMenu([]);
      setBrandSpecials([]);
      setBrandCategories([]);
      setBrandCategoriesItems([]);
      getMenu(_value);
      setValue(_value);
    }
  };

  const renderTabContent = () => {
    let b = brand && brand.name;
    if (props.change) {
      brandSpecials.length = 0;
      specialsMenu.length = 0;
      brandCategories.length = 0;
      brandCategoriesItems.length = 0;
      b = "";
    }

    if (brand) {
      return (
        <DadShop
          addProduct={addProduct}
          specials={brandSpecials.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          specialsMenu={specialsMenu.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          brand={b}
          categories={brandCategories.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          brandCategoriesItems={brandCategoriesItems.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          availableCurrency={props.availableCurrency}
          currency={props.currency}
        />
      );
    }
  };

  const renderTab = (bnd, i, classes) => {
    return (
      <Tab
        key={bnd.name}
        disableRipple
        style={{ backgroundColor: bnd.bgcolor, opacity: !bnd.available && 0.3 }}
        className={classes.tabHeader}
        icon={
          <img width="60" height="60" src={bnd.logo.en} alt={bnd.name.en} />
        }
      ></Tab>
    );
  };

  return (
    <div>
      {props.brands && props.brands.length > 0 && (
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ backgroundColor: "#f4f4f4" }}
        >
          {props.brands.map((bnd, i) => renderTab(bnd, i, classes))}
        </Tabs>
      )}

      {!loading ? (
        renderTabContent()
      ) : (
        <div style={{ textAlign: "center", backgroundColor: "#f4f4f4" }}>
          <Loader type="Oval" color="grey" width="30" height="30" />
        </div>
      )}
      {product && (
        <ProductDialog
          brand={brand}
          menu={menu}
          item={product}
          open={productDialog}
          onAddCartIterm={props.onAddCartIterm}
          close={clearProduct}
          classes={classes}
          availableCurrency={props.availableCurrency}
          currency={props.currency}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FAFAFA",
  },
  flex: {
    flex: 1,
  },
  body: {
    flex: 1,
    alignSelf: "center",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    alignSelf: "center",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabHeader: {
    height: 60,
    width: 60,
    minWidth: 0,
  },
  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    height: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  tabSelected: {},
  list: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
    marginLeft: 24,
    marginRight: 24,
    padding: 8,
  },
  quantityItem: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
    marginLeft: 24,
    marginRight: 24,
  },
}));

export default DaDMenu;
