import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  IconButton,
} from "@material-ui/core/";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "../../app.config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VouchersDialog = ({ open, vouchers, enabled, addVoucher, close }) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="sm"
      disableEscapeKeyDown
      disableBackdropClick
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          minWidth: 400,
        },
      }}
    >
      <DialogTitle className={classes.header}>
        <div align="right">
          <IconButton onClick={close} className={classes.closeBtn}>
            <Close />
          </IconButton>
        </div>
        <div
          style={{
            marginTop: -20,
          }}
        >
          Vouchers
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0,
        }}
        className={classes.root}
      >
        <Grid container>
          <div
            style={{
              display: "block",
              paddingLeft: 16,
              paddingRight: 16,
              marginTop: 20,
              width: "100%",
            }}
          >
            {vouchers.map((voucher, i) => (
              <div
                style={{
                  border: "1px solid grey",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 10,
                  paddingLeft: 10,
                  marginBottom: 10,
                  borderRadius: 5,
                  backgroundColor: enabled
                    ? voucher.enabled
                      ? "#ffff"
                      : "grey"
                    : "#ffff",
                  boxShadow: "0 0 0 0.2rem transparent",
                  cursor: "pointer",
                }}
                key={i}
                onClick={() => {
                  if (enabled && voucher.enabled) {
                    addVoucher(voucher);
                  }
                }}
              >
                <Grid container>
                  <Grid item sm={2} style={{ paddingTop: 10 }}>
                    <img
                      height="50"
                      width="50"
                      key={voucher.item_name}
                      src={voucher.brand_logo}
                      alt=""
                    />
                  </Grid>
                  <Grid item sm={10}>
                    <div style={{ marginBottom: 2, fontWeight: "bold" }}>
                      {voucher.item_name}
                    </div>

                    <div style={{ marginBottom: 2, fontSize: 13 }}>
                      {voucher.prize_rules_description}
                    </div>

                    <div style={{ marginBottom: 2, fontSize: 13, color: red }}>
                      {voucher.brand_name}
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
  flex: {
    flex: 1,
  },
  header: { backgroundColor: "#333333", color: "#fff", paddingTop: 8 },
  content: {
    backgroundColor: "#f4f4f4",
    paddingLeft: 80,
    paddingRight: 80,
  },
  closeBtn: {
    backgroundColor: "#666666",
    height: 20,
    width: 20,
    fontSize: 15,
    padding: 0,
    marginTop: 15,
    color: "#ffff",
  },

  toolbar: theme.mixins.toolbar,
  logo: {
    alignSelf: "center",
  },
}));

export default VouchersDialog;
