const countryCodes = require("./country.codes");

exports.phoneSanitizer = (value) => {
  value = value.replace(" ", "");

  countryCodes.forEach((element) => {
    if (element.dial_code) {
      const _code = element.dial_code.replace("+", "");

      if (value.search(_code) === 1) {
        let _value = value.slice(_code.length + 1);

        _value = _value.replace("+", "");

        if (_value[0] === "0") {
          _value = _value.replace("0", "");
        }

        if (_value[0] === "0") {
          _value = _value.replace("0", "");
        }

        if (_value.search(_code) === 0) {
          _value = _value.slice(_code.length);
        }

        if (_value[0] === "0") {
          _value = _value.replace("0", "");
        }

        value = element.dial_code + _value;
      }
    }
  });

  return value;
};
