import React from "react";
import { Grid } from "@material-ui/core";
import dividerLastDefault from "../assets/dividerLastDefault.png";
import dividerLastGreen from "../assets/dividerLastGreen.png";
import dividerFirstGreen from "../assets/dividerFirstGreen.png";
import dividerDefault from "../assets/dividerDefault.png";
import dividerGreen from "../assets/dividerGreen.png";

const Progress = ({ time, title, mode, last, first }) => {
  const image = () => {
    let img = null;

    if (mode === "default") {
      img = last ? dividerLastDefault : dividerDefault;
    } else {
      img = last ? dividerLastGreen : dividerGreen;

      if (first) img = dividerFirstGreen;
    }
    return img;
  };

  return (
    <Grid
      container
      style={{ fontSize: "0.8em", marginRight: 8, marginLeft: 8 }}
    >
      <Grid item xs={3}>
        <div style={{ paddingTop: "50%", paddingBottom: "50%" }}>{time}</div>
      </Grid>
      <Grid item xs={2}>
        <img src={image()} style={{ width: 30 }} alt={title} />
      </Grid>
      <Grid item xs={7}>
        <div
          style={{ paddingTop: "25%", paddingBottom: "25%", paddingLeft: 8 }}
        >
          {title === "Order Collected" ? "Collected" : title}
        </div>
      </Grid>
    </Grid>
  );
};

export default Progress;
