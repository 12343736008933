import jwtDecode from "jwt-decode";
import { logout, setCurrentUser } from "../redux/actions/auth.actions";
import store from "../redux/store";

export const checkToken = () => {
  // Check for token
  if (localStorage.jwTtoken) {
    // Decode token and get user info and exp
    const decoded = jwtDecode(localStorage.jwTtoken);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logout());
    }
  }
};
