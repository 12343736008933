import React, { useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  Card,
  Tab,
  Tabs,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core/";
import Ionicon from "react-ionicons";
import { ArrowForwardIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import promo_image from "../assets/no_image_promos_dad.jpg";
import { getItemDescription, getItemName, getItemPrice } from "../utils/item";

const DaDShop = (props) => {
  const classes = useStyles();

  return (
    <div>
      {props.specialsMenu.length > 0 ? (
        <div style={{ paddingRight: 8, paddingLeft: 8 }}>
          <Tabs
            value={0}
            scrollButtons="on"
            variant="scrollable"
            classes={{ indicator: classes.indicator }}
          >
            {props.specialsMenu.map((item, i) => {
              return props.specials
                .filter((f) => f.items === item.uuid)
                .map((special) => {
                  return (
                    <Tab
                      key={item.id}
                      disableRipple
                      onClick={() =>
                        props.addProduct({
                          ...item,
                          brand: props.brand,
                        })
                      }
                      style={{ opacity: 1 }}
                      icon={
                        <Card
                          style={{
                            borderRadius: 8,
                          }}
                        >
                          <img
                            style={{
                              height: "80px",
                              width: "250px",
                            }}
                            src={special.special_image.en ?? promo_image}
                            alt={getItemName(item)}
                          />
                          <ListItem
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            <ListItemText
                              primary={
                                <span style={{ paddingRight: 80 }}>
                                  {getItemName(item)}
                                </span>
                              }
                              className={classes.primary}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                className={classes.primary}
                                primary={
                                  <span>
                                    {getItemPrice(item, props.currency)}
                                    <ArrowForwardIos />
                                  </span>
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Card>
                      }
                    />
                  );
                });
            })}
          </Tabs>
        </div>
      ) : null}
      <List>
        {props.categories.map((category, i) => {
          let t = {};
          let items = props.brandCategoriesItems.filter(
            (e) => !(t[e.id] = e.id in t)
          );
          return (
            <MenuItem
              addProduct={props.addProduct}
              key={i}
              items={items}
              category={category}
              brand={props.brand}
              classes={classes}
              currency={props.currency}
              availableCurrency={props.availableCurrency}
            />
          );
        })}
      </List>
    </div>
  );
};

const MenuItem = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const submit = (item) => {
    props.addProduct(item);
  };

  return (
    props.category.name.en.toLowerCase() && (
      <ExpansionPanel
        expanded={!collapsed}
        onChange={() => setCollapsed(!collapsed)}
        disabled={!props.category.available}
      >
        <ExpansionPanelSummary
          style={{
            backgroundColor: "#f4f4f4",
            boxShadow: "none",
            minHeight: 40,
          }}
        >
          <Ionicon
            style={{ marginRight: 30, width: 20, height: 20 }}
            icon={collapsed ? "ios-arrow-forward" : "ios-arrow-down"}
          />

          <ListItemText
            className={props.classes.listText}
            primary={getItemName(props.category)}
          />
          {!props.category.available && (
            <ListItemSecondaryAction>
              <ListItemText
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  color: "#f4f4f4",
                  backgroundColor: "#d9534f",
                  borderRadius: 8,
                }}
                primary={<div>Unavailable</div>}
              />
            </ListItemSecondaryAction>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <List style={{ width: "100%" }}>
            {props.category.items.map((c) =>
              props.items
                .filter((f) => f.uuid === c)
                .map((item, i) => (
                  <ListItem
                    disabled={!item.available}
                    divider={true}
                    style={{
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 40,
                      paddingRight: 150,
                    }}
                    className={props.classes.btn}
                    button
                    key={item.id + i}
                    onClick={() =>
                      submit({
                        ...item,
                        category_name: props.category.name.en,
                      })
                    }
                  >
                    <ListItemText
                      className={props.classes.listText}
                      primary={
                        <div style={{ fontWeight: "bold", marginRight: 80 }}>
                          {getItemName(item)}
                        </div>
                      }
                      secondary={
                        <span style={{ paddingRight: 80 }}>
                          {getItemDescription(item)}
                        </span>
                      }
                    />
                    <ListItemSecondaryAction
                      style={{ right: item.available ? -5 : null }}
                    >
                      {!item.available && (
                        <ListItemText
                          style={{
                            paddingLeft: 8,
                            paddingRight: 8,
                            color: "#f4f4f4",
                            backgroundColor: "#d9534f",
                            borderRadius: 8,
                          }}
                          primary={<div>Unavailable</div>}
                        />
                      )}
                      {item.available && (
                        <List>
                          <ListItem>
                            <ListItemText
                              className={props.classes.listText}
                              primary={getItemPrice(item, props.currency)}
                            />
                            <Ionicon
                              style={{ marginLeft: 30 }}
                              icon="ios-arrow-forward"
                            />
                          </ListItem>
                        </List>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
            )}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    color: "#000",
    fontSize: 14,
  },
  btn: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  primary: {
    fontSize: 10,
    fontWeight: "bold",
  },
  listText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: "#FFF",
  },
  subtitle: {
    color: "#000",
    fontSize: 12,
  },
  warningMessage: {
    borderRadius: 4,
    backgroundColor: "#F99B1C",
    padding: 16,
    margin: 4,
    marginLeft: 4,
    alignSelf: "center",
  },
  cartBtn: {
    bottom: 8,
    left: 0,
    right: 0,
    position: "absolute",
    width: "96%",
    paddingHorizontal: 16,
    height: 48,
    backgroundColor: "#D71920",
  },
}));

export default DaDShop;
