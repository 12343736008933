/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Grid,
  Button,
  IconButton,
  ListItemText,
} from "@material-ui/core/";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import InputField from "../commons/input.field";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectComplexDialog = (props) => {
  const classes = useStyles();

  const _complexes = props.complexes.sort(function (a, b) {
    if (a.complex) {
      if (a.complex.name < b.complex.name) {
        return -1;
      }

      if (a.complex.name > b.complex.name) {
        return 1;
      }
      return 0;
    }

    if (a.name) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
  });

  const complex = props.complex
    ? props.complex
    : props.complexes.find((f) => f.complex_type === "primary");

  let s_complex = {};

  if (complex) {
    s_complex = complex;
  } else {
    if (props.complexes[0]) {
      s_complex = props.complexes[0];
    }
  }

  const [selectedComplex, setSelectedComplex] = useState([s_complex]);

  const [query, setQuery] = useState("");
  const [complexes, setComplexes] = useState(_complexes);

  useEffect(() => {
    let filter = [];
    if (query) {
      if (_complexes.length > 0) {
        if (_complexes[0].complex) {
          filter = _complexes.filter((f) =>
            f.complex.name.toLowerCase().includes(query)
          );
        }

        if (_complexes[0].name) {
          filter = _complexes.filter((f) =>
            f.name.toLowerCase().includes(query)
          );
        }
      }
    } else {
      filter = _complexes;
    }

    setComplexes([...filter]);
  }, [query]);

  const submit = () => {
    props.submit(selectedComplex[0]);
  };

  const addComplex = (complex) => {
    if (selectedComplex.length > 0) {
      selectedComplex.pop();
      setSelectedComplex([complex]);
    } else {
      setSelectedComplex([complex]);
    }
  };

  const checked = (complex) => {
    let check = selectedComplex.filter((f) => f.name === complex.name);
    return check.length > 0;
  };
  return (
    <Dialog
      maxWidth="md"
      disableEscapeKeyDown
      disableBackdropClick
      open={props.open}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          minWidth: 400,
        },
      }}
    >
      <DialogTitle>
        <div align="right">
          <IconButton onClick={props.close} className={classes.closeBtn}>
            <Close />
          </IconButton>
        </div>
        <div
          align="center"
          style={{
            marginTop: -20,
            textTransform: "uppercase",
          }}
        >
          {" "}
          SELECT YOUR COMPLEX
        </div>
        <div
          align="center"
          style={{ marginLeft: "10%", marginRight: "10%", marginBottom: -15 }}
        >
          <InputField
            setValue={(event) => setQuery(event)}
            placeholder="Search Complex"
            value={query}
          />
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0,
        }}
        className={classes.root}
      >
        <Grid container>
          <div
            style={{
              display: "block",
              paddingLeft: 16,
              paddingRight: 16,
              marginTop: 20,
              width: "100%",
            }}
          >
            {complexes.map((_complex, i) =>
              _complex.complex ? (
                <div
                  style={{
                    border: "1px solid grey",
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    backgroundColor: "#ffff",
                    boxShadow: "0 0 0 0.2rem transparent",
                  }}
                  key={i}
                  onClick={() => addComplex(_complex.complex)}
                >
                  <Grid container>
                    <Grid item sm={10}>
                      <div style={{ marginBottom: 2 }}>
                        {_complex.complex.name}
                      </div>
                      {_complex.complex?.header_detail?.tags && (
                        <div style={{ display: "inline-flex", fontSize: 11 }}>
                          {_complex.complex.header_detail?.tags.map(
                            (tag, i) => (
                              <span
                                key={i}
                                style={{ color: tag.color, marginRight: 3 }}
                              >
                                {tag.title}
                                {_complex.complex.header_detail?.tags.length >
                                  0 &&
                                  _complex.complex.header_detail?.tags.length -
                                    1 !==
                                    i &&
                                  ","}
                              </span>
                            )
                          )}
                        </div>
                      )}

                      <div style={{ marginBottom: 5 }}>
                        <div style={{ fontSize: 11 }}>
                          Opening Times:{" "}
                          {_complex.complex.header_detail?.opening_times}
                        </div>

                        <div style={{ fontSize: 11, marginTop: 2 }}>
                          Collection Time: {_complex.complex.collection_time}
                        </div>
                      </div>

                      {_complex.complex?.brands.map((brand) => (
                        <img
                          height="50"
                          alt=""
                          width="50"
                          key={brand.name}
                          src={brand.logo?.en}
                          style={{
                            backgroundColor: brand.bgcolor,
                            opacity: !brand.available && 0.3,
                          }}
                        />
                      ))}
                    </Grid>
                    <Grid item sm={2}>
                      <div
                        className="complex"
                        style={{ textAlign: "center", marginBottom: 10 }}
                      >
                        <input
                          readOnly
                          type="checkbox"
                          checked={checked(_complex.complex)}
                        />
                      </div>
                      {_complex.complex.header_detail?.circle &&
                        _complex.complex.header_detail?.circle.map(
                          (circle, i) => (
                            <div
                              key={i}
                              style={{
                                backgroundColor: circle.color,
                                marginRight: 3,
                                fontSize: 7,
                                textAlign: "center",
                                paddingTop: 20,
                                paddingBottom: 20,
                                borderRadius: 50,
                                marginBottom: 10,
                              }}
                            >
                              {circle.title.map((t, i) => (
                                <div key={i + t}>{t}</div>
                              ))}
                            </div>
                          )
                        )}
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div
                  style={{
                    border: "1px solid grey",
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    backgroundColor: "#ffff",
                    boxShadow: "0 0 0 0.2rem transparent",
                  }}
                  key={i}
                  onClick={() => addComplex(_complex)}
                >
                  <Grid container>
                    <Grid item sm={10}>
                      <div style={{ marginBottom: 2 }}>{_complex.name}</div>
                      <div style={{ marginBottom: 5 }}>
                        {_complex.header_detail?.tags &&
                          _complex.header_detail?.tags.length > 0 && (
                            <div
                              style={{ display: "inline-flex", fontSize: 11 }}
                            >
                              {_complex.header_detail?.tags.map((tag, i) => (
                                <span
                                  key={i}
                                  style={{ color: tag.color, marginRight: 3 }}
                                >
                                  {tag.title}
                                  {_complex.header_detail?.tags.length > 0 &&
                                    _complex.header_detail?.tags.length - 1 !==
                                      i &&
                                    ","}
                                </span>
                              ))}
                            </div>
                          )}

                        <div style={{ fontSize: 11 }}>
                          Opening Times: {_complex.header_detail?.opening_times}
                        </div>

                        <div style={{ fontSize: 11, marginTop: 2 }}>
                          Delivery Time: {_complex.delivery_time}
                        </div>
                      </div>
                      {_complex.brands &&
                        _complex.brands.map((brand) => (
                          <img
                            height="50"
                            width="50"
                            alt=""
                            key={brand.name}
                            src={brand.logo?.en}
                            style={{
                              backgroundColor: brand.bgcolor,
                              opacity: !brand.available && 0.3,
                            }}
                          />
                        ))}
                    </Grid>
                    <Grid item sm={2}>
                      <div
                        className="complex"
                        style={{ textAlign: "center", marginBottom: 10 }}
                      >
                        <input
                          readOnly
                          type="checkbox"
                          checked={checked(_complex)}
                        />
                      </div>
                      {_complex.header_detail &&
                        _complex.header_detail.circle &&
                        _complex.header_detail.circle.map((circle, i) => (
                          <div
                            key={i}
                            style={{
                              backgroundColor: circle.color,
                              marginRight: 3,
                              fontSize: 7,
                              textAlign: "center",
                              paddingTop: 20,
                              paddingBottom: 20,
                              borderRadius: 50,
                              marginBottom: 10,
                            }}
                          >
                            {circle.title.map((t) => (
                              <div key={i + t}>{t}</div>
                            ))}
                          </div>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              )
            )}
          </div>

          <Button
            fullWidth={true}
            onClick={submit}
            variant="contained"
            style={{
              backgroundColor: "#d9534f",
              boxShadow: "none",
              borderRadius: 0,
              marginTop: 50,
              paddingTop: 10,
              fontWeight: "bold",
            }}
          >
            <ListItemText
              disableTypography
              style={{ margin: 0 }}
              primary={
                <Typography
                  type="body2"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  submit
                </Typography>
              }
            />
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
  flex: {
    flex: 1,
  },
  content: {
    backgroundColor: "#f4f4f4",
    paddingLeft: 80,
    paddingRight: 80,
  },
  closeBtn: {
    border: "thin solid",
    height: 20,
    width: 20,
    fontSize: 15,
    padding: 0,
    marginTop: "-20px",
    marginRight: "-15px",
  },
  quantityItem: {
    borderRadius: 10,
    boxShadow: "none",
    marginBottom: 10,
  },
  btn: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  body: {
    flex: 1,
    alignSelf: "center",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    alignSelf: "center",
  },
  controls: {
    flex: 1,
    width: "100%",
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  listText: {
    color: "#fff",
  },
}));

export default SelectComplexDialog;
