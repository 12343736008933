/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-eval */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Grid,
  ListItemText,
  Paper,
  Button,
} from "@material-ui/core/";
import Ionicon from "react-ionicons";
import * as polyline from "polyline";
import marker from "../assets/marker.png";
import { Map, Marker, Polygon, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import * as EmailValidator from "email-validator";

// Components
import SearchBar from "./searchbar";
import InputField from "../commons/input.field";

// Configs
import { BASE_URL } from "../app.config";
import OffZone from "./customer.dialog/out.off.zone";
import PlaceEdit from "./customer.dialog/address";
import ChangeZone from "./customer.dialog/change.zone";
import ContactFields from "./customer.dialog/contact";
import Loader from "react-loader-spinner";
import { phoneSanitizer } from "../utils/other/sanitizers";
import { get, post, put } from "../utils/fetch";

const dadicon = new Icon({
  iconUrl: marker,
  iconSize: [96, 96],
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerDialog = (props) => {
  const initLocation = {
    street: "",
    name: "",
    house_number: "",
    suburb: "",
    city: "",
    address: "",
    zone_name: "",
    coords: {
      lat: "",
      lon: "",
    },
  };

  const [mode, setMode] = useState("edit");
  const [deliveryMode, setDeliveryMode] = useState("delivery");
  const [collectionType, setColletionType] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [location, setLocation] = useState(initLocation);
  const [locationReady, setLocationReady] = useState(false);
  const [zone, setZone] = useState();
  const [key, setKey] = useState(0);
  const [zones, setZones] = useState([]);
  const [complexes, setComplexes] = useState([]);
  const [complex, setComplex] = useState("Select Complex");
  const [name, setName] = useState("");
  const [friend_name, setFriendName] = useState("");
  const [alt_telephone, setAltTelephone] = useState("");
  const [telephone, setTelephone] = useState("");
  const [isPhone, setIsPhone] = useState(true);
  const [isAltPhone, setIsAltPhone] = useState(true);
  const [isFriendPhone, setIsFriendPhone] = useState(true);
  const [friend_telephone, setFriendTelephone] = useState("");
  const [error, setError] = useState(false);
  const [zoneCheckingLoading, setZoneCheckingLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmail, setIsEmail] = useState(true);
  const [order_for, setOrderFor] = useState("You");
  const [collection_name, setCollectionName] = useState("");
  const [vehicleRegistration, setVehicleRegistration] = useState("");
  const [vehicleMakeAndModel, setVehicleMakeAndModel] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [addressValue, setAddressValue] = useState(0);
  const [out_of_zone, setOutOfZone] = useState(false);

  const onSearchComplete = (result, lat, lng) => {
    let lt = "" + Number(lat);
    let ln = "" + Number(lng);

    let street_values = [];
    for (let i = 0; i < result.split(",").length - 2; i++) {
      street_values.push(result.split(",")[i]);
    }

    let street = [];
    let house_number = [];
    for (let i = 0; i < street_values.toString().split(" ").length; i++) {
      if (/\d/.test(street_values.toString().split(" ")[i])) {
        house_number.push(street_values.toString().split(" ")[i]);
      } else {
        street.push(street_values.toString().split(" ")[i]);
      }
    }

    let url = `${BASE_URL}/zone?lat=${lt}&lon=${ln}&source=ao`;

    if (!friend_name) {
      url = url + "&country_code=" + props.countryCode;
    }

    get(url).then((response) => {
      let nk = key;
      if (!response.out_of_zone) {
        setZone(response);
        setLocation({
          ...location,
          house_number: house_number.toString(),
          street: street.toString().split(",").join(" "),
          city: result.split(",")[result.split(",").length - 2],
          address: result,
          zone_name: response.zone.name.en,
          zone_uuid: response.zone.uuid,
          country_code: response.zone.country_code,
          country_name: response.zone.country_name,
          coords: {
            lat: lat,
            lon: lng,
          },
        });
        setMode("edit");
        setOutOfZone(false);
        setLocationReady(false);
        setSearchLoading(false);
        setKey(++nk);
      } else {
        setOutOfZone(true);
        setSearchLoading(false);
      }
    });
  };

  const placeEditLocation = (pel) => {
    setLocation({ ...pel, cart: null });

    let nlocation = {
      name: name,
      address: location.address,
      coords: zone.zone.zone_location,
      rating: 5,
      zone_uuid: zone.zone.uuid,
      cart: {
        delivery_mode: deliveryMode,
        zone_name: zone.zone.name?.en,
        zone_uuid: zone.zone.uuid,
        customer: {
          address: location?.address,
          address_instruction: location.instructions ?? "",
          city: location.city ?? "",
          directions: location.directions ?? "",
          email: email ?? "",
          location_nickname: location.name ?? "",
          name: name,
          phone: telephone,
          alt_phone: alt_telephone,
          street: location.street ?? "",
        },
        customer_location: {
          lat: location.coords.lat,
          lon: location.coords.lon,
        },
      },
    };

    let _error;

    if (!name) {
      _error = true;
    }

    if (!telephone) {
      _error = true;
    }

    const _isPhone = eval(props.country.web_regex).test(telephone);

    if (!_isPhone) {
      _error = true;
      setIsPhone(false);
    }

    if (alt_telephone) {
      const _isAltPhone = eval(
        /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
      ).test(alt_telephone);

      if (!_isAltPhone) {
        _error = true;
        setIsAltPhone(false);
      }
    }

    if (order_for === "Friend") {
      const _IsFriendPhone = eval(
        /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
      ).test(friend_telephone);
      if (!friend_name) {
        _error = true;
      }

      if (!friend_telephone) {
        _error = true;
      }

      if (!_IsFriendPhone) {
        _error = true;
        setIsFriendPhone(false);
      }
    }

    if (!location.name) {
      _error = true;
    }

    if (location.name.length > 50) {
      _error = true;
    }

    if (location.address.length > 250) {
      _error = true;
    }

    if (!location.city) {
      _error = true;
    }

    if (!location.street) {
      _error = true;
    }

    if (_error) {
      setError(_error);
      return;
    }

    if (nlocation) {
      if (order_for === "Friend") {
        nlocation.cart.customer.friend_name = friend_name;
        nlocation.cart.customer.friend_number = friend_telephone;
      }
      if (
        location.coords.lat.toFixed(5) !== props.defaultLocation.lat.toFixed(5)
      ) {
        if (nlocation.cart.delivery_mode.toLocaleLowerCase() === "delivery") {
          props.onAddLocation(nlocation);

          if (typeof props.edit === "function") {
            props.edit();
          }

          savedLocation("delivery");
        }
      } else {
        setLocation({ ...initLocation });
        alert(
          "It appears you have not moved the pin or found an address. Please try searching again"
        );
      }
    }
  };

  const changeZone = () => {
    setMode("change_zone");
    get(
      `${BASE_URL}/zone?country=${props.countryName}&lat=${props.defaultLocation.lat}&lon=${props.defaultLocation.lon}&source=ao`
    ).then((response) => {
      if (!response.out_of_zone) {
        setZones(response);
      }
    });
  };

  useEffect(() => {
    if (props.complex) {
      setComplex(props.complex);
      if (props.complex.delivery_options) {
        const delivery_options = props.complex.delivery_options.filter(
          (f) => f === "In Store" || f === "Kerbside"
        );
        setDeliveryOptions(delivery_options);
        if (delivery_options.length === 1) {
          setColletionType(delivery_options[0]);
        }
      }
    }
  }, [props.complex]);

  useEffect(() => {
    setTelephone(props.callingNumber);
    if (props.client) {
      if (props.client.name) setName(props.client.name);
      if (props.client && props.client.zone) {
        setZone(props.client.zone);
      } else {
        setZone(props.defaultZone);
      }

      if (props.client?.cart) {
        setAltTelephone(props.client.cart.customer.alt_telephone);
        setEmail(props.client.cart.customer.email);
        setDeliveryMode(props.client.cart.delivery_mode);
        setMode(
          props.client.cart.delivery_mode !== "delivery" ? "collection" : "edit"
        );

        if (props.client.cart.delivery_mode !== "delivery") {
          setCollectionName(props.client.name);
        }

        setLocation({
          street: props.client.cart.customer.street,
          name: props.client.cart.customer.location_nickname,
          house_number: props.client.cart.customer.house_number,
          suburb: props.client.cart.customer.suburb,
          city: props.client.cart.customer.city,
          address: props.client.cart.customer.address,
          directions: props.client.cart.customer.directions,
          instructions: props.client.cart.customer.address_instruction,
          zone_name: props.client.cart.zone_name,
          coords: {
            lat: Number(props.client.coords.lat),
            lon: Number(props.client.coords.lon),
          },
        });
      }
    }
  }, [props.client, props.callingNumber]);

  useEffect(() => {
    if (props.open) {
      get(`${BASE_URL}/complexes?country=${props.countryName}&source=ao`).then(
        (response) => {
          if (response) {
            setComplexes(response);
          }
        }
      );
    }
  }, [props.open]);

  const savedLocation = async (type) => {
    let data;

    if (type === "delivery") {
      let nickname;
      let address;

      if (location.name.length > 50) {
        nickname = location.name.slice(0, 50);
      } else {
        nickname = location.name;
      }

      if (location.address.length > 250) {
        address = location.address.slice(0, 250);
      } else {
        address = location.address;
      }

      data = {
        address,
        latitude: location.coords.lat,
        longitude: location.coords.lon,
        house_number: location.house_number,
        address_instruction: location.instructions ?? "",
        city: location.city ?? "",
        directions: location.directions ?? "",
        street: location.street ?? "",
        suburb: location.suburb ?? "",
        zone_uuid: location.zone_uuid,
        country_code: location.country_code,
        country_name: location.country_name,
        nickname,
      };
    } else {
      let collector_details;
      if (type === "instore") {
        collector_details = {
          collection_name,
        };
      } else {
        collector_details = {
          vehicleColor,
          vehicleMakeAndModel,
          vehicleRegistration,
        };
      }

      data = {
        ...collector_details,
        latitude: complex.latitude,
        longitude: complex.longitude,
        city: complex.city ?? "",
        collection_name,
        complex_uuid: complex.uuid,
        complex_name: complex.name,
        country_code: complex.country_code,
        country_name: complex.country_name,
      };
    }

    if (order_for === "Friend") {
      data.friend_name = friend_name;
      data.friend_number = friend_telephone;
    }

    const _phone = telephone.includes("+")
      ? phoneSanitizer(telephone)
      : phoneSanitizer(props.country.dial_code + telephone);

    const _location = {
      ...data,
      name: name,
      type,
      phone: _phone,
      alt_phone: alt_telephone ?? "",
      email: email ?? "",
      source: "ao",
    };

    if (props.client?.address_id) {
      put(`${BASE_URL}/locations/update_location`, {
        ..._location,
        address_id: props.client.address_id,
      });
    } else {
      post(`${BASE_URL}/locations/add_location/${type}`, _location);
    }
  };

  const submit = () => {
    let value = addressValue;
    setAddressValue(++value);
    let lt = "" + Number(location.coords.lat);
    let ln = "" + Number(location.coords.lon);

    let _error;

    if (!name) {
      _error = true;
    }

    if (!telephone) {
      _error = true;
    }

    if (email) {
      if (!EmailValidator.validate(email)) {
        _error = true;
        setIsEmail(false);
      }
    }

    const _isPhone = eval(props.country.web_regex).test(telephone);

    if (!_isPhone) {
      _error = true;
      setIsPhone(false);
    }

    if (alt_telephone) {
      const _IsAltPhone = eval(
        /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
      ).test(alt_telephone);

      if (!_IsAltPhone) {
        _error = true;
        setIsAltPhone(false);
      }
    }

    if (order_for === "Friend") {
      const _IsFriendPhone = eval(
        /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
      ).test(friend_telephone);
      if (!friend_name) {
        _error = true;
      }

      if (!friend_telephone) {
        _error = true;
      }

      if (!_IsFriendPhone) {
        _error = true;
        setIsFriendPhone(false);
      }
    }

    if (deliveryMode === "delivery") {
      if (!location.name) {
        _error = true;
      }

      if (location.name.length > 50) {
        _error = true;
      }

      if (location.address.length > 250) {
        _error = true;
      }

      if (!location.city) {
        _error = true;
      }

      if (!location.street) {
        _error = true;
      }
    }

    if (_error) {
      setError(_error);
      return;
    }

    if (!_error) {
      if (deliveryMode === "delivery") {
        setZoneCheckingLoading(true);

        const url = `${BASE_URL}/zone?lat=${lt}&lon=${ln}&country_code=${props.countryCode}&source=ao`;

        get(url).then(async (response) => {
          if (!response.out_of_zone) {
            if (locationReady) {
              if (
                location.coords.lat.toFixed(5) !==
                props.defaultLocation.lat.toFixed(5)
              ) {
                if (mode !== "edit") {
                  props.onAddLocation(location);
                  if (typeof props.edit === "function") {
                    props.edit();
                  }

                  setLocation({
                    ...location,
                    zone_uuid: response.zone.uuid,
                    country_code: response.zone.country_code,
                    country_name: response.zone.country_name,
                  });

                  savedLocation("delivery");
                  setZoneCheckingLoading(false);
                }
              } else {
                setLocation({ ...initLocation });
                alert(
                  "It appears you have not moved the pin or found an address. Please try searching again"
                );
              }
            }
          } else {
            setOutOfZone(true);
            if (response.out_of_zone) {
              get(
                `${BASE_URL}/zone?country=${props.countryName}&lat=${props.defaultLocation.lat}&lon=${props.defaultLocation.lon}&source=ao`
              ).then((response) => {
                setZones((zones) => [...zones, response]);
              });
            }
          }
        });
      }

      if (deliveryMode === "collection" && collectionType) {
        const type = collectionType === "In Store" ? "instore" : "kerbside";

        let collector_details;
        if (collectionType === "In Store") {
          if (!collection_name) {
            setError(true);
            return;
          }
        }

        if (collectionType === "Kerbside") {
          if (!vehicleRegistration) _error = true;
          if (!vehicleMakeAndModel) _error = true;
          if (!vehicleColor) _error = true;

          if (_error) {
            setError(_error);
            return;
          }

          collector_details = {
            vehicle_registration: vehicleRegistration,
            vehicle_make_and_model: vehicleMakeAndModel,
            vehicle_color: vehicleColor,
          };
        }

        const details = complexes.filter(
          (f) => f.complex.uuid === complex.uuid
        )[0];

        let nlocation = {
          name: name,
          complex: complex.name,
          coords: {
            lat: complex.latitude,
            lon: complex.longitude,
          },
          complexes: complexes,
          phone: telephone,
          rating: 5,
          selected_complex: { ...complex, payment: details.payments },
          cart: {
            delivery_mode: type,
            customer: {
              ...collector_details,
              address: "",
              address_instruction: "",
              city: complex.city,
              directions: "",
              email: email ?? "",
              location_nickname: "",
              collection_name,
              name,
              phone: telephone,
              alt_phone: alt_telephone,
              street: "",
            },
            complex_uuid: complex.uuid,
            customer_location: {
              lat: complex.latitude,
              lon: complex.longitude,
            },
          },
        };

        if (order_for === "Friend") {
          nlocation.cart.customer.friend_name = friend_name;
          nlocation.cart.customer.friend_number = friend_telephone;
        }

        savedLocation(type);
        props.onAddLocation(nlocation);
        if (typeof props.edit === "function") props.edit();
      }
    }
  };

  const close = () => {
    if (props.customerEdit) {
      if (props.customerEditCart) props.onAddLocation(location);

      props.onClose();
    } else {
      props.onClose();
    }
  };

  const recordNo = () => {
    if (BASE_URL) {
      post(`${BASE_URL}/rest/simbisa_extended_no`, location);
    }
  };

  const changeZoneData = (details, zone) => {
    setZone({ ...details, zone: zone });

    const nlocation = {
      ...location,
      name: name,
      address: location.address,
      complex: zone?.complex,
      coords: zone?.zone_location,
      rating: 5,
      zone_uuid: zone.uuid,
      cart: {
        delivery_mode: deliveryMode,
        complex_name: zone?.complex,
        complex_uuid: zone?.complex_uuid,
        contact_number: zone?.contact_number,
        country: zone?.country,
        zone_name: zone?.name?.en,
        customer: {
          address: location?.address,
          address_instruction: location?.address_instruction,
          city: location?.city,
          directions: location?.directions,
          email: email,
          location_nickname: location?.location_nickname,
          name: name,
          phone: telephone,
          alt_phone: telephone,
          street: location?.street,
        },
        customer_location: {
          lat: location.coords.lat,
          lon: location.coords.lon,
        },
      },
    };

    setLocation({ ...nlocation });
  };

  const addDeliveryZone = (details, zone, address) => {
    setZone({ ...details, zone: zone });

    const nlocation = {
      name: name,
      address: location.address,
      complex: zone?.complex,
      coords: zone.zone_location,
      rating: 5,
      zone_uuid: zone.uuid,
      cart: {
        delivery_mode: deliveryMode,
        complex_name: zone?.complex,
        complex_uuid: zone?.complex_uuid,
        contact_number: zone?.contact_number,
        country: zone.country,
        zone_name: zone.name?.en,
        customer: {
          address: location?.address,
          address_instruction: location?.address_instruction,
          city: location?.city,
          house_number: address.house_number,
          directions: location?.directions,
          email: email,
          suburb: location.surbub,
          location_nickname: address?.location_nickname,
          name: name,
          phone: telephone,
          alt_phone: alt_telephone,
          street: location?.street,
        },
        customer_location: {
          lat: location.coords.lat,
          lon: location.coords.lon,
        },
      },
    };

    setLocation({ ...nlocation });
    setMode("edit");
  };

  const collection = async () => {
    setMode("collection");
    setDeliveryMode("collection");
    setOutOfZone(false);
    if (complexes.length > 0) return;
  };

  const delivery = () => {
    setMode("edit");
    setDeliveryMode("delivery");
    setOutOfZone(false);
    let nl = {
      ...location,
      deliveryMode: "delivery",
    };
    setLocation(nl);
  };

  const updateCoords = (e) => {
    setLocation({
      ...location,
      coords: {
        lat: e.target._latlng.lat,
        lon: e.target._latlng.lng,
      },
    });
  };

  const view = () => {
    const contactReady = name && alt_telephone && telephone;
    let locationView,
      contactView,
      mapView = null;

    if (mode === "edit") {
      locationView = deliveryMode === "delivery" && (
        <Grid item md={3}>
          <Grid container spacing={8} justify="center">
            <Grid item sm={9}>
              <Typography align="center" variant="body1">
                Address
                {location.name &&
                location.houseNumber &&
                location.street &&
                location.city ? (
                  <Ionicon
                    className={props.classes.icon}
                    style={{
                      fill: "green",
                    }}
                    icon="md-checkmark-circle"
                  />
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          {searchLoading ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "50%",
              }}
            >
              <Loader
                type="ThreeDots"
                color="#D71920"
                height="100"
                width="100"
              />
            </div>
          ) : out_of_zone ? (
            <div style={{ marginTop: 20, marginBottom: 16 }}>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{ fontSize: 18, fontWeight: "bold", marginBottom: 8 }}
                >
                  Out Of Zone
                </div>
                <div style={{ marginBottom: 16 }}>
                  You can choose collection or order for a friend
                </div>
              </div>
              <Grid container justify="center" spacing={2}>
                <Grid item sm={2}></Grid>
                <Grid item sm={4}>
                  <Button
                    size="small"
                    style={{
                      margin: 4,
                      backgroundColor: "#ccc",
                      textTransform: "capitalize",
                      boxShadow: "none",
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      setMode("collection");
                      setDeliveryMode("collection");
                      setOutOfZone(false);
                    }}
                  >
                    Collection
                  </Button>
                </Grid>
                <Grid item sm={1}></Grid>
                <Grid item sm={5}>
                  <Button
                    size="small"
                    style={{
                      margin: 4,
                      backgroundColor: "#D71920",
                      textTransform: "capitalize",
                      boxShadow: "none",
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      setMode("edit");
                      setDeliveryMode("delivery");
                      setOrderFor("Friend");
                      setOutOfZone(false);
                    }}
                  >
                    <span style={{ color: "#fff" }}>Friend</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <PlaceEdit
              classes={props.classes}
              isEdit={props.countryCode !== "KE"}
              location={location}
              error={error}
              placeEditLocation={placeEditLocation}
              addressValue={addressValue}
              changeZone={changeZone}
            />
          )}
        </Grid>
      );
    } else if (mode === "change_zone") {
      locationView = (
        <Grid item md={3}>
          <Grid container spacing={8} justify="center">
            <Grid item sm={9}>
              <Typography align="center" variant="body1">
                Address
                {locationReady ? (
                  <Ionicon
                    className={props.classes.icon}
                    style={{
                      fill: locationReady ? "green" : "inherit",
                    }}
                    icon="md-checkmark-circle"
                  />
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          {zones.length > 0 ? (
            <ChangeZone
              recordNo={recordNo}
              mode={() => setMode("edit")}
              changeZoneData={changeZoneData}
              zone={zone}
              zones={zones}
              classes={props.classes}
              onResut={(result) => {
                setLocationReady(result);
              }}
            />
          ) : (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              Loading zones....
            </div>
          )}
        </Grid>
      );
    } else if (mode === "off_zone") {
      locationView = (
        <Grid item md={3}>
          <Grid container spacing={8} justify="center">
            <Grid item sm={9}>
              <Typography align="center" variant="body1">
                Address
                {locationReady ? (
                  <Ionicon
                    className={props.classes.icon}
                    style={{
                      fill: locationReady ? "green" : "inherit",
                    }}
                    icon="md-checkmark-circle"
                  />
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <OffZone
            recordNo={recordNo}
            mode={() => setMode("edit")}
            addDeliveryZone={addDeliveryZone}
            zone={zone}
            zones={zones}
            classes={props.classes}
            onResut={(result) => {
              setLocationReady(result);
            }}
          />
        </Grid>
      );
    } else if (mode === "collection") {
      locationView = (
        <Grid item md={6}>
          <Grid container spacing={8} justify="center">
            <Grid item sm={9}>
              <Typography align="center" variant="body1">
                Address
                {complex.name !== "Select Complex" ? (
                  <Ionicon
                    className={props.classes.icon}
                    style={{
                      fill: "green",
                    }}
                    icon="md-checkmark-circle"
                  />
                ) : null}
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={12} onClick={() => props.selectComplex(complexes)}>
            <div
              className={`${props.classes.customerDialogInput} action-select`}
              style={{
                paddingLeft: 16,
                margin: 8,
                borderRadius: 8,
                width: "calc(100% - 15px)",
                cursor: "pointer",
              }}
            >
              <ListItemText
                primary={complex.name ? complex.name : "Select Complex"}
                secondary={
                  <Ionicon
                    style={{ marginRight: 30, width: 20, height: 20 }}
                    icon="ios-arrow-down"
                  />
                }
                className={props.classes.primary}
              />
            </div>
          </Grid>
          <div align="center" style={{ marginTop: 8 }}>
            {deliveryOptions.includes("In Store") && (
              <Button
                onClick={() => setColletionType("In Store")}
                size="small"
                variant="contained"
                color={collectionType === "In Store" ? "secondary" : "default"}
                style={{
                  margin: 8,
                  marginRight: 4,
                  textTransform: "capitalize",
                  boxShadow: "none",
                  borderRadius: 10,
                  padding: 0,
                }}
              >
                In Store
              </Button>
            )}
            {deliveryOptions.includes("Kerbside") && (
              <Button
                onClick={() => setColletionType("Kerbside")}
                size="small"
                variant="contained"
                color={collectionType === "Kerbside" ? "secondary" : "default"}
                style={{
                  margin: 8,
                  marginLeft: 4,
                  textTransform: "capitalize",
                  boxShadow: "none",
                  borderRadius: 10,
                  padding: 0,
                }}
              >
                Kerbside
              </Button>
            )}
          </div>
          {collectionType === "In Store" && (
            <InputField
              required={true}
              error={error && !collection_name}
              value={collection_name}
              placeholder="Collector's Name"
              setValue={(event) => setCollectionName(event)}
            />
          )}
          {collectionType === "Kerbside" && (
            <main>
              <InputField
                required={true}
                error={error && !vehicleRegistration}
                setValue={(event) => setVehicleRegistration(event)}
                placeholder="Vehicle Registration *"
                value={vehicleRegistration}
              />
              <InputField
                required={true}
                error={error && !vehicleMakeAndModel}
                setValue={(event) => setVehicleMakeAndModel(event)}
                placeholder="Vehicle Make and Model *"
                value={vehicleMakeAndModel}
              />
              <InputField
                required={true}
                error={error && !vehicleColor}
                setValue={(event) => setVehicleColor(event)}
                placeholder="Vehicle Color *"
                value={vehicleColor}
              />
            </main>
          )}
        </Grid>
      );
    }

    if (mode) {
      let surbub;

      if (zone) {
        surbub = polyline.decode(zone.zone.zone_area);
        surbub = surbub.map((point) => [point[0], point[1]]);
      }

      contactView = (
        <Grid item md={deliveryMode === "delivery" ? 3 : 6}>
          <Typography align="center" variant="body1">
            Contact
            {contactReady ? (
              <Ionicon
                className={props.classes.icon}
                style={{ fill: contactReady ? "green" : "inherit" }}
                icon="md-checkmark-circle"
              />
            ) : null}
          </Typography>
          <ContactFields
            classes={props.classes}
            setName={setName}
            setFriendName={setFriendName}
            setAltTelephone={setAltTelephone}
            setTelephone={setTelephone}
            setFriendTelephone={setFriendTelephone}
            setEmail={setEmail}
            setOrderFor={setOrderFor}
            country={props.country}
            error={error}
            name={name}
            order_for={order_for}
            friend_name={friend_name}
            alt_telephone={alt_telephone}
            telephone={telephone}
            isPhone={isPhone}
            isAltPhone={isAltPhone}
            isEmail={isEmail}
            isFriendPhone={isFriendPhone}
            friend_telephone={friend_telephone}
            email={email}
          />
        </Grid>
      );

      mapView = deliveryMode === "delivery" && (
        <Grid item md={6} style={{ paddingRight: 16 }}>
          <Grid container spacing={8} justify="center">
            <Grid item sm={9}>
              <Typography align="center" variant="body1">
                Map
                {location.coords &&
                location.coords.lat &&
                location.coords.lat.toFixed(5) !==
                  props.defaultLocation.lat.toFixed(5) ? (
                  <Ionicon
                    className={props.classes.icon}
                    style={{
                      fill: location.address ? "green" : "inherit",
                    }}
                    icon="md-checkmark-circle"
                  />
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <SearchBar
            onSearchComplete={onSearchComplete}
            countryCode={order_for !== "You" ? "" : props.countryCode}
            address={location.address}
            setSearchLoading={() => setSearchLoading(true)}
          />
          <Paper
            className={props.classes.map}
            style={{
              border: "3px solid #cdcccb",
              borderRadius: 6,
              boxShadow: "none",
              zIndex: 0,
            }}
          >
            <div id="map"></div>
            <Map
              center={
                location.coords && location.coords.lat
                  ? [location.coords.lat, location.coords.lon]
                  : [props.defaultLocation.lat, props.defaultLocation.lon]
              }
              zoom={12}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={
                  location.coords && location.coords.lat
                    ? [location.coords.lat, location.coords.lon]
                    : [props.defaultLocation.lat, props.defaultLocation.lon]
                }
                draggable={true}
                icon={dadicon}
                onDragend={updateCoords}
              />
              {surbub && <Polygon positions={surbub} color="red" />}
            </Map>
          </Paper>
        </Grid>
      );
    }

    return { locationView, contactView, mapView };
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      fullScreen
      disableEscapeKeyDown
      disableBackdropClick
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        style={{ backgroundColor: "#efeff0", paddingTop: 0, paddingBottom: 0 }}
      >
        {!props.customerEditCart && (
          <div align="left" style={{ marginTop: 10 }}>
            <Ionicon
              style={{
                backgroundColor: "#d9534f",
                fill: "#ffff",
                width: 30,
                height: 30,
              }}
              icon="ios-arrow-back"
              onClick={() => close()}
            />
          </div>
        )}

        <div
          align="center"
          style={{ marginTop: !props.customerEditCart && -20 }}
        >
          {" "}
          CUSTOMER{" "}
        </div>
        <div align="center">
          <Button
            onClick={delivery}
            size="small"
            variant="contained"
            color={deliveryMode === "delivery" ? "secondary" : "default"}
            style={{
              margin: 8,
              marginRight: 4,
              textTransform: "capitalize",
              boxShadow: "none",
              borderRadius: 10,
            }}
          >
            Delivery
          </Button>
          <Button
            onClick={collection}
            size="small"
            variant="contained"
            color={deliveryMode !== "delivery" ? "secondary" : "default"}
            style={{
              margin: 8,
              marginLeft: 4,
              textTransform: "capitalize",
              boxShadow: "none",
              borderRadius: 10,
            }}
          >
            Collection
          </Button>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "#efeff0",
          paddingLeft: 100,
          paddingRight: 100,
        }}
      >
        <Grid
          container
          style={{
            paddingLeft: deliveryMode === "delivery" ? 0 : "25%",
            paddingRight: deliveryMode === "delivery" ? 0 : "25%",
          }}
        >
          {view().contactView}
          {view().mapView}
          {view().locationView}
        </Grid>
        <div
          align="center"
          style={{ maxWidth: "300px", margin: "auto", marginTop: 50 }}
        >
          <Button
            fullWidth
            align="center"
            color="secondary"
            variant="contained"
            style={{ boxShadow: "none", borderRadius: 0 }}
            onClick={submit}
          >
            {zoneCheckingLoading ? (
              <div
                style={{ textAlign: "center", backgroundColor: "transparent" }}
              >
                <Loader type="Oval" color="#fff" width="30" height="30" />
              </div>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerDialog;
