import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Routes from "./routes";
import { authListener } from "./redux/actions/auth.actions";
import "./App.css";
import { checkToken } from "./utils/check.token";

checkToken();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(authListener());
  }, []);

  return <Routes />;
}

export default App;
