import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dialog, TextField, Typography } from "@material-ui/core/";
import CancelIcon from "@material-ui/icons/Cancel";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";
import { resetPassword } from "../redux/actions/auth.actions";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [npassword, setnPassword] = useState("");
  const [resetting, setResetting] = useState(false);

  async function onPasswordReset(event) {
    event.preventDefault();
    setResetting(true);
    dispatch(resetPassword({ email: auth.user.email, password: npassword }));
  }

  useEffect(() => {
    if (auth && auth.emailSent) {
      setTimeout(() => {
        props.onClose();
      }, 3000);
    }
  }, [auth]);

  return (
    <>
      <Dialog
        onClose={() => props.onClose}
        open={props.open}
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: "none",
            minWidth: 400,
            paddingTop: 15,
            top: -70,
            backgroundColor: "#ccc",
          },
        }}
      >
        {auth.user?.p_r && !resetting && (
          <div
            onClick={() => props.onClose()}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <CancelIcon style={{ width: 25, height: 25 }} />
          </div>
        )}

        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
          {auth?.error && !auth?.error.email && !auth?.error.password && (
            <Typography
              style={{
                color: "#D71920",
                marginBottom: 10,
                textAlign: "center",
              }}
            >
              {auth.error}
            </Typography>
          )}

          {auth?.emailSent && (
            <>
              <Typography
                style={{
                  color: "green",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {auth.message}. Redirecting to login...
              </Typography>
            </>
          )}

          <TextField
            variant="outlined"
            size="small"
            required
            fullWidth
            label="New Password"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginTop: 20 }}
            value={npassword}
            onChange={(e) => setnPassword(e.target.value)}
          />
          {auth?.error && auth?.error.password && (
            <Typography
              style={{
                color: "#D71920",
                paddingTop: 10,
              }}
            >
              {auth.error.password}
            </Typography>
          )}
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{
            backgroundColor: "#9e1318",
            borderRadius: 0,
            boxShadow: "none",
            color: "#ffff",
            marginTop: 20,
          }}
          onClick={onPasswordReset}
        >
          {auth && auth.isEmailSentLoading ? (
            <Loader type="Oval" color="white" width="20" height="20" />
          ) : (
            "Submit"
          )}
        </Button>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#D71920",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderColor: "red !important",
  },
}));

export default ResetPassword;
