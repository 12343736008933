import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core/";

// Components
import InputField from "../../commons/input.field";

const ContactFields = (props) => {
  const [order_for, setOrderFor] = useState("You");

  useEffect(() => {
    if (order_for) {
      props.setOrderFor(order_for);
    }
  }, [order_for]);

  useEffect(() => {
    if (props.order_for !== order_for) {
      setOrderFor(props.order_for);
    }
  }, [props.order_for]);

  return (
    <div style={{ marginRight: 16 }}>
      <InputField
        required={true}
        error={props.error && !props.name}
        setValue={(event) => props.setName(event)}
        placeholder="Customer Name"
        value={props.name}
      />

      <InputField
        required={true}
        error={props.error && (!props.telephone || !props.isPhone)}
        setValue={(event) => props.setTelephone(event)}
        placeholder="Telephone"
        value={props.telephone}
      />

      <InputField
        setValue={(event) => props.setAltTelephone(event)}
        placeholder="Alt. Telephone"
        error={props.alt_telephone && !props.isAltPhone}
        value={props.alt_telephone}
      />
      <InputField
        setValue={(event) => props.setEmail(event)}
        placeholder="Email"
        error={props.email && !props.isEmail}
        value={props.email}
      />
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => setOrderFor("You")}
          size="small"
          variant="contained"
          color={order_for === "You" ? "secondary" : "default"}
          style={{
            margin: 8,
            marginRight: 4,
            textTransform: "capitalize",
            boxShadow: "none",
            borderRadius: 10,
            padding: 0,
          }}
        >
          For You
        </Button>
        <Button
          onClick={() => setOrderFor("Friend")}
          size="small"
          variant="contained"
          color={order_for === "Friend" ? "secondary" : "default"}
          style={{
            margin: 8,
            marginRight: 4,
            textTransform: "capitalize",
            boxShadow: "none",
            borderRadius: 10,
            padding: 0,
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          For Friend
        </Button>
      </div>
      {order_for === "Friend" && (
        <div>
          <InputField
            required={true}
            error={props.error && !props.friend_name}
            setValue={(event) => props.setFriendName(event)}
            placeholder="Friend Name"
            value={props.friend_name}
          />
          <InputField
            required={true}
            error={
              props.error && (!props.friend_telephone || !props.isFriendPhone)
            }
            setValue={(event) => props.setFriendTelephone(event)}
            placeholder="Friend Telephone"
            value={props.friend_telephone}
          />
        </div>
      )}
    </div>
  );
};

export default ContactFields;
