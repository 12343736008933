import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Container,
  Dialog,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core/";
import Loader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";
import { login, resetPassword } from "../redux/actions/auth.actions";
import logo from "../assets/dadv2.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Login = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  async function onLogin(event) {
    dispatch(login({ email: username, password }));
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div style={{ textAlign: "center" }}>
          <img src={logo} width="150px" alt="logo" />
        </div>
        <div className={classes.paper}>
          <div className={classes.form}>
            {auth?.emailSent && (
              <Typography>
                <span style={{ color: "green" }}>{auth.message}</span>
              </Typography>
            )}

            {auth?.error && !auth?.error.email && !auth?.error.password && (
              <Typography
                style={{
                  color: "#D71920",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {auth.error}
              </Typography>
            )}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="email"
              autoFocus
              InputProps={{
                classes: {
                  notchedOutline:
                    auth?.error && auth?.error.username
                      ? classes.notchedOutline
                      : null,
                },
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                classes: {
                  notchedOutline:
                    auth?.error && auth?.error.password
                      ? classes.notchedOutline
                      : null,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{
                backgroundColor: "#D71920",
                borderRadius: 0,
                boxShadow: "none",
                color: "#ffff",
              }}
              className={classes.submit}
              onClick={() => onLogin()}
            >
              {auth && auth.isLoading ? (
                <Loader type="Oval" color="white" width="20" height="20" />
              ) : (
                "login"
              )}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#D71920",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderColor: "red !important",
  },
}));

export default Login;
