import React from "react";
import { Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThumbDown, ThumbUp } from "@material-ui/icons";

const LastOrder = ({ openOrderDialog, addOrderDetails, order, viewLogs }) => {
  const classes = useStyles();

  const _viewOrder = () => {
    addOrderDetails(order);
    openOrderDialog();
  };

  const _viewLogs = () => {
    if (order.logs) {
      addOrderDetails(order);
      viewLogs();
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid item container>
        <Grid item sm={1} onClick={_viewOrder}>
          <h6 className={classes.title}>Source</h6>
          <h6 className={classes.data}>{order.order_source}</h6>
        </Grid>

        <Grid
          item
          sm={order.logs ? 2 : 3}
          style={{ marginRight: 10 }}
          onClick={_viewOrder}
        >
          <h6 className={classes.title}>Address</h6>
          <h6 className={classes.data}>{order.customer?.address}</h6>
        </Grid>

        <Grid item sm={1} onClick={_viewOrder}>
          <h6 className={classes.title}>Complex</h6>
          <h6 className={classes.data}>{order.complex_name}</h6>
        </Grid>

        <Grid
          item
          sm={2}
          onClick={_viewOrder}
          style={{ maxWidth: "20%", flexBasis: "20%", paddingLeft: 5 }}
        >
          <h6 className={classes.title}>Brands</h6>
          <h6 className={classes.data}>{order.brands}</h6>
        </Grid>

        {order.logs && (
          <Grid
            item
            sm={1}
            onClick={_viewLogs}
            style={{ maxWidth: "11%", flexBasis: "11%", textAlign: "center" }}
          >
            <h6 className={classes.title}>Logs</h6>
            <h6 className={classes.data} style={{ color: "blue" }}>
              View History
            </h6>
          </Grid>
        )}

        <Grid
          item
          sm={2}
          onClick={_viewOrder}
          style={{ maxWidth: "12%", flexBasis: "12%" }}
        >
          <h6 className={classes.title}>Rated</h6>
          <h6 className={classes.data}>Delivery | Food</h6>
          <h6 className={classes.data} style={{ display: "inline-flex" }}>
            <div style={{ marginRight: "80%" }}>
              {order.rating?.rate_us_on_delivery === "good" ? (
                <ThumbUp
                  style={{
                    color: "#218839",
                  }}
                />
              ) : (
                order.rating?.rate_us_on_delivery === "bad" && (
                  <ThumbDown
                    style={{
                      color: "#D71920",
                    }}
                  />
                )
              )}
            </div>
            <div>
              {order.rating?.rate_our_food === "good" ? (
                <ThumbUp
                  style={{
                    color: "#218839",
                  }}
                />
              ) : (
                order.rating?.rate_our_food === "bad" && (
                  <ThumbDown
                    style={{
                      color: "#D71920",
                    }}
                  />
                )
              )}
            </div>
          </h6>
        </Grid>

        <Grid
          item
          sm={2}
          onClick={_viewOrder}
          style={{ maxWidth: "13%", flexBasis: "13%" }}
        >
          <h6 className={classes.title}>Date & Time</h6>
          <h6 className={classes.data}>{order.placed}</h6>
        </Grid>

        <Grid
          item
          sm={1}
          onClick={_viewOrder}
          style={{ maxWidth: "8.9%", flexBasis: "8.9%" }}
        >
          <h6 className={classes.title}>Overall Time</h6>
          <h6 className={classes.data} style={{ textAlign: "center" }}>
            {order.overall_time}
          </h6>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  paper: {
    margin: 8,
    marginLeft: 0,
    cursor: "pointer",
    boxShadow: "none",
    borderRadius: 0,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 0,
  },

  data: {
    fontSize: "13px",
    marginTop: 5,
    marginBottom: 0,
    fontWeight: "inherit",
  },
  title: {
    fontSize: "13px",
    margin: 0,
  },
}));

export default LastOrder;
