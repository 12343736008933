import React, { useState, useEffect } from "react";
import {
  Paper,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  TextField,
} from "@material-ui/core";
import Ionicon from "react-ionicons";
import { makeStyles } from "@material-ui/core/styles";

const sessiontoken = () => {
  let token;
  if (window.google) {
    token = new window.google.maps.places.AutocompleteSessionToken();
  }
  return token;
};

function getPredictions(input, countryCode, sessionToken, setPredictions) {
  try {
    var autocompleteService =
      new window.google.maps.places.AutocompleteService();

    if (input) {
      autocompleteService.getPlacePredictions(
        {
          input: input,
          componentRestrictions: { country: countryCode },
          sessionToken: sessionToken,
        },
        (predictions) => setPredictions(predictions)
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function getDetails(prediction, details, sessionToken) {
  let map = new window.google.maps.Map(document.getElementById("map"), {
    center: {
      lat: 48,
      lng: 4,
    },
    zoom: 4,
    disableDefaultUI: true,
  });
  let serviceDetails = new window.google.maps.places.PlacesService(map);
  let request = {
    placeId: prediction.place_id,
    sessionToken: sessionToken,
    fields: ["geometry"],
  };

  serviceDetails.getDetails(request, function (place, status) {
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      if (!place.geometry) {
        return;
      }

      details(prediction, place.geometry);
    }
  });
}

const SearchBar = (props) => {
  const classes = useStyles();
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [startTyping, setStartTyping] = useState(false);
  const [sessionToken, setSessionToken] = useState("");

  const [predictions, setPredictions] = useState([]);
  const [prediction, setPrediction] = useState(false);

  const details = (prediction, geometry) => {
    let lat = geometry.location.lat();
    let lon = geometry.location.lng();

    if (lat && lon) {
      setSessionToken();
      setPrediction(false);
      props.onSearchComplete(prediction.description, lat, lon);
    }
  };

  const addPrediction = (_prediction) => {
    setPrediction(true);
    setPredictions([]);
    props.setSearchLoading();
    getDetails(_prediction, details, sessionToken);
  };

  useEffect(() => {
    if (startTyping) {
      setSessionToken(sessiontoken());
    }
  }, [startTyping]);

  useEffect(() => {
    if (sessionToken && !prediction && address.length >= 2) {
      getPredictions(address, countryCode, sessionToken, setPredictions);
    }
  }, [sessionToken, address]);

  const search = (e) => {
    e.preventDefault();
    setAddress(e.target.value);
    if (e.target.value.length >= 2) {
      setStartTyping(true);
    } else {
      setStartTyping(false);
      setSessionToken();
      setPredictions([]);
    }
  };

  useEffect(() => {
    setAddress(props.address);
  }, [props.address]);

  useEffect(() => {
    setCountryCode(props.countryCode);
  }, [props.countryCode]);

  return (
    <div className="Demo__search-bar-container">
      <div className="Demo__search-input-container">
        <TextField
          variant="outlined"
          margin="normal"
          size="small"
          required
          fullWidth
          id="Search"
          label="Search"
          name="Search"
          InputProps={{
            classes: {
              notchedOutline: props.value ? classes.notchedOutline : null,
            },
          }}
          onChange={search}
          value={address}
        />
      </div>
      {predictions && predictions.length > 0 && address && (
        <Paper className="Demo__autocomplete-container">
          <List
            style={{
              marginRight: 8,
              marginLeft: 8,
            }}
          >
            {predictions.map((prediction, i) => (
              <ListItem
                button
                style={{
                  padding: 0,
                  borderBottom: "thin solid #808080",
                }}
                key={i + prediction}
                onClick={() => {
                  setAddress(prediction.description);
                  addPrediction(prediction);
                }}
              >
                <ListItemText primary={prediction.description} />
                <ListItemSecondaryAction
                  style={{
                    marginRight: -16,
                  }}
                >
                  <Ionicon icon="ios-arrow-dropright" />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#D71920",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderColor: "red !important",
  },
}));

export default SearchBar;
