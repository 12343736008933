import React from "react";
import { TextField } from "@material-ui/core/";

const MultipleInputField = ({
  value,
  required,
  placeholder,
  setValue,
  rowsMax,
}) => {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      multiline
      maxRows={rowsMax ?? 4}
      required={required}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      id={placeholder}
      label={placeholder}
      name={placeholder}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  );
};

export default MultipleInputField;
