import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ListItemText,
  ExpansionPanelSummary,
} from "@material-ui/core";
import Ionicon from "react-ionicons";
import moment from "moment-timezone";
import {
  Call,
  CreditCard,
  Favorite,
  FlashOnOutlined,
  Timelapse,
} from "@material-ui/icons";
import { red } from "../../app.config";

export default function Profile({ _user, version, viewVouchers }) {
  const classes = useStyles();

  const [user, setUser] = useState({});
  const [segment, setSegment] = useState({});

  const [f_days, setFdays] = useState();
  const [l_days, setLdays] = useState();

  useEffect(() => {
    if (_user) {
      setUser(_user);

      if (_user.segment) {
        setSegment(_user.segment);

        const f_d =
          _user.segment.first_purchase_date ?? _user.segment.first_order_date;

        if (f_d) {
          const given = moment(f_d, "YYYY-MM-DD");
          const current = moment().startOf("day");
          const _f_days = moment.duration(current.diff(given)).asDays();
          setFdays(_f_days);
        }

        if (_user.segment.last_order_date) {
          const given = moment(_user.segment.last_order_date, "YYYY-MM-DD");
          const current = moment().startOf("day");
          const _l_days = moment.duration(current.diff(given)).asDays();
          setLdays(_l_days);
        }
      }
    }
  }, [_user]);

  const _viewVouchers = () => {
    if (user.prizes.length > 0) {
      viewVouchers();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {user.prepay && (
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "inline-flex",
                backgroundColor: "#D71921",
                borderRadius: 10,
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <CreditCard />
              <div style={{ marginLeft: 5, fontSize: "0.9em", marginTop: 2 }}>
                Prepay Only
              </div>
            </div>
          </div>
        )}
        {user.name && (
          <div>
            <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
              {user.name}
            </div>

            <Grid container style={{ marginTop: 20, fontSize: "0.8em" }}>
              <Grid item sm={6}>
                <div className={classes.phone}>
                  <Call />
                  <div style={{ marginTop: 4, marginLeft: 2 }}>
                    {user.phone}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6}>
                {user.prizes && (
                  <div onClick={_viewVouchers}>
                    <Grid container>
                      <Grid
                        item
                        sm={6}
                        style={{ display: "inline-flex", marginTop: "7%" }}
                      >
                        <div style={{ marginRight: 10, marginLeft: 5 }}>|</div>
                        <div>Vouchers</div>
                      </Grid>
                      <Grid item sm={6} className="segment-data">
                        <div className={classes.voucher}>
                          <div>{user.prizes.length}</div>
                          <Ionicon
                            className={classes.v_icon}
                            icon={"ios-arrow-forward"}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </div>

      {segment.types?.map((type, i) => (
        <TraitItem
          key={i}
          type={type}
          traits={segment.traits}
          f_days={f_days}
          l_days={l_days}
          classes={classes}
        />
      ))}

      <div className="version-button">{version}</div>
    </div>
  );
}

const TraitItem = ({ traits, classes, l_days, f_days, type }) => {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (type === "Statistics") {
      setCollapsed(false);
    }
  }, [type]);

  return (
    <ExpansionPanel
      expanded={!collapsed}
      onChange={() => setCollapsed(!collapsed)}
    >
      <ExpansionPanelSummary
        className={classes.expansion}
        style={{
          borderBottom: "solid #403f3f thin",
          backgroundColor: "#333333",
          minHeight: 40,
          marginTop: type !== "Statistics" && 10,
        }}
      >
        {type === "Statistics" && (
          <FlashOnOutlined
            style={{ marginTop: 5, color: red, transform: "rotate(60deg)" }}
          />
        )}

        {type === "Favourites" && (
          <Favorite style={{ marginTop: 5, color: red }} />
        )}

        {type === "Last" && <Timelapse style={{ marginTop: 5, color: red }} />}

        <ListItemText style={{ marginLeft: 10 }} primary={type} />

        <Ionicon
          className={classes.icon}
          icon={collapsed ? "ios-arrow-down" : "ios-arrow-up"}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansion}>
        <List>
          {traits
            .filter((f) => f.type === type)
            .map((trait, i) => (
              <ListItem className={classes.listItem} key={i}>
                <Grid container>
                  <Grid item sm={4}>
                    <div>{trait.name}</div>
                  </Grid>

                  <Grid item sm={8} className="segment-data">
                    {trait.name === "Last Order" ||
                    trait.name === "First Order" ? (
                      <div className={classes.data}>
                        <div className={classes.timeContainer}>
                          <span className="days">
                            {trait.name === "Last Order" ? l_days : f_days} days
                            ago
                          </span>
                          <span className="date">
                            {moment(trait.key).format("D/MM/YY")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.data}>
                        {trait.name === "Avg Spend" || trait.name === "LTV"
                          ? Number(trait.key.toFixed(2)).toLocaleString()
                          : trait.key}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles({
  container: {
    backgroundColor: "#000",
    color: "#fff",
    height: "100%",
    position: "fixed",
    top: 0,
    width: "25%",
    overflowX: "auto",
  },
  header: {
    borderBottom: "solid #403f3f thin",
    paddingBottom: 30,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 30,
  },
  listItem: {
    borderBottom: "solid #403f3f thin",
    display: "inline-flex",
    fontSize: "0.85em",
    paddingRight: 0,
    paddingLeft: 0,
  },
  data: {
    backgroundColor: "#333333",
    borderRadius: 8,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: "center",
    width: "fit-content",
  },

  voucher: {
    backgroundColor: "#2AB522",
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    width: "fit-content",
    display: "inline-flex",
  },
  phone: {
    backgroundColor: "#2AB522",
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 4,
    display: "inline-flex",
  },

  icon: {
    marginRight: 5,
    marginTop: 5,
    width: 15,
    height: 15,
    fill: "#fff",
  },

  v_icon: {
    width: 15,
    height: 15,
    fill: "#fff",
  },

  expansion: {
    backgroundColor: "#000",
    boxShadow: "none",
    minHeight: 40,
    color: "#fff",
  },

  timeContainer: {
    "& .date": {
      display: "none",
    },
    "&:hover .days": {
      display: "none",
    },
    "&:hover .date": {
      display: "block",
      cursor: "default",
    },
  },
});
