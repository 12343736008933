import React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Grid,
  Button,
  ListItemText,
} from "@material-ui/core/";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerEditConfirmDialog = (props) => {
  return (
    <Dialog
      maxWidth="xs"
      disableEscapeKeyDown
      disableBackdropClick
      open={props.open}
      TransitionComponent={Transition}
    >
      <DialogContent
        style={{
          backgroundColor: "#f4f4f4",
        }}
      >
        <Grid container>
          <Grid item sm={12} style={{ fontSize: 25, textAlign: "center" }}>
            <Typography>Are you sure?</Typography>
          </Grid>
          <Grid
            item
            sm={12}
            style={{ marginBottom: 48, marginTop: 24, textAlign: "center" }}
          >
            {props.newAddress ? (
              <Typography variant="body1">{props.body}</Typography>
            ) : (
              <Typography variant="body1">
                {props.body} will empty the{" "}
                <span style={{ fontWeight: 600 }}>CART</span>
              </Typography>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Button
                fullWidth={true}
                onClick={() => props.yes()}
                variant="contained"
                style={{
                  backgroundColor: "#d9534f",
                  borderRadius: 16,
                  boxShadow: "none",
                }}
              >
                <ListItemText
                  disableTypography
                  style={{ margin: 0 }}
                  primary={
                    <Typography
                      type="body2"
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                    >
                      Yes
                    </Typography>
                  }
                />
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                fullWidth={true}
                onClick={() => props.no()}
                variant="contained"
                style={{
                  backgroundColor: "#e0e0e0",
                  borderRadius: 16,
                  boxShadow: "none",
                }}
              >
                <ListItemText
                  disableTypography
                  style={{ margin: 0 }}
                  primary={
                    <Typography
                      type="body2"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      No
                    </Typography>
                  }
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerEditConfirmDialog;
