import React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Grid,
  Button,
  ListItemText,
} from "@material-ui/core/";
import { red } from "../app.config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Feedback = (props) => {
  return (
    <Dialog
      maxWidth="xs"
      disableEscapeKeyDown
      disableBackdropClick
      open={props.open}
      TransitionComponent={Transition}
    >
      <DialogContent
        style={{
          backgroundColor: "#f4f4f4",
        }}
      >
        <Grid container>
          <Grid item sm={12} style={{ fontSize: 25, textAlign: "center" }}>
            <Typography>Try Again</Typography>
          </Grid>
          <Grid
            item
            sm={12}
            style={{ marginBottom: 48, marginTop: 24, textAlign: "center" }}
          >
            {props.body}
          </Grid>

          <div
            style={{ width: "100%", paddingLeft: "15%", paddingRight: "15%" }}
          >
            <Button
              fullWidth={true}
              onClick={() => props.ok()}
              variant="contained"
              style={{
                backgroundColor: red,
                borderRadius: 16,
                boxShadow: "none",
                color: "#ffff",
              }}
            >
              <ListItemText
                disableTypography
                style={{ margin: 0 }}
                primary={
                  <Typography
                    type="body2"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    OK
                  </Typography>
                }
              />
            </Button>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Feedback;
