import React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Button,
  ListItemText,
} from "@material-ui/core/";
import firebase from "../../../firebase";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeMillis: 60000,
};

remoteConfig.defaultConfig = {
  countries: false,
  default_zone: false,
};

const InnBucksCode = ({ open, onClose, feedback }) => {
  return (
    <Dialog
      maxWidth="md"
      open={open}
      TransitionComponent={Transition}
      onClose={() => onClose()}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          minWidth: 500,
        },
      }}
    >
      <DialogContent
        style={{
          backgroundColor: "#efeff0",
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingTop: "2%",
        }}
      >
        <div style={{ minWidth: 400, padding: "5px 0" }}>
          <h3
            style={{
              fontSize: 20,
              color: "#000",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            InnBucks Code
          </h3>
          <h1
            style={{
              fontSize: 30,
              color: "#000",
              textAlign: "center",
            }}
          >
            {feedback.code}
          </h1>
        </div>
        <Button
          fullWidth={true}
          onClick={() => onClose()}
          variant="contained"
          style={{
            backgroundColor: "green",
            borderRadius: 5,
            padding: 10,
          }}
        >
          <ListItemText
            disableTypography
            style={{ margin: 0 }}
            primary={
              <Typography
                type="body2"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Done
              </Typography>
            }
          />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default InnBucksCode;
