//===================================
// AUTHENTICATION
//===================================

export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

//===================================

export const RESET_EMAIL_FAILED = "RESET_EMAIL_FAILED";
export const RESET_EMAIL_LOADING = "RESET_EMAIL_LOADING";
export const RESET_EMAIL_SUCCESS = "RESET_EMAIL_SUCCESS";
