import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core/";

const SelectZoneField = (props) => {
  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>{props.defaultValue}</InputLabel>
      <Select
        onChange={(e) =>
          e.target.value && props.setValue(JSON.parse(e.target.value))
        }
        label={props.defaultValue}
      >
        <MenuItem value="">
          <em>{props.defaultValue}</em>
        </MenuItem>
        {props.menuitem.map((details, index) => (
          <MenuItem
            className="action-option"
            value={JSON.stringify({ details, zone: details.zone })}
            key={details.zone.uuid}
          >
            {details.zone.name?.en}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectZoneField;
