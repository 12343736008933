import React, { useEffect, useState } from "react";
import { Typography, Card } from "@material-ui/core/";
import Ionicon from "react-ionicons";

// Css
import "../App.css";
import SelectField from "../commons/select.field";
import InputField from "../commons/input.field";

const sources = [
  "Call Center",
  "Whatsapp",
  "Email",
  "Web",
  "Facebook",
  "App",
  "Direct",
  "Kiosk",
  "Other",
];

const Customer = (props) => {
  const [source, setSource] = useState("Call Center");
  const [directions, setDirections] = useState("");
  const [instructions, setInstrunctions] = useState("");
  const [alt_phone, setAltPhone] = useState("");
  const [kra_pin, setKraPin] = useState("");

  const [editSource, setEditSource] = useState(false);
  const [editDirections, setEditDirections] = useState(false);
  const [editInstructions, setEditInstrunctions] = useState(false);
  const [editAltphone, setEditAltphone] = useState(false);

  const [error, setError] = useState({ name: "" });

  useEffect(() => {
    if (props.client.cart.customer.address_instruction) {
      setInstrunctions(props.client.cart.customer.address_instruction);
    }

    if (props.client.cart.customer.directions) {
      setDirections(props.client.cart.customer.directions);
    }

    if (props.client.cart.customer.alt_phone) {
      setAltPhone(props.client.cart.customer.alt_phone);
    }
  }, [props.client]);

  useEffect(() => {
    if (props.user) {
      const { kra_pin: krap } = props.user;

      if (krap) {
        setKraPin(krap);
      }
    }
  }, [props.user]);

  const onChanged = (key, value) => {
    setError({ name: "" });
    const data = {};

    if (key === "kra_pin") {
      setKraPin(value);
      data.kra_pin = value;
    }

    if (key === "directions") {
      setDirections(value);
      data.directions = value;
    }

    if (key === "instructions") {
      setInstrunctions(value);
      data.instructions = value;
    }

    if (key === "source") {
      setSource(value);
      data.source = value;
    }

    if (key === "alt_phone") {
      setAltPhone(value);

      if (value) {
        const isAltPhone = eval(
          /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
        ).test(value);

        if (!isAltPhone) {
          return setError({ name: "alt_phone" });
        }

        data.alt_phone = value;
      }
    }

    props.edit(data);
  };

  return (
    <main>
      <Card
        style={{ margin: 8, borderRadius: 8, boxShadow: "none" }}
        className={props.classes.paper}
      >
        <strong>Complex</strong>
        <Typography style={{ marginBottom: 10 }}>
          {props.client.cart.complex_name}
        </Typography>
        <strong>Zone</strong>
        <Typography style={{ marginBottom: 10 }}>
          {props.client.cart.zone_name}
        </Typography>
        <strong>Method</strong>
        <Typography style={{ marginBottom: 10, textTransform: "capitalize" }}>
          {props.client.cart.delivery_mode}
        </Typography>
        <strong>Source</strong>
        <Typography style={{ marginBottom: 10 }}>
          {editSource ? (
            <span style={{ marginTop: 10 }}>
              <SelectField
                label="Change Source"
                color={source === "Change Source"}
                className={"CheckoutSourceField"}
                menuitem={sources}
                setValue={(v) => onChanged("source", v)}
              />
              <span
                onClick={() => setEditSource(false)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                }}
              >
                save
              </span>
            </span>
          ) : (
            <span>
              {source}
              <span
                onClick={() => setEditSource(true)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                  marginLeft: 10,
                }}
              >
                edit
              </span>
            </span>
          )}
        </Typography>
      </Card>
      <Card
        style={{ margin: 8, borderRadius: 8, boxShadow: "none" }}
        className={props.classes.paper}
      >
        <div style={{ marginBottom: 20 }}>
          <strong>Customer Details</strong>
        </div>
        <Typography style={{ marginTop: 10 }}>
          <strong style={{ marginTop: 10 }}>Customer Name:</strong>
        </Typography>
        <Typography>{props.client.cart.customer.name}</Typography>
        <Typography style={{ marginTop: 10 }}>
          <strong>Tel:</strong>
          {props.client.cart.customer.phone}
        </Typography>

        <div style={{ marginTop: 10 }}>
          {editAltphone ? (
            <>
              <InputField
                setValue={(v) => onChanged("alt_phone", v)}
                placeholder="Alt Phone"
                value={alt_phone}
              />

              {error.name && (
                <p style={{ color: "red" }}>Please enter valid Alt Phone</p>
              )}
              <span
                onClick={() => setEditAltphone(false)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                }}
              >
                Save
              </span>
            </>
          ) : (
            <div style={{ display: "inline-block" }}>
              <div style={{ display: "inline-flex" }}>
                <strong>Alt Phone:</strong>
                {alt_phone}
              </div>
              <span
                onClick={() => setEditAltphone(true)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                  marginLeft: 2,
                }}
              >
                edit
              </span>
            </div>
          )}
        </div>
        <Typography style={{ marginTop: 20 }}>
          <strong>Address:</strong>
          {props.client.cart.customer.address}
        </Typography>

        <div style={{ marginTop: 10 }}>
          {editInstructions ? (
            <>
              <InputField
                setValue={(v) => onChanged("instructions", v)}
                placeholder="Instuctions"
                value={instructions}
              />
              <span
                onClick={() => setEditInstrunctions(false)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                }}
              >
                Save
              </span>
            </>
          ) : (
            <div style={{ display: "inline-block" }}>
              <div style={{ display: "inline-flex" }}>
                <strong>Instructions:</strong>
                {instructions}
              </div>
              <span
                onClick={() => setEditInstrunctions(true)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                  marginLeft: 2,
                }}
              >
                edit
              </span>
            </div>
          )}
        </div>
        <div style={{ marginTop: 10 }}>
          {editDirections ? (
            <>
              <InputField
                setValue={(v) => onChanged("directions", v)}
                placeholder="Directions"
                value={directions}
              />
              <span
                onClick={() => setEditDirections(false)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                }}
              >
                Save
              </span>
            </>
          ) : (
            <div style={{ display: "inline-block" }}>
              <div style={{ display: "inline-flex" }}>
                <strong>Directions:</strong>
                {directions}
              </div>
              <span
                onClick={() => setEditDirections(true)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                  marginLeft: 2,
                }}
              >
                edit
              </span>
            </div>
          )}
        </div>
        {props.kraPin && (
          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
            <strong style={{ flex: "none", marginRight: 10 }}>KRA Pin:</strong>
            <InputField
              setValue={(v) => onChanged("kra_pin", v)}
              placeholder="KRA Pin"
              value={kra_pin}
            />
          </div>
        )}
      </Card>
      <div className="confirm">
        <div style={{ textAlign: "center" }}>
          <Ionicon
            style={{
              fill: "green",
              width: 40,
              height: 40,
            }}
            icon="md-checkmark-circle"
          />
        </div>
      </div>
    </main>
  );
};

export default Customer;
