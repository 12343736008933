import React, { useState, useEffect } from "react";
import {
  Typography,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  List,
  Checkbox,
  Paper,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Ionicon from "react-ionicons";
import { v1 as uuidv1 } from "uuid";

const Group = (props) => {
  const classes = useStyles();

  let selectedItem = [];
  props.options.map((option) => {
    if (option.default) {
      selectedItem.push(option);
    }
  });

  const [selectedItems, setSelectedItems] = useState(selectedItem);
  const [listStyle, setListStyle] = useState("list");
  const [optionQuantity, setOptionQuantity] = useState(2);
  const [error, setError] = useState();
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (props.validation > 0) {
      checkOptions();
    }
  }, [props.validation]);

  const checkOptions = () => {
    if (
      props.required &&
      (selectedItems.length < props.force_min ||
        selectedItems.length > props.force_max)
    ) {
      setListStyle("error");
      setError(true);
      setErrorText("Make sure you selected enough options");
    }

    props.checkValid(
      !(props.required && selectedItems.length < props.force_min)
    );
    return !(props.required && selectedItems.length < props.force_min);
  };

  useEffect(() => {
    selectedItems.map((option) => {
      if (option.default) {
        props.addSize ? props.addSize(option) : props.addOption(option);
      }
    });
  }, []);

  const toggleOption = (item) => {
    item.cart_option_id = uuidv1();

    if (selectedItems.includes(item)) {
      selectedItems.splice(selectedItems.indexOf(item), 1);

      if (
        props.required &&
        (selectedItems.length < props.force_min ||
          selectedItems.length > props.force_max)
      ) {
        setListStyle("error");
        setError(true);
        setErrorText("Make sure you selected enough options");
      }
      props.removeOption(item);
    } else {
      if (selectedItems.length < props.force_max) {
        setSelectedItems((selectedItems) => [...selectedItems, item]);
      } else {
        props.removeOption(selectedItems.pop());
        setSelectedItems([item]);
      }

      props.addSize ? props.addSize(item) : props.addOption(item);
      setListStyle("list");
      setError(false);
    }
  };

  const checked = (item) => {
    let check = selectedItems.filter((f) => f.name.en === item.name.en);

    return check.length > 0;
  };

  const view = () => {
    let errorView = null;
    let sublist = null;

    sublist = selectedItems.map((item) => {
      if (item.groups) {
        let _groups = [];

        item.groups.map((g) => {
          if (props.sizeGroups) {
            props.sizeGroups
              .filter((f) => f.uuid === g)
              .map((grp) => {
                _groups.push(grp);
              });
          }
        });

        return _groups.map((group) => {
          let options = [];
          if (group.options) {
            group.options.map((o) => {
              if (props.sizeGroupOptions) {
                props.sizeGroupOptions
                  .filter((f) => f.uuid === o)
                  .map((opt) => options.push(opt));
              }
            });

            return (
              <Group
                classes={classes}
                key={group.id}
                name={group.name.en}
                required={group.required ? 1 : 0}
                id={group.id}
                addOption={props.addOption}
                removeOption={props.removeOption}
                increaseOptionQuantity={props.increaseOptionQuantity}
                decreaseOptionQuantity={props.decreaseOptionQuantity}
                validation={props.validation}
                currency={props.currency}
                checkValid={props.checkValid}
                force_max={
                  group.force_max == 0
                    ? group.options.length * 5
                    : group.force_max
                }
                force_min={group.force_min}
                options={options.sort((a, b) =>
                  a.position < b.position ? -1 : a.position > b.position ? 1 : 0
                )}
                sizeGroups={props.sizeGroups}
                sizeGroupOptions={props.sizeGroupOptions}
              />
            );
          }
        });
      }
    });

    if (error) {
      errorView = (
        <Typography color="error" align="center" gutterBottom>
          {errorText}
        </Typography>
      );
    }

    return { error: errorView, sublist: sublist };
  };

  return (
    <div>
      <ListItem className={classes.option}>
        <ListItemText
          primary={<div style={{ fontWeight: "bold" }}>{props.name}</div>}
          secondary={props.required ? "Required" : "Optional"}
        />
      </ListItem>
      <Paper
        className={listStyle === "error" ? classes.listError : classes.list}
      >
        <List>
          {view().error}
          {props.options.map((item) => (
            <ListItem
              style={{ height: 48 }}
              className={classes.hover}
              button
              key={item.id}
              onClick={() => (item.available ? toggleOption(item) : null)}
            >
              <Checkbox
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
                className={classes.sizes}
                checked={checked(item)}
              />
              <ListItemText
                primary={
                  <div
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                      fontSize: 14,
                      marginRight: 10,
                    }}
                  >
                    {item.name.en}
                  </div>
                }
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    <div style={{ fontSize: 14, display: "inline-flex" }}>
                      {selectedItems.map(
                        (f) =>
                          f.name.en === item.name.en && (
                            <List
                              style={{ padding: 0, margin: 0, marginLeft: -40 }}
                              key={item.type_id || item.id}
                            >
                              <ListItem
                                style={{
                                  width: "100%",
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  marginRight: -35,
                                  marginTop: -5,
                                }}
                                className="product-group-option-add-sub"
                              >
                                <Button
                                  onClick={() => {
                                    if (
                                      item.type !== "size" &&
                                      item.price &&
                                      item.quantity &&
                                      item.available &&
                                      item.quantity > 1
                                    ) {
                                      setOptionQuantity(optionQuantity - 1);
                                      props.decreaseOptionQuantity(
                                        item.type_id || item.id
                                      );
                                    }
                                  }}
                                  style={{
                                    marginRight: -15,
                                  }}
                                >
                                  <Ionicon icon="ios-remove-circle-outline" />
                                </Button>
                                <ListItemText
                                  style={{
                                    marginRight: -5,
                                  }}
                                  primary={item.quantity || 1}
                                />
                                <Button
                                  onClick={() => {
                                    if (
                                      item.type !== "size" &&
                                      item.price &&
                                      optionQuantity <= props.force_max
                                    ) {
                                      setOptionQuantity(optionQuantity + 1);
                                      props.increaseOptionQuantity(
                                        item.type_id || item.id
                                      );
                                    }
                                  }}
                                >
                                  <Ionicon icon="ios-add-circle-outline" />
                                </Button>
                              </ListItem>
                            </List>
                          )
                      )}
                      <p>
                        {!item.available && (
                          <span
                            style={{
                              padding: 5,
                              color: "#f4f4f4",
                              backgroundColor: "#d9534f",
                              borderRadius: 8,
                            }}
                          >
                            Unavailable
                          </span>
                        )}

                        {item.price > 0 && item.available
                          ? Number(item.prices[props.currency]).toFixed(
                              item.pricePrecision
                            )
                          : ""}
                      </p>
                    </div>
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
      {view().sublist}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#2e2e2e",
    "&$checked": {
      color: "#2e2e2e",
    },
    padding: 0,
  },
  hover: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  checked: {},
  sizes: {
    width: 20,
    height: 20,
  },
  list: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 16,
    padding: 0,
    boxShadow: "none",
  },
  listError: {
    backgroundColor: "#ff000020",
    borderRadius: 10,
    borderColor: "red",
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    boxShadow: "none",
  },
  title: {
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  listTitle: {
    fontWeight: "bold",
    fontSize: 16,
  },
  subTitle: {
    fontStyle: "italic",
    fontSize: 12,
  },
  option: {
    borderBottomColor: "transparent",
    margin: 0,
    padding: 0,
  },
  toolbar: theme.mixins.toolbar,
}));

export default Group;
