/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";

const ActiveTicket = ({ ticket }) => {
  const classes = useStyles();
  const [timeDiff, setTimeDiff] = useState("");
  let comment = {};
  if (ticket.comments) {
    comment = ticket.comments.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return new Date(b.created_at) - new Date(a.created_at);
      }
    })[0];
  }

  useEffect(() => {
    ticketTime();
    const timer = setInterval(() => {
      ticketTime();
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const ticketTime = () => {
    const time = comment.created_at;
    const now = moment();
    const duration = moment.duration(now.diff(time));

    const d =
      duration._data.hours === 0
        ? duration._data.minutes + "mins"
        : duration._data.hours + "hrs " + duration._data.minutes + "mins";
    setTimeDiff(d);
  };

  return (
    <Paper className={classes.paper}>
      <Grid item container>
        <Grid item sm={2}>
          <h6 className={classes.title}>Time</h6>
          <h6 className={classes.data}>{timeDiff}</h6>
        </Grid>

        <Grid item sm={2}>
          <h6 className={classes.title}>User</h6>
          <h6 className={classes.data}>{ticket.customer.name}</h6>
        </Grid>

        <Grid item sm={6}>
          <h6 className={classes.title}>Comment</h6>
          <h6 className={classes.data}>{comment.body}</h6>
        </Grid>

        <Grid item sm={2}>
          <h6 className={classes.title}>State</h6>
          <h6 className={classes.data}>{comment.state?.title}</h6>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  paper: {
    margin: 8,
    marginLeft: 0,
    cursor: "pointer",
    boxShadow: "none",
    borderRadius: 0,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 0,
  },

  data: {
    fontSize: "13px",
    marginTop: 5,
    marginBottom: 0,
    fontWeight: "inherit",
  },
  title: {
    fontSize: "13px",
    margin: 0,
  },
}));

export default ActiveTicket;
