/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Paper, Typography, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import Loader from "react-loader-spinner";

const CollectionLocations = ({
  onAddLocation,
  onEditLocation,
  collectionLocations,
  menuLoading,
  zoneLoading,
}) => {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    setLocations([]);
    if (collectionLocations) {
      collectionLocations.map((_location) => {
        let details;
        if (_location.collection_type !== "instore") {
          details = {
            vehicle_colour: _location.vehicle_colour,
            vehicle_make_model: _location.vehicle_make_model,
            vehicle_registration: _location.vehicle_registration,
          };
        }

        let location = {
          name: _location.name,
          coords: {
            lat: _location.latitude,
            lon: _location.longitude,
          },
          total: _location.total_orders,
          last_ordered_days: _location.last_ordered_days ?? "",
          collection_location: true,
          prepay: _location.prepay,
          complex_uuid: _location.complex_uuid,
          complex_name: _location.complex_name,
          customer_id: _location.customer_id || _location.client_id,
          cart: {
            delivery_mode: _location.collection_type,
            ...details,
            country: _location.country,
            customer: {
              address: _location.address || "",
              address_instruction: _location.address_instruction || "",
              city: _location.city || "",
              directions: _location.directions || "",
              email: _location.email || "",
              location_nickname: _location.location_nickname || "",
              collection_name: _location.collection_name,
              name: _location.name || "",
              phone: _location.phone || "",
              alt_phone: _location.alt_phone || "",
              street: _location.street || "",
            },
            complex_uuid: _location.complex_uuid,
            complex_name: _location.complex_name,
            customer_location: {
              lat: _location.latitude,
              lon: _location.longitude,
            },
          },
        };
        setLocations((locations) => [...locations, location]);
      });
    }
  }, [collectionLocations]);

  const callerLocations = () => {
    return locations.map((location, i) => {
      return (
        <Paper key={i} className={classes.paper} style={{ paddingRight: 0 }}>
          <Grid item container>
            <Grid
              item
              sm={11}
              container
              onClick={() => {
                if (!menuLoading && !zoneLoading) onAddLocation(location);
              }}
            >
              <Grid item sm={location.prepay ? 2 : 3} style={{ padding: 0 }}>
                <Typography variant="caption">Name</Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.name}
                </Typography>
              </Grid>

              <Grid item sm={6} style={{ padding: 0 }}>
                <Typography variant="caption">Complex</Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.complex_name}
                </Typography>
              </Grid>

              <Grid item sm={2} style={{ padding: 0, textAlign: "center" }}>
                <Typography variant="caption">Last Ordered</Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.last_ordered_days}
                </Typography>
              </Grid>
              <Grid item sm={1} style={{ padding: 0, textAlign: "center" }}>
                <Typography variant="caption">Total</Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.total}
                </Typography>
              </Grid>

              {location.prepay && (
                <Grid
                  item
                  sm={1}
                  style={{ textAlign: "center", marginTop: 15 }}
                >
                  <div
                    style={{
                      borderRadius: 10,
                      backgroundColor: "green",
                      color: "#fff",
                    }}
                  >
                    prepay
                  </div>
                </Grid>
              )}
            </Grid>

            <Grid item sm={1} style={{ textAlign: "end" }}>
              {!zoneLoading ? (
                <IconButton
                  onClick={() => {
                    onEditLocation(location);
                    setEditLoading({ index: i, loading: true });
                  }}
                >
                  <MoreVert />
                </IconButton>
              ) : (
                editLoading.index === i && (
                  <div style={{ marginTop: 10, marginRight: 10 }}>
                    <Loader type="Oval" color="grey" height="20" width="20" />
                  </div>
                )
              )}
            </Grid>
          </Grid>
        </Paper>
      );
    });
  };

  return (
    <main className={classes.root}>
      <div style={{ marginBottom: "10%" }}>
        {collectionLocations.length > 0 && callerLocations()}
      </div>
    </main>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  paper: {
    ...theme.mixins.gutters(),
    margin: 8,
    marginLeft: 0,
    cursor: "pointer",
    boxShadow: "none",
    borderRadius: 0,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    padding: 5,
    borderRadius: 100,
    fontSize: 60,
  },
  data: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
  },
}));

export default CollectionLocations;
