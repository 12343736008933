import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import { Circle, DeliveryDining, SupportAgent } from "@styled-icons/material";
import { Dish } from "@styled-icons/boxicons-regular";
import { Close } from "@material-ui/icons";
import moment from "moment-timezone";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const colors = {
  all: "#007bff",
  kitchen: "#007bff",
  rider: "#218838",
  support: "#b4000f",
};

const borderColors = {
  all: "#007bff",
  kitchen: "#479ffc",
  rider: "#539962",
  support: "#b8464f",
};

const icons = {
  all: <Circle />,
  kitchen: <Dish />,
  rider: <DeliveryDining />,
  support: <SupportAgent />,
};

const headings = ["Time Stamp", "State", "User", "Comment", "Type"];

const OrderLogs = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog
        open={props.open}
        className={classes.root}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md"
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <div align="right" style={{ marginBottom: -40 }}>
            <IconButton onClick={props.close} className={classes.closeBtn}>
              <Close />
            </IconButton>
          </div>
          Order - {props.order.order_number} Log
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div
            className={classes.header1}
            spacing={2}
            style={{ gridTemplateColumns: "15% 13% 24% 35% 12%" }}
          >
            {headings.map((title, index) => (
              <div key={index} className={classes.headerItem}>
                <p
                  className={classes.title}
                  style={{
                    textAlign: index === headings.length - 1 ? "end" : "start",
                  }}
                >
                  {title}
                </p>
              </div>
            ))}
          </div>

          {props.order.logs &&
            props.order.logs.map((item, index) => (
              <OrderLogItem key={index} {...item} />
            ))}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const OrderLogItem = ({ created_at, state, user, comment, type }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root_item}
      style={{ gridTemplateColumns: "15% 13% 24% 35% 12%" }}
    >
      <div className={classes.item}>
        <p className={classes.text}>
          {moment(created_at).format("DD-MM-YYYY h:mm")}
        </p>
      </div>

      <div className={classes.item}>
        <div
          className={classes.state}
          style={{
            color: colors[state.toLowerCase()] || colors.all,
            borderColor: borderColors[state.toLowerCase()] || borderColors.all,
          }}
        >
          {icons[state.toLowerCase()] || icons.all}

          <p>{state}</p>
        </div>
      </div>

      <div className={classes.item}>
        <p className={classes.text}>{user}</p>
      </div>

      <div className={classes.item}>
        <p className={classes.text}>{comment}</p>
      </div>

      <div
        className={classes.item}
        style={{
          textAlign: "end",
        }}
      >
        <p className={classes.text}>{type}</p>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  header: {
    padding: "8px 12px",
    fontWeight: 700,
  },

  header1: {
    backgroundColor: "#000",
    width: "100%",
    padding: 6,
    display: "grid",
  },

  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },
  content: {
    borderTop: "3px #fff  solid",
  },
  closeBtn: {},

  root_item: {
    width: "100%",
    padding: 5,
    display: "grid",
    borderBottom: "1px solid #bfbfbf",
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },

  state: {
    display: "inline-flex",
    marginLeft: 0,
    marginTop: 6,
    border: "1px solid #000",
    padding: "3px 4px",
    borderRadius: 20,
    width: "fit-content",
    "& svg": {
      width: 14,
      marginRight: 2,
    },
    "& p": {
      fontSize: 10.5,
      margin: 0,
    },
  },
}));

export default OrderLogs;
