import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  ListItemText,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core/";
import { Close } from "@material-ui/icons";
import MultipleInputField from "../../../commons/multiple.input.field";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const CreateLogDialog = ({ open, onClose, order }) => {
  const classes = useStyles();
  const agent = useSelector((state) => state.auth.user);

  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = async () => {
    setMessage("");

    if (!comment) return;
    try {
      setLoading(true);

      const body = {
        order_uuid: order.order_uuid,
        comment,
        category: "Agent Ticket",
        created_by: agent.email,
        user: agent.email,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      };

      const url = order.base_url + "/rest/order_log";

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          AUTHORIZATION: "y_bHqy-Y5wor07I4tFMuCh2PbUdBR_fhWkFKC8-JyAo",
          TOKEN: "EROmYghE2wZgdGIOgAmliHXJv-kf57eIfVjOJ7ROq2c",
        },
        body: JSON.stringify(body),
      });

      const { log_id, message } = await response.json();

      setMessage(message);
      if (log_id) {
        setSuccess(true);
        setLoading(false);
        await new Promise((r) => setTimeout(r, 3000));
        // onClose();
      }
    } catch (error) {
      setLoading(false);
      setMessage(error.toString());
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={() => {
        if (!loading) {
          onClose();
        }
      }}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          minWidth: 500,
        },
      }}
    >
      <DialogTitle
        style={{
          padding: "2% 5%",
          fontWeight: 700,
        }}
      >
        <div align="right" style={{ marginBottom: -40 }}>
          <IconButton
            onClick={() => {
              if (!loading) {
                onClose();
              }
            }}
            className={classes.closeBtn}
          >
            <Close />
          </IconButton>
        </div>
        Order - {order.order_number}
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "#efeff0",
          padding: "2% 5%",
        }}
      >
        {message && (
          <p
            style={{
              color: success ? "#045904" : "#6e0808",
              margin: 4,
              padding: "2% 5%",
              borderRadius: 3,
              backgroundColor: success ? "#bad6ba" : "#f0c2c2",
            }}
          >
            {message}
          </p>
        )}

        <div style={{ minWidth: 400, marginBottom: 5 }}>
          <MultipleInputField
            placeholder="Write your comment"
            value={comment}
            required={true}
            setValue={(event) => setComment(event)}
            rowsMax={8}
          />
        </div>

        <Button
          fullWidth={true}
          onClick={() => {
            if (!loading) {
              onCreate();
            }
          }}
          variant="contained"
          style={{
            backgroundColor: "green",
            borderRadius: 3,
            padding: 10,
          }}
        >
          <ListItemText
            disableTypography
            style={{ margin: 0 }}
            primary={
              <Typography
                type="body2"
                style={{
                  color: "#fff",
                }}
              >
                {loading ? "Saving..." : "Save"}
              </Typography>
            }
          />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  header1: {
    backgroundColor: "#000",
    width: "100%",
    padding: 6,
    display: "grid",
  },

  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },
  content: {
    borderTop: "3px #fff  solid",
  },
  closeBtn: {},

  root_item: {
    width: "100%",
    padding: 5,
    display: "grid",
    borderBottom: "1px solid #bfbfbf",
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },

  state: {
    display: "inline-flex",
    marginLeft: 0,
    marginTop: 6,
    border: "1px solid #000",
    padding: "3px 4px",
    borderRadius: 20,
    width: "fit-content",
    "& svg": {
      width: 14,
      marginRight: 2,
    },
    "& p": {
      fontSize: 10.5,
      margin: 0,
    },
  },
}));

export default CreateLogDialog;
