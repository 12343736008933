import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Ionicon from "react-ionicons";
import CancelIcon from "@material-ui/icons/Cancel";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { logout } from "./../redux/actions/auth.actions";
import logo from "../assets/dadv2.png";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function DashboardDrawer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const status = useSelector((state) => state);

  return (
    <Drawer anchor="right" open={props.open} onClose={props.close}>
      <div className={classes.list} role="presentation">
        <List>
          <ListItem button>
            <ListItemIcon onClick={props.close}>
              <Ionicon
                className={classes.icon}
                style={{
                  fill: "#ffff",
                }}
                icon="ios-close-circle-outline"
              />
            </ListItemIcon>
            <ListItemText
              style={{ textAlign: "center" }}
              primary={<img src={logo} width="80px" alt="" />}
            />
          </ListItem>

          <ListItem style={{ fontStyle: "italic" }}>
            {status.auth.user.name && (
              <ListItemText primary={status.auth.user.name} />
            )}
          </ListItem>
        </List>

        <List>
          <ListItem
            button
            onClick={() => {
              props.close();
              props.resetPassword();
            }}
          >
            <ListItemIcon style={{ color: "#fff" }}>
              <RotateLeftIcon style={{ width: 25, height: 25 }} />
            </ListItemIcon>
            <ListItemText primary={"Reset Password"} />
          </ListItem>
        </List>

        <List>
          <ListItem
            button
            onClick={() => {
              props.close();
              dispatch(logout());
            }}
          >
            <ListItemIcon style={{ color: "#fff" }}>
              <CancelIcon style={{ width: 25, height: 25 }} />
            </ListItemIcon>
            <ListItemText primary={"logout"} />
          </ListItem>
        </List>
        <Divider style={{ backgroundColor: "#fff" }} />
      </div>
    </Drawer>
  );
}
