/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  List,
  Paper,
  Button,
  Grid,
  Input,
} from "@material-ui/core/";
import { Close, Add, Remove } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import Group from "./product.group";
import {
  getItemDescription,
  getItemName,
  getItemPrice,
  getItemTotal,
} from "../utils/item";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductDialog = (props) => {
  const classes = useStyles();

  const [cartItem, setCartItem] = useState({});
  const [sizes, setSizes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [validation, setCheckValidation] = useState(0);
  const [isChecked, setIsChecked] = useState();
  const [valids, setValids] = useState([]);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [expCheckout, setExpressCheckout] = useState(false);

  useEffect(() => {
    setCartItem({
      ...props.item,
      quantity: 1,
      amount: props.item.price,
      amounts: props.item.prices && { ...props.item.prices },
      options: [],
      brand: props.brand,
      brand_name: props.brand.name.en,
      brand_id: props.brand.id,
      type: props.item.type ? props.item.type : "item",
      type_id: props.item.id,
    });

    if (props.item.size) {
      props.item.size.forEach((s) => {
        props.menu.sizes
          .filter((f) => f.uuid === s)
          .forEach((size) => {
            setSizes((sizes) => [...sizes, size]);
          });
      });
    }

    if (props.item.groups) {
      props.item.groups.forEach((g) => {
        props.menu.groups
          .filter((f) => f.uuid === g)
          .forEach((group) => {
            setGroups((groups) => [...groups, group]);
          });
      });
    }
  }, []);

  useEffect(() => {
    if (valids.length > 0) {
      let t = {};
      if (!valids.includes(false)) {
        const valid = valids.filter((e) => !(t[e.id] === e.id in t));

        if (valid[0]) {
          if (!isChecked && valid[0]) {
            let item = cartItem;

            const currentAt = moment().format("YYYY-MM-DD HH:mm:ss");
            item.time_item_added = currentAt;

            if (currentAt) {
              const cii = currentAt.substring(11, 19).replaceAll(":", "");
              item.cart_item_id = cii;
            }

            if (item.quantity === 0 || !item.quantity) {
              item.quantity = 1;
            }

            props.onAddCartIterm(props, item, props.edit, expCheckout);
            props.close();
          }
        }
      }
    }
  }, [valids, isChecked]);

  const getCartItemAmount = () => {
    let amount = props.item.price;
    let currencies = Object.keys(props.item.prices);
    let amounts = props.item.prices && { ...props.item.prices };

    cartItem.options.map((item) => {
      currencies.map(
        (currency) =>
          (amounts[currency] += item.prices[currency] * (item.quantity || 1))
      );
      amount += item.price * (item.quantity || 1);
    });

    return { amount, amounts };
  };

  const removeOption = (item) => {
    let _cartItem = cartItem;
    let _options = _cartItem.options;

    if (_options.includes(item)) {
      _options.splice(_options.indexOf(item), 1);
    }

    _cartItem.options = _options;
    _cartItem.amount = getCartItemAmount().amount;
    _cartItem.amounts = getCartItemAmount().amounts;
    setCartItem({ ..._cartItem });
  };

  const addOption = (item) => {
    let _cartItem = cartItem;
    let _options = _cartItem.options;

    item.quantity = 1;
    _options.push(item);

    _cartItem.options = _options;
    _cartItem.amount = getCartItemAmount().amount;
    _cartItem.amounts = getCartItemAmount().amounts;
    setCartItem({ ..._cartItem });
  };

  const addSize = (item) => {
    let _cartItem = cartItem;
    let _options = _cartItem.options;

    if (!_options.includes(item)) {
      item.quantity = 1;
      _options.push(item);
    }

    _cartItem.options = _options;
    _cartItem.amount = getCartItemAmount().amount;
    _cartItem.amounts = getCartItemAmount().amounts;
    setCartItem({ ..._cartItem });
  };

  const increaseOptionQuantity = (id) => {
    let item = cartItem;
    let amount = item.price;
    let currencies = Object.keys(item.prices);
    let amounts = item.prices && { ...item.prices };

    item.options = item.options.map((p) => {
      if ((p.id || p.type_id) === id) {
        let option = p;
        let q = option.quantity || 1;
        q++;
        option.quantity = q;
        return option;
      }
      return p;
    });

    item.options.forEach((item) => {
      currencies.map(
        (currency) =>
          (amounts[currency] += item.prices[currency] * (item.quantity || 1))
      );
      amount += item.price * (item.quantity || 1);
    });

    item.amount = amount;
    item.amounts = amounts;
    setCartItem({ ...item });
  };

  const decreaseOptionQuantity = (id) => {
    let item = cartItem;
    let amount = item.price;
    let currencies = Object.keys(item.prices);
    let amounts = item.prices && { ...item.prices };

    item.options = item.options.map((p) => {
      if ((p.id || p.type_id) === id) {
        let option = p;
        let q = option.quantity || 1;
        q--;
        if (option.quantity < 1) return false;
        option.quantity = q;
        return option;
      }
      return p;
    });

    item.options.map((item) => {
      currencies.map(
        (currency) =>
          (amounts[currency] += item.prices[currency] * (item.quantity || 1))
      );
      amount += item.price * (item.quantity || 1);
    });

    item.amount = amount;
    item.amounts = amounts;
    setCartItem({ ...item });
  };

  const checkValid = (valid) => {
    setValids((valids) => [...valids, valid]);
  };

  const addtoCart = () => {
    if (groups.length > 0) {
      setCheckValidation(++groups.length);
    }

    if (sizes.length > 0) {
      setCheckValidation(++sizes.length);
    }

    if (sizes.length <= 0 && groups.length <= 0) checkValid(true);

    valids.splice(valids.indexOf(false));

    return false;
  };

  const expressCheckout = () => {
    if (groups.length > 0) {
      setCheckValidation(++groups.length);
    }

    if (sizes.length > 0) {
      setCheckValidation(++sizes.length);
    }

    if (sizes.length <= 0 && groups.length <= 0) checkValid(true);

    valids.splice(valids.indexOf(false));
    setExpressCheckout(true);
    return false;
  };

  const addToCartItem = (quantity) => {
    let _cartItem = cartItem;
    _cartItem.quantity = quantity;
    _cartItem.time_item_added = moment().format("YYYY-MM-DD HH:mm:ss");
    setCartItem({ ..._cartItem });
  };

  const onInstructionChange = (instructions) => {
    let _cartItem = cartItem;
    _cartItem.instructions = instructions;
    setCartItem({ ..._cartItem });
  };

  const view = () => {
    let quantity =
      cartItem.quantity === 1 ? "1 item" : cartItem.quantity + " items";

    let sizesView = null;
    let groupsView = null;

    if (sizes.length > 0) {
      sizesView = (
        <Group
          addSize={addSize}
          addOption={addOption}
          removeOption={removeOption}
          validation={validation}
          checkValid={checkValid}
          increaseOptionQuantity={increaseOptionQuantity}
          decreaseOptionQuantity={decreaseOptionQuantity}
          currency={props.currency}
          name="Sizes"
          required={1}
          id={1}
          force_max={1}
          force_min={1}
          options={sizes.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          sizeGroupOptions={props.menu.options.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
          sizeGroups={props.menu.groups.sort((a, b) =>
            a.position < b.position ? -1 : a.position > b.position ? 1 : 0
          )}
        />
      );
    }

    if (groups.length > 0) {
      groupsView = groups.map((group) => {
        let options = [];

        if (group.options) {
          group.options.forEach((o) => {
            if (props.menu.options) {
              props.menu.options
                .filter((f) => f.uuid === o)
                .map((opt) => options.push(opt));
            }
          });
        }

        return (
          <Group
            key={group.id}
            name={group.name.en}
            validation={validation}
            checkValid={checkValid}
            required={group.required ? 1 : 0}
            id={group.id}
            currency={props.currency}
            addOption={addOption}
            removeOption={removeOption}
            increaseOptionQuantity={increaseOptionQuantity}
            decreaseOptionQuantity={decreaseOptionQuantity}
            force_max={
              group.force_max === 0
                ? (group.options ?? []).length * 5
                : group.force_max
            }
            force_min={group.force_min}
            options={options.sort((a, b) =>
              a.position < b.position ? -1 : a.position > b.position ? 1 : 0
            )}
            sizeGroupOptions={props.menu.options.sort((a, b) =>
              a.position < b.position ? -1 : a.position > b.position ? 1 : 0
            )}
            sizeGroups={props.menu.groups.sort((a, b) =>
              a.position < b.position ? -1 : a.position > b.position ? 1 : 0
            )}
          />
        );
      });
    }

    return { groups: groupsView, sizes: sizesView, quantity: quantity };
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.close}
    >
      <DialogTitle>
        <div align="right">
          <IconButton onClick={props.close} className={classes.closeBtn}>
            <Close />
          </IconButton>
        </div>
        <div
          align="center"
          style={{
            marginTop: -20,
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          {" "}
          Choose your options
        </div>
      </DialogTitle>
      <DialogContent className={classes.root}>
        <main
          style={{
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <List>
            <ListItem style={{ padding: 0, paddingRight: 90 }}>
              <ListItemText
                primary={
                  <div
                    style={{
                      fontWeight: "bold",
                      marginRight: 80,
                    }}
                  >
                    {getItemName(props.item)}
                  </div>
                }
                secondary={
                  <span style={{ paddingRight: 80 }}>
                    {getItemDescription(props.item)}
                  </span>
                }
              />
              {props.item.price > 0 ? (
                <ListItemSecondaryAction>
                  <ListItemText
                    primary={
                      <div style={{ fontWeight: "bold" }}>
                        {getItemPrice(props.item, props.currency)}
                      </div>
                    }
                  />
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          </List>
          {view().sizes}
          {view().groups}
          <List>
            <ListItem style={{ padding: 0 }}>
              <ListItemText
                primary={<div style={{ fontWeight: "bold" }}>Quantity</div>}
              />
            </ListItem>
            <Paper className={classes.quantityItem}>
              <ListItem style={{ padding: 0, margin: 0 }}>
                <List style={{ padding: 0, width: "100%" }}>
                  <ListItem
                    style={{
                      width: "100%",
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: 4,
                      paddingRight: 4,
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item sm={4} style={{ marginLeft: -16 }}>
                        <Button
                          style={{ fontSize: 25 }}
                          className={classes.btn}
                          onClick={() =>
                            addToCartItem(
                              cartItem.quantity > 1
                                ? String(--cartItem.quantity)
                                : 1
                            )
                          }
                        >
                          <Remove />
                        </Button>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        style={{ textAlign: "center", fontWeight: "bold" }}
                      >
                        <ListItemText primary={String(cartItem.quantity)} />
                      </Grid>
                      <Grid item sm={4} style={{ textAlign: "end" }}>
                        <Button
                          className={classes.btn}
                          style={{ marginRight: -30, fontSize: 25 }}
                          onClick={() =>
                            addToCartItem(String(++cartItem.quantity))
                          }
                        >
                          <Add />
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </ListItem>
            </Paper>
            <ListItem
              button
              style={{ padding: 0 }}
              onClick={() => setComment(!Boolean(comment))}
              className={classes.btn}
            >
              <ListItemText
                style={{
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                }}
                primary={
                  <div style={{ fontWeight: "bold" }}>
                    Add Special Instructions
                  </div>
                }
              />
            </ListItem>
            {comment ? (
              <Paper className={classes.quantityItem}>
                <Input
                  fullWidth={true}
                  align="center"
                  disableUnderline
                  defaultValue={name}
                  onChange={(event) => onInstructionChange(event.target.value)}
                  placeholder="e.g: No pepper / sugar / salt please."
                  error={!Boolean(name)}
                  required={true}
                />
              </Paper>
            ) : null}

            {props.item.express_checkout === "1" && (
              <div
                style={{
                  marginBottom: 8,
                  marginTop: 6,
                }}
              >
                <Button
                  fullWidth={true}
                  onClick={() => expressCheckout()}
                  variant="contained"
                  style={{
                    backgroundColor: "#D71920",
                    borderRadius: 16,
                    textTransform: "capitalize",
                    padding: 0,
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{ color: "#FFFFFF", paddingLeft: 5 }}
                      >
                        {view().quantity}
                      </Typography>
                    }
                  />
                  <ListItemText
                    disableTypography
                    primary={
                      <div
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                        }}
                      >
                        Express Checkout
                      </div>
                    }
                  />
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{ color: "#FFFFFF", paddingRight: 10 }}
                      >
                        {(
                          cartItem.amounts[props.currency] * cartItem.quantity
                        ).toFixed(props.item.pricePrecision)}
                      </Typography>
                    }
                  />
                </Button>
              </div>
            )}

            <div
              style={{
                marginBottom: 48,
                marginTop: 6,
              }}
            >
              <Button
                fullWidth={true}
                onClick={() => addtoCart()}
                variant="contained"
                style={{
                  backgroundColor: props.item.expressCheckout
                    ? "black"
                    : "#D71920",
                  borderRadius: 16,
                  textTransform: "capitalize",
                  padding: 0,
                }}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#FFFFFF" }}>
                      {view().quantity}
                    </Typography>
                  }
                />
                <ListItemText
                  disableTypography
                  primary={
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                      }}
                    >
                      Add to cart
                    </div>
                  }
                />
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#FFFFFF" }}>
                      {getItemTotal(cartItem, props.currency)}
                    </Typography>
                  }
                />
              </Button>
            </div>
          </List>
        </main>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
  flex: {
    flex: 1,
  },
  content: {
    backgroundColor: "#f4f4f4",
    paddingLeft: 80,
    paddingRight: 80,
  },
  closeBtn: {
    border: "thin solid",
    height: 20,
    width: 20,
    fontSize: 15,
    padding: 0,
    marginTop: "-20px",
    marginRight: "-15px",
  },
  quantityItem: {
    borderRadius: 10,
    boxShadow: "none",
    marginBottom: 10,
  },
  btn: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  body: {
    flex: 1,
    alignSelf: "center",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    alignSelf: "center",
  },
  controls: {
    flex: 1,
    width: "100%",
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  listText: {
    color: "#fff",
  },
}));

export default ProductDialog;
