import React, { useState, useEffect } from "react";
import { Paper, Typography, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import Ionicon from "react-ionicons";
import Loader from "react-loader-spinner";

const DeliveryLocations = ({
  onAddLocation,
  onEditLocation,
  deliveryLocations,
  menuLoading,
  zoneLoading,
}) => {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [editLoading, setEditLoading] = useState({});

  useEffect(() => {
    setLocations([]);
    if (deliveryLocations) {
      for (const _location of deliveryLocations) {
        const location = {
          name: _location.name,
          address: _location.address,
          complex: _location.complex_name,
          confirmed: _location.confirmed,
          verified: _location.verified,
          prepay: _location.prepay,
          coords: {
            lat: _location.latitude,
            lon: _location.longitude,
          },
          total: _location.total_orders,
          customer_id: _location.customer_id,
          complex_name: _location.complex_name,
          last_ordered_days: _location.last_ordered_days ?? "",
          delivery_location: true,
          address_id: _location.address_id,
          cart: {
            delivery_mode: "delivery",
            country: _location.country,
            customer: {
              address: _location.address,
              address_instruction: _location.address_instruction,
              directions: _location.directions,
              city: _location.city,
              email: _location.email,
              location_nickname: _location.nickname,
              name: _location.name,
              phone: _location.phone,
              alt_phone: _location.alt_phone,
              street: _location.street,
              suburb: _location.suburb,
            },
            customer_location: {
              lat: _location.latitude,
              lon: _location.longitude,
            },
          },
        };

        if (_location.zone_uuid) {
          location.zone_uuid = _location.zone_uuid;
          location.country_code = _location.country_code;
          location.country_name = _location.country_name;
        }

        setLocations((locations) => [...locations, location]);
      }
    }
  }, [deliveryLocations]);

  const callerLocations = () => {
    return locations.map((location, i) => {
      return (
        <Paper key={i} className={classes.paper} style={{ paddingRight: 0 }}>
          <Grid item container>
            <Grid
              item
              sm={11}
              container
              onClick={() => {
                if (!menuLoading && !zoneLoading) onAddLocation(location);
              }}
            >
              <Grid item sm={location.prepay ? 2 : 3} style={{ padding: 0 }}>
                <Typography variant="caption">Name</Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.name}
                </Typography>
              </Grid>
              <Grid item sm={6} style={{ padding: 0 }}>
                <Typography variant="caption">
                  Address
                  <span
                    style={{
                      textTransform: "uppercase",
                      color: location.verified ? "green" : "gray",
                    }}
                  >
                    <Ionicon
                      className={classes.icon}
                      style={{
                        fill: location.verified ? "green" : "gray",
                        width: 15,
                      }}
                      icon={
                        location.verified
                          ? "md-checkmark-circle"
                          : "ios-close-circle-outline"
                      }
                    />
                    {location.verified ? " Agent Verified" : " unverified"}
                  </span>
                </Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.address}
                </Typography>
              </Grid>

              <Grid item sm={2} style={{ padding: 0, textAlign: "center" }}>
                <Typography variant="caption">Last Ordered</Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.last_ordered_days}
                </Typography>
              </Grid>
              <Grid item sm={1} style={{ padding: 0, textAlign: "center" }}>
                <Typography variant="caption">Total</Typography>
                <Typography variant="body1" className={classes.data}>
                  {location.total}
                </Typography>
              </Grid>
              {location.prepay && (
                <Grid
                  item
                  sm={1}
                  style={{ textAlign: "center", marginTop: 15 }}
                >
                  <div
                    style={{
                      borderRadius: 10,
                      backgroundColor: "green",
                      color: "#fff",
                    }}
                  >
                    prepay
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid item sm={1} style={{ textAlign: "end" }}>
              {!zoneLoading ? (
                <IconButton
                  onClick={() => {
                    onEditLocation(location);
                    setEditLoading({ index: i, loading: true });
                  }}
                >
                  <MoreVert />
                </IconButton>
              ) : (
                editLoading.index === i && (
                  <div style={{ marginTop: 10, marginRight: 10 }}>
                    <Loader type="Oval" color="grey" height="20" width="20" />
                  </div>
                )
              )}
            </Grid>
          </Grid>
        </Paper>
      );
    });
  };

  return (
    <main className={classes.root}>
      <div style={{ marginBottom: "10%" }}>
        {locations.length > 0 && callerLocations()}
      </div>
    </main>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  paper: {
    ...theme.mixins.gutters(),
    margin: 8,
    marginLeft: 0,
    cursor: "pointer",
    boxShadow: "none",
    borderRadius: 0,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  icon: {
    fontSize: "inherit",
    fontStyle: "inherit",
    padding: 0,
    margin: 0,
    marginBottom: -5,
    marginLeft: 2,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    padding: 5,
    borderRadius: 100,
    fontSize: 60,
  },
  data: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
  },
}));

export default DeliveryLocations;
