/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Ionicon from "react-ionicons";

// Components
import InputField from "../../commons/input.field";
import MultipleInputField from "../../commons/multiple.input.field";

const PlaceEdit = (props) => {
  const [name, setName] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [suburb, setSuburb] = useState("");
  const [instructions, setInstructions] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [error, setError] = useState(false);
  const [directions, setDirections] = useState("");

  const [editHouseNumber, setEditHouseNumber] = useState(false);
  const [editStreet, setEditStreet] = useState(false);
  const [editSuburb, setEditSuburb] = useState(false);
  const [editCity, setEditCity] = useState(false);

  useEffect(() => {
    if (props.addressValue) {
      getValue();
    }
    if (props.error) {
      setError(props.error);
    }
  }, [props.addressValue, props.error]);

  useEffect(() => {
    if (props.location.city) {
      setName(props.location.name);
      setZoneName(props.location.zone_name);
      setHouseNumber(props.location.house_number);
      setStreet(props.location.street);
      setCity(props.location.city);
      setDirections(props.location.directions);
      setSuburb(props.location.suburb);
    } else {
      if (props.location.cart) {
        setName(props.location.cart.customer.location_nickname);
        setHouseNumber(props.location.cart.customer.house_number);
        setStreet(props.location.cart.customer.street);
        setCity(props.location.cart.customer.city);
        setDirections(props.location.cart.customer.directions);
        setSuburb(props.location.cart.customer.suburb);
      }
    }
  }, [props.location]);

  const getValue = () => {
    let _error;
    if (!name) _error = true;
    if (!street) _error = true;
    if (!city) _error = true;

    if (name?.length > 50) {
      _error = true;
    }

    if (street?.length > 100) {
      _error = true;
    }

    if (city?.length > 100) {
      _error = true;
    }

    if (_error) {
      setError(_error);
    }

    if (!_error) {
      if (houseNumber && suburb) {
        props.location.address =
          houseNumber + "," + street + ", " + suburb + ", " + city;
      } else if (houseNumber && !suburb) {
        props.location.address = houseNumber + "," + street + ", " + city;
      } else if (!houseNumber && suburb) {
        props.location.address = street + ", " + suburb + ", " + city;
      } else {
        props.location.address = street + ", " + city;
      }

      props.location.name = name;
      props.location.house_number = houseNumber;
      props.location.suburb = suburb;
      props.location.street = street;
      props.location.city = city;
      props.location.zone_name = zoneName;
      props.location.directions = directions;
      props.location.instructions = instructions;
      props.placeEditLocation(props.location);
    } else {
      return false;
    }
  };

  return (
    <div>
      {zoneName ? (
        <div
          style={{
            display: "inline-flex",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              marginBottom: 10,
              marginTop: 20,
              paddingLeft: 20,
            }}
          >
            {zoneName}
          </div>
          <Ionicon
            className={props.classes.icon}
            onClick={() => {
              if (name !== "") {
                props.changeZone();
              } else {
                alert("Please fill in Location Name");
              }
            }}
            style={{
              paddingRight: 8,
              paddingTop: 4,
              cursor: "pointer",
              marginTop: 10,
              marginLeft: 30,
            }}
            icon="md-create"
          />
        </div>
      ) : (
        props.location.cart?.zone_name && (
          <div
            style={{
              fontWeight: "bold",
              marginBottom: 10,
              marginTop: 20,
              paddingLeft: 20,
            }}
          >
            {props.location.cart?.zone_name}
          </div>
        )
      )}

      <div style={{ marginBottom: 10 }}>
        {editHouseNumber ? (
          <InputField
            setValue={(event) => setHouseNumber(event)}
            placeholder="House Number "
            value={houseNumber}
          />
        ) : (
          <div style={{ marginTop: 16 }}>
            <span>House Number: {houseNumber}</span>

            <span
              onClick={() => setEditHouseNumber(true)}
              style={{
                marginRight: 50,
                borderRadius: 10,
                backgroundColor: "grey",
                color: "#ffff",
                paddingRight: 8,
                paddingLeft: 8,
                marginLeft: 10,
              }}
            >
              edit
            </span>
          </div>
        )}
      </div>

      <div style={{ marginBottom: 10 }}>
        {editStreet || (error && (!street || street?.length > 100)) ? (
          <InputField
            required={true}
            error={error && (!street || street?.length > 100)}
            setValue={(event) => setStreet(event)}
            placeholder="Street"
            value={street}
          />
        ) : (
          <div>
            <span>Street: {street}</span>
            {props.isEdit && (
              <span
                onClick={() => setEditStreet(true)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                  marginLeft: 10,
                }}
              >
                edit
              </span>
            )}
          </div>
        )}
      </div>

      <div style={{ marginBottom: 10 }}>
        {editSuburb ? (
          <InputField
            setValue={(event) => setSuburb(event)}
            error={error && suburb?.length > 100}
            placeholder="Suburb"
            value={suburb}
          />
        ) : (
          <div>
            <span>Suburb: {suburb}</span>
            {props.isEdit && (
              <span
                onClick={() => setEditSuburb(true)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                  marginLeft: 10,
                }}
              >
                edit
              </span>
            )}
          </div>
        )}
      </div>

      <div style={{ marginBottom: 10 }}>
        {editCity ? (
          <InputField
            required={true}
            error={error && (!city || city?.length > 100)}
            setValue={(event) => setCity(event)}
            placeholder="City"
            value={city}
          />
        ) : (
          <div>
            <span>City: {city}</span>
            {props.isEdit && (
              <span
                onClick={() => setEditCity(true)}
                style={{
                  marginRight: 50,
                  borderRadius: 10,
                  backgroundColor: "grey",
                  color: "#ffff",
                  paddingRight: 8,
                  paddingLeft: 8,
                  marginLeft: 10,
                }}
              >
                edit
              </span>
            )}
          </div>
        )}
      </div>

      <div>
        {name?.length > 20 && (
          <span style={{ color: "red", fontSize: 10 }}>
            Location name must be less than 20 characters
          </span>
        )}
      </div>
      <InputField
        required={true}
        error={error && (!name || name?.length > 30)}
        setValue={(event) => setName(event)}
        placeholder="Location Name (eg. Home)"
        value={name}
      />
      <InputField
        setValue={(event) => setInstructions(event)}
        placeholder="Instructions (eg. Hoot at gate)"
        value={instructions}
      />
      <MultipleInputField
        setValue={(event) => setDirections(event)}
        placeholder="Directions"
        value={directions}
      />
    </div>
  );
};

export default PlaceEdit;
