export const getItemName = (item) => {
  let name = "";

  if (item.name) {
    if (item.name.en) {
      name = item.name.en;
    }
  }

  return name;
};

export const getItemDescription = (item) => {
  let description = "";

  if (item.description) {
    if (item.description.en) {
      description = item.description.en;
    }
  }

  return description;
};

export const getItemPrice = (item, currency) => {
  let price = item.price ?? 0;

  if (currency) {
    if (item.prices && item.prices[currency]) {
      price = item.prices[currency];
    }
  }

  if (price <= 0) {
    return "";
  }

  return Number(price).toFixed(item.pricePrecision);
};

export const getItemTotal = (item, currency) => {
  let total = item.amount ?? 0;

  if (currency) {
    if (item.amounts && item.amounts[currency]) {
      total = item.amounts[currency];
    }
  }

  if (total <= 0) {
    return "";
  }

  return Number(total).toFixed(item.pricePrecision);
};
