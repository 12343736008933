/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";

import { post } from "../../utils/fetch";
import { BASE_URL } from "../../app.config";
import { Close } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Ecocash = ({ open, onSuccess, order_uuid, number, onClose }) => {
  const [msg, setMsg] = useState("");
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (number) {
      _onPay();
    }
  }, [number]);

  const _onPay = async () => {
    await new Promise((r) => setTimeout(r, 4000));

    setMsg("");

    const payload = {
      number,
      order_uuid,
    };

    const ppUrl = `${BASE_URL}/ecocash/process_payment`;
    const data = await post(ppUrl, payload);
    const { status, message } = data;

    if (status === "PENDING SUBSCRIBER VALIDATION") {
      setStep(1);

      const payload = {
        ...data,
        order_uuid,
      };

      const gpsUrl = `${BASE_URL}/ecocash/get_status`;

      for (let i = 0; i <= 20; i++) {
        const data = await post(gpsUrl, payload);

        await new Promise((r) => setTimeout(r, 4000));

        if (data.status !== "PENDING SUBSCRIBER VALIDATION") {
          if (data.status === "COMPLETED") {
            setStep(2);

            await new Promise((r) => setTimeout(r, 4000));
            onSuccess();

            break;
          } else if (data.status === "FAILED") {
            if (i >= 3) {
              await new Promise((r) => setTimeout(r, 1000));

              setStep(0);
              setMsg("Transaction failed");
              break;
            }
          } else {
            await new Promise((r) => setTimeout(r, 6000));

            setStep(0);
            setMsg("Transaction " + data.status.toLowerCase());
            break;
          }
        } else if (i >= 19) {
          await new Promise((r) => setTimeout(r, 2000));

          setStep(0);
          setMsg("Transaction timed out");
        }
      }
    } else if (message) {
      setMsg(message);
      setStep(0);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      disableEscapeKeyDown
      disableBackdropClick
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 5,
          boxShadow: "none",
          width: 400,
          height: 300,
        },
      }}
    >
      <DialogTitle>
        {step !== 0 && (
          <div
            align="center"
            style={{
              marginTop: -5,
              fontSize: 18,
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            DO NOT REFRESH BROWSER
          </div>
        )}

        {step === 0 && (
          <div align="right">
            <IconButton
              onClick={onClose}
              style={{
                border: "thin solid",
                height: 20,
                width: 20,
                fontSize: 15,
                padding: 0,
                marginTop: "-20px",
                marginRight: "-15px",
              }}
            >
              <Close />
            </IconButton>
          </div>
        )}
      </DialogTitle>
      {step === 0 && (
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "red", fontSize: 15, textAlign: "center" }}>
            {msg}, please try again.
          </p>

          <p style={{ padding: 5 }} />

          <Button
            onClick={() => {
              setStep(1);
              _onPay();
            }}
            variant="contained"
            style={{
              backgroundColor: "green",
              borderRadius: 5,
              textAlign: "center",
              width: "50%",
              color: "#ffff",
              boxShadow: "none",
            }}
          >
            Try Again
          </Button>
        </div>
      )}

      {step === 1 && (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Loader type="ThreeDots" color="#D71920" height="100" width="100" />
          <p style={{ color: "#000", fontSize: 15, padding: 5 }}>
            We have sent a prompt to mobile number <strong>{number}</strong>,
            please request for authorisation.
          </p>
        </div>
      )}

      {step === 2 && (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Loader type="ThreeDots" color="green" height="100" width="100" />
          <p style={{ color: "#000", fontSize: 15, padding: 10 }}>
            Order was successfully paid! Processing your order ...
          </p>
        </div>
      )}
    </Dialog>
  );
};

export default Ecocash;
