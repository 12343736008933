import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper, Button } from "@material-ui/core/";

// Components
import SelectZoneField from "../../commons/select.zone.field";
import MultipleInputField from "../../commons/multiple.input.field";

const ChangeZone = (props) => {
  const initZone = {
    name: {
      en: "Select Zone",
    },
  };
  const [mode, setMode] = useState("message");
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState(initZone);
  const [zoneDetails, setZoneDetails] = useState();
  const [reason, setReason] = useState("");

  const submit = () => {
    if (zone.name.en === "Select Zone") {
      alert("Please select delivery zone, or cancel to change drop location");
    } else {
      props.changeZoneData(zoneDetails, zone);
      props.onResut(true);
    }
  };

  useEffect(() => {
    setZones(props.zones);
  }, [props.zones]);

  const recordNo = () => {
    props.recordNo();
    props.onResut(false);
    props.mode();
  };

  const view = () => {
    let content = null;

    if (mode === "message") {
      content = (
        <div style={{ marginTop: 20, marginBottom: 16 }}>
          <Typography
            align="center"
            variant="body1"
            style={{ marginBottom: 16 }}
          >
            Do you want to Proceed and change a Zone?
          </Typography>
          <Grid container justify="center" spacing={2}>
            <Grid item sm={2}></Grid>
            <Grid item sm={4}>
              <Button
                size="small"
                style={{ margin: 4, backgroundColor: "#ccc" }}
                onClick={recordNo}
              >
                No
              </Button>
            </Grid>
            <Grid item sm={4}>
              <Button
                size="small"
                style={{ margin: 4, backgroundColor: "#D71920" }}
                onClick={() => setMode("zone")}
              >
                <span style={{ color: "#fff" }}>Yes</span>
              </Button>
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
        </div>
      );
    } else if (mode === "zone") {
      content = (
        <div style={{ marginTop: 36, marginBottom: 8 }}>
          <Grid container justify="center" spacing={2}>
            <Grid item sm={12}>
              <SelectZoneField
                color={zone.name.en === "Select Zone" && true}
                defaultValue="Select Zone"
                menuitem={zones}
                setValue={(value) => {
                  setZone(value.zone);
                  setZoneDetails(value.details);
                }}
              />
            </Grid>
            <Grid item sm={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <MultipleInputField
                setValue={(event) => setReason(event)}
                placeholder="Reason"
                value={reason}
              />
            </Grid>
            <Grid
              item
              sm={6}
              style={{ textAlign: "end", marginTop: 10, paddingRight: 0 }}
            >
              <Button
                size="small"
                style={{
                  margin: 4,
                  padding: 0,
                  textTransform: "capitalize",
                  borderRadius: 10,
                  backgroundColor: "#ccc",
                }}
                onClick={recordNo}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item sm={6} style={{ marginTop: 10, paddingLeft: 0 }}>
              <Button
                size="small"
                style={{
                  margin: 4,
                  backgroundColor: "#D71920",
                  padding: 0,
                  textTransform: "capitalize",
                  borderRadius: 10,
                }}
                onClick={() => submit()}
              >
                <span style={{ color: "#fff" }}>Submit</span>
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }
    return content;
  };
  return (
    <Paper
      style={{
        marginTop: 16,
        boxShadow: "none",
        backgroundColor: "transparent",
        fontSize: 13,
      }}
    >
      <Typography align="center" variant="body2" style={{ fontWeight: "bold" }}>
        Change Zone
      </Typography>
      {view()}
    </Paper>
  );
};

export default ChangeZone;
