import authReducer from "./auth.reducer";

const initialState = {
  auth: {
    isAuth: Boolean(localStorage.getItem("token")),
    user: null,
    status: { downloading: false, uploading: false },
  },
};

function rootReducer(state = initialState, action) {
  return {
    auth: authReducer(state.auth, action),
  };
}
export default rootReducer;
