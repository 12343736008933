/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import { Paper, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import moment from "moment-timezone";

// Config
import firebase from "../../../firebase";
import { BASE_URL } from "../../../app.config";
import { post } from "../../../utils/fetch";
import MsisdnDialog from "./msisdn";
import InnBucksCode from "./innbucks.code";
import { AddCommentSharp, EmailRounded } from "@material-ui/icons";
import MessageDialog from "./message";
import CreateLogDialog from "./create_log";

const CurrentOrders = ({
  openOrderDialog,
  addOrderDetails,
  order,
  viewLogs,
  country,
  presets,
}) => {
  const classes = useStyles();
  const [orderDetails, setOrderDetails] = useState(order);
  const [loading, setLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);
  const [retryCreated, setRetryCreated] = useState(false);
  const [showMsisdn, setShowMsisdn] = useState(false);
  const [showPendingRetry, setShowPendingRetry] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [showPresets, setShowPresets] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [duration, setDuration] = useState("");

  useEffect(() => {
    getOrderDuration();

    if (order) {
      setLoading(true);

      if (order.order_source === "Call Center") {
        if (order.order_status === "Pending Payment") {
          setShowPendingRetry(true);
        }
      }

      firebase
        .database()
        .ref("orders")
        .orderByKey()
        .equalTo(order.order_uuid)
        .on("child_added", (snapshot) => {
          const orderVal = snapshot.val();

          setOrderDetails(orderVal);
          setLoading(false);
        });

      firebase
        .database()
        .ref("orders")
        .orderByKey()
        .equalTo(order.order_uuid)
        .on("child_changed", (snapshot) => {
          const orderVal = snapshot.val();

          setOrderDetails(orderVal);
          setLoading(false);
        });

      firebase
        .database()
        .ref("orders")
        .orderByKey()
        .equalTo(order.order_uuid)
        .on("child_removed", (snapshot) => {
          const orderVal = snapshot.val();

          setOrderDetails(orderVal);
          setLoading(false);
        });
    }

    const interval = setInterval(() => {
      getOrderDuration();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getOrderDuration = () => {
    const country_tz = `Africa/${order.customer.city}`.replaceAll(" ", "");

    const placed = moment(order.placed).format("YYYY-MM-DD HH:mm:ss");
    const current = moment().tz(country_tz).format("YYYY-MM-DD HH:mm:ss");
    const hrs = moment(current).diff(placed, "hours");
    const mins = moment(current).diff(placed, "minutes");
    const _duration = moment.duration(moment(current).diff(placed));
    const secs = _duration.asSeconds() % 60;

    let duration;
    if (hrs > 0) {
      if (mins % 60 > 0) {
        duration = hrs + "hr" + ":" + (mins % 60) + "m";
      } else {
        duration = hrs + "hr";
      }
    } else {
      if (mins > 0) {
        duration = mins + " mins";
      } else {
        duration = secs + " secs";
      }
    }

    setDuration(duration);
  };

  const postPayment = async (url, data) => {
    const response = await post(url, data);

    if (response.success) {
      setRetryCreated(true);
      setShowMsisdn(false);
      const { payment } = orderDetails;

      if (payment.payment_brand === "innbucks_ussd") {
        setShowCode(true);
        setFeedback(response);
      }
    }

    setPendingLoading(false);
  };

  const _retryPendingOrder = async (msisdn) => {
    if (!retryCreated) {
      let url;

      const { order_uuid, payment } = orderDetails;

      if (payment.payment_brand !== "innbucks_ussd") {
        setShowMsisdn(false);
      }

      setPendingLoading(true);

      const body = {
        order_uuid: order_uuid,
        msisdn,
      };

      if (payment.payment_brand === "paybylink") {
        url = `${BASE_URL}/cellulant/create_bill`;
      } else if (payment.payment_brand === "innbucks_ussd") {
        url = `${BASE_URL}/innbucks/merchant/process_payment`;
      } else if (payment.payment_brand === "pesapal_paybylink") {
        url = `${BASE_URL}/pesapal/process_payment`;
      } else if (payment.payment_brand === "peach_paybylink") {
        url = `${BASE_URL}/peach/paybylink/process_payment`;
      } else {
        setPendingLoading(false);
      }

      if (url) {
        postPayment(url, body);
      }
    }
  };

  const _viewOrder = () => {
    if (order.order_status.toLowerCase() !== "cancelled") {
      addOrderDetails(order);
      openOrderDialog();
    }
  };

  const _viewLogs = (order) => {
    if (order.logs) {
      addOrderDetails(order);
      viewLogs();
    }
  };

  return orderDetails ? (
    <Paper className={classes.paper} style={{ paddingRight: 0 }}>
      <Grid item container>
        <Grid
          item
          sm={10}
          container
          style={{
            maxWidth: "90%",
            flexBasis: "90%",
          }}
        >
          <Grid item sm={1} onClick={() => _viewOrder(orderDetails)}>
            <h6 className={classes.title}>Order ID</h6>
            <h6 className={classes.data}>{orderDetails.odos_order_number}</h6>
          </Grid>

          <Grid
            item
            sm={orderDetails.logs ? 3 : 4}
            style={{ marginRight: 10, paddingLeft: 10 }}
            onClick={() =>
              orderDetails.tooken_url ? null : _viewOrder(orderDetails)
            }
          >
            <h6 className={classes.title}>
              {orderDetails.delivery_mode === "delivery"
                ? "Delivery"
                : "Collection"}
            </h6>
            <h6 className={classes.data}>
              {orderDetails.delivery_mode === "delivery" ? (
                orderDetails.tooken_url ? (
                  <a
                    href={orderDetails.tooken_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "blue",
                    }}
                  >
                    {orderDetails.customer.address}
                  </a>
                ) : (
                  orderDetails.customer.address
                )
              ) : (
                orderDetails.customer.collection_name
              )}
            </h6>
          </Grid>

          <Grid item sm={2} onClick={() => _viewOrder(orderDetails)}>
            <h6 className={classes.title}>Brands</h6>
            <h6 className={classes.data}>{orderDetails.brands}</h6>
          </Grid>
          {orderDetails.logs && (
            <Grid
              item
              sm={1}
              onClick={() => _viewLogs(orderDetails)}
              style={{ maxWidth: "10%", flexBasis: "10%", textAlign: "center" }}
            >
              <h6 className={classes.title}>Logs</h6>
              <h6 className={classes.data} style={{ color: "blue" }}>
                View Logs
              </h6>
            </Grid>
          )}

          <Grid
            item
            sm={1}
            onClick={() =>
              showPendingRetry
                ? setShowMsisdn(!retryCreated)
                : _viewOrder(orderDetails)
            }
            style={{ marginRight: 10, textAlign: "center" }}
          >
            <h6 className={classes.title}>
              Status{" "}
              {showPendingRetry && (
                <label
                  style={{
                    color: "#ffff",
                    backgroundColor: "green",
                    borderRadius: 10,
                    padding: "2px 6px",
                  }}
                >
                  {pendingLoading
                    ? "Retrying..."
                    : retryCreated
                    ? "Created"
                    : "Retry"}
                </label>
              )}
            </h6>
            <h6 className={classes.data}>{orderDetails.order_status}</h6>
          </Grid>

          <Grid
            item
            sm={2}
            onClick={() => _viewOrder(orderDetails)}
            style={{ maxWidth: "12%", flexBasis: "12%", textAlign: "center" }}
          >
            <h6 className={classes.title}>Time at stage</h6>
            <h6 className={classes.data}>{duration}</h6>
          </Grid>

          <Grid
            item
            sm={1}
            onClick={() => _viewOrder(orderDetails)}
            style={{
              maxWidth: "8.9%",
              flexBasis: "8.9%",
              textAlign: "center",
            }}
          >
            <h6 className={classes.title}>Overall Time</h6>
            <h6 className={classes.data} style={{ textAlign: "center" }}>
              {orderDetails.overall_time}
            </h6>
          </Grid>
        </Grid>

        <Grid
          item
          container
          spacing={1}
          style={{
            textAlign: "end",
            maxWidth: "10%",
            flexBasis: "10%",
          }}
        >
          <Grid item sm={4} />
          <Grid item sm={4} onClick={() => setShowPresets(true)}>
            <IconButton>
              <EmailRounded style={{ color: "green" }} />
            </IconButton>
          </Grid>

          <Grid item sm={4} onClick={() => setAddComment(true)}>
            <IconButton>
              <AddCommentSharp style={{ color: "#D71920" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {showMsisdn && (
        <MsisdnDialog
          open={showMsisdn}
          msisdn={orderDetails.msisdn}
          onClose={() => setShowMsisdn(false)}
          onRetry={_retryPendingOrder}
          country={country}
          pendingLoading={pendingLoading}
        />
      )}
      {showCode && (
        <InnBucksCode
          open={showCode}
          onClose={() => setShowCode(false)}
          feedback={feedback}
        />
      )}

      <MessageDialog
        presets={presets}
        number={order.customer.phone}
        order_uuid={order.order_uuid}
        open={showPresets}
        close={() => setShowPresets(false)}
      />

      <CreateLogDialog
        order={order}
        open={addComment}
        onClose={() => setAddComment(false)}
      />
    </Paper>
  ) : (
    loading && (
      <div style={{ textAlign: "center" }}>
        <Loader type="Circles" color="#D71920" height="50" width="50" />
      </div>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  paper: {
    margin: 8,
    marginLeft: 0,
    cursor: "pointer",
    boxShadow: "none",
    borderRadius: 0,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },

  data: {
    fontSize: "13px",
    marginTop: 5,
    marginBottom: 0,
    fontWeight: "inherit",
  },

  title: {
    fontSize: "13px",
    margin: 0,
  },
}));

export default CurrentOrders;
