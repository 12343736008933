import {
  AUTH_SUCCESS,
  AUTH_LOADING,
  AUTH_FAILED,
  RESET_EMAIL_SUCCESS,
  RESET_EMAIL_LOADING,
  SET_CURRENT_USER,
  RESET_EMAIL_FAILED,
} from "../action.types";

const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_LOADING:
      return { isAuth: false, isLoading: true, user: action.payload };
    case RESET_EMAIL_LOADING:
      return { isAuth: false, isEmailSentLoading: true };
    case RESET_EMAIL_FAILED:
      return {
        isEmailSentLoading: false,
        emailSent: false,
        error: action.payload,
      };
    case RESET_EMAIL_SUCCESS:
      return {
        isAuth: false,
        isEmailSentLoading: false,
        emailSent: true,
        message: action.payload,
      };
    case AUTH_FAILED:
      return { isAuth: false, isLoading: false, error: action.payload };
    case AUTH_SUCCESS:
      return { isAuth: true, user: action.payload };
    case SET_CURRENT_USER:
      return { isAuth: true, user: action.payload };
    default:
      return state;
  }
};

export default authReducer;
