import React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  Button,
} from "@material-ui/core/";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import pending from "../../../assets/pending.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PendingOrderAlert = (props) => {
  const classes = useStyles();
  const isd = props.order.delivery_mode === "delivery";

  return (
    <Dialog
      maxWidth="sm"
      disableEscapeKeyDown
      disableBackdropClick
      open={props.open}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 10,
          boxShadow: "none",
          minWidth: 500,
        },
      }}
    >
      <DialogContent
        style={{
          padding: 0,
        }}
        className={classes.root}
      >
        <div align="right">
          <IconButton onClick={props.close} className={classes.closeBtn}>
            <Close />
          </IconButton>
        </div>
        <div
          style={{
            marginTop: -20,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={pending} width="50%" alt="Pending Payment" />
        </div>
        <div style={{ padding: "0 3%" }}>
          <p>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>APP:</span>{" "}
            {props.order.app} v{props.order.version}
          </p>
          <p>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>MODE:</span>{" "}
            {props.order.delivery_mode}
          </p>

          <p>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>ORDER REF:</span>{" "}
            {props.order.order_uuid}
          </p>
          <p>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>
              ORDER STATUS:
            </span>{" "}
            {props.order.order_status}
          </p>

          <p>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>
              {isd ? "ADDRESS" : "COLLECTION"}:
            </span>{" "}
            {isd
              ? props.order.customer.address
              : props.order.customer.collection_name}
          </p>
        </div>
        <Button
          onClick={props.onViewOrder}
          variant="contained"
          style={{
            borderRadius: 0,
            width: "100%",
            padding: "3% 0",
            backgroundColor: "green",
            fontWeight: "bold",
            color: "#ffff",
          }}
        >
          View Order
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
  flex: {
    flex: 1,
  },
  header: { backgroundColor: "#333333", color: "#fff", paddingTop: 8 },
  content: {
    backgroundColor: "#f4f4f4",
    paddingLeft: 80,
    paddingRight: 80,
  },
  closeBtn: {
    backgroundColor: "#D71920",
    height: 30,
    width: 30,
    fontSize: 20,
    padding: 8,
    color: "#ffff",
    marginTop: 5,
    marginRight: 5,
    "&:hover": {
      backgroundColor: "#D71920",
      height: 32,
      width: 32,
    },
  },

  toolbar: theme.mixins.toolbar,
  logo: {
    alignSelf: "center",
  },
}));

export default PendingOrderAlert;
