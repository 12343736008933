import React from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faMotorcycle,
  faUsers,
  faCheckSquare,
  faCheckCircle,
  faEllipsisV,
  faUser,
  faChartBar,
  faDollarSign,
  faCalendar,
  faCheck,
  faUserEdit,
  faStickyNote,
  faRoad,
} from "@fortawesome/free-solid-svg-icons";
import Ionicon from "react-ionicons";
import Loader from "react-loader-spinner";
import appicon from "../assets/appicon.png";

library.add(
  faMotorcycle,
  faUsers,
  faCheckSquare,
  faCheckCircle,
  faEllipsisV,
  faUser,
  faChartBar,
  faCalendar,
  faDollarSign,
  faCheck,
  faUserEdit,
  faCircle,
  faStickyNote,
  faRoad
);
const useStyles = makeStyles((theme) => ({
  dialog: {
    boxShadow: "none",
    borderRadius: 0,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviousOrderDialog = ({
  onClose,
  open,
  order,
  callingNumber,
  tax,
  loadPreviousOrder,
}) => {
  const classes = useStyles();

  const renderLineItems = () => {
    let _lineItem, display;
    let displays = [];

    if (order && order.line_items) {
      _lineItem = order.line_items.map((lineitem) => lineitem);
    }

    if (_lineItem) {
      display = _lineItem.map((lineitem, i) => (
        <ListItem
          key={lineitem.id + i}
          style={{
            borderBottom: "thin solid #dddddd",
            paddingBottom: 8,
            padding: 0,
            paddingTop: 8,
          }}
        >
          <Grid item sm={12} style={{ paddingBottom: 8 }}>
            <Grid container style={{ fontWeight: 550, paddingBottom: 8 }}>
              <Grid sm={6} item>
                {typeof lineitem.name === "object"
                  ? lineitem.name.en
                  : lineitem.name}
              </Grid>
              <Grid sm={2} item style={{ textAlign: "center" }}>
                {Math.round(lineitem.price) !== 0 ? lineitem.price : null}
              </Grid>
              <Grid sm={2} item style={{ textAlign: "center" }}>
                {Math.round(lineitem.price) !== 0
                  ? Math.round(lineitem.qty)
                  : null}
              </Grid>
              <Grid sm={2} item style={{ textAlign: "center" }}>
                {lineitem.total
                  ? lineitem.total
                  : Math.round(lineitem.price) !== 0
                  ? lineitem.price
                  : null}
              </Grid>
            </Grid>
            {lineitem.addons &&
              lineitem.addons.map((extra, i) => (
                <Grid container key={extra.id + i}>
                  <Grid item sm={6}>
                    {typeof extra.name === "object"
                      ? extra.name.en
                      : extra.name}
                  </Grid>
                  <Grid item sm={2} style={{ textAlign: "center" }}>
                    {extra.price !== "0.00" ? extra.price : null}
                  </Grid>
                  <Grid item sm={2} style={{ textAlign: "center" }}>
                    {Math.round(extra.qty)}
                  </Grid>
                  <Grid item sm={2} style={{ textAlign: "center" }}>
                    {extra.total !== "0.00" ? extra.total : null}
                  </Grid>
                </Grid>
              ))}
            {lineitem.instructions ? (
              <Grid item sm={12}>
                {lineitem.instructions}
              </Grid>
            ) : null}
          </Grid>
        </ListItem>
      ));
      displays.push(display);
    }

    return displays;
  };

  const loadOrder = () => {
    loadPreviousOrder();
    onClose();
  };

  return order ? (
    <Dialog
      onClose={() => onClose(false)}
      className={classes.dialog}
      maxWidth="sm"
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 48,
          paddingRight: 48,
        }}
      >
        <div align="right" style={{ marginTop: 5, marginRight: -40 }}>
          <Ionicon
            icon="ios-close-circle-outline"
            onClick={() => onClose(false)}
          />
        </div>
        <Grid container style={{ fontSize: "0.9rem", marginTop: -10 }}>
          <Grid item sm={10}>
            <List>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.customer.name}
                />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText style={{ margin: 0 }} primary={callingNumber} />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.customer.address}
                />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.customer.addressInstruction}
                />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={
                    <div>
                      <span style={{ fontWeight: "bold" }}>Placed: </span>
                      {order.placed}
                    </div>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={2} style={{ marginTop: -10, marginLeft: -10 }}>
            <img src={appicon} width={86} height={86} alt="Dial a Delivery" />
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent
        style={{
          paddingLeft: 48,
          paddingRight: 48,
        }}
      >
        <List style={{ fontSize: "0.9rem" }}>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="chart-bar"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0, cursor: "pointer" }}
                  primary={order.complex_name}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="check-circle"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={2}>
                <ListItemText
                  style={{
                    margin: 0,
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                  primary={order.order_status}
                />
              </Grid>
              <Grid item sm={5}></Grid>
            </Grid>
          </ListItem>
          {order.driver_name && (
            <ListItem
              style={{
                padding: 0,
                paddingBottom: 8,
                paddingTop: 8,
                borderBottom: "thin solid #dddddd",
              }}
            >
              <Grid container>
                <Grid item sm={2} style={{ maxWidth: "8%" }}>
                  <FontAwesomeIcon
                    icon="motorcycle"
                    style={{ width: "1.2em", height: "1.2em" }}
                  />
                </Grid>
                <Grid item sm={3}>
                  <ListItemText
                    style={{ margin: 0, cursor: "pointer" }}
                    primary={order.driver_name}
                  />
                </Grid>
              </Grid>
            </ListItem>
          )}
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="dollar-sign"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.payment_type}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="road"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.delivery_mode}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="user"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.order_source}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="sticky-note"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={`Order - ${order.order_number}`}
                />
              </Grid>
            </Grid>
          </ListItem>
          {renderLineItems()}
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
            }}
          >
            <Grid container>
              <Grid item sm={12} style={{ fontWeight: "bold" }}>
                <ListItemText style={{ margin: 0 }} primary="Total" />
                <ListItemSecondaryAction
                  style={{ marginTop: -10, top: "unset" }}
                >
                  <ListItemText
                    style={{ margin: 0 }}
                    primary={order.order_total_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  />
                </ListItemSecondaryAction>
              </Grid>
              <Grid item sm={12}>
                <ListItemText style={{ margin: 0 }} primary="Vat" />
                <ListItemSecondaryAction
                  style={{ marginTop: -10, top: "unset" }}
                >
                  <ListItemText
                    style={{ margin: 0 }}
                    primary={order.order_tax_value.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  />
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions
        style={{
          borderTop: "thin solid #dddddd",
        }}
      >
        <Button
          onClick={() => loadOrder()}
          color="primary"
          style={{
            textTransform: "capitalize",
            backgroundColor: "#bcbec0",
            padding: 0,
            width: "15%",
          }}
        >
          Load Order
        </Button>
        <Button
          onClick={() => onClose(false)}
          color="primary"
          style={{
            textTransform: "capitalize",
            backgroundColor: "#d71920",
            padding: 0,
            color: "#ffff",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <div style={{ position: "absolute", top: "50%", right: "47%" }}>
      <Loader type="Circles" color="#D71920" height="100" width="100" />
    </div>
  );
};

export default PreviousOrderDialog;
