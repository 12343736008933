import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Grid,
  Button,
  ListItemText,
} from "@material-ui/core/";
import Ionicon from "react-ionicons";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CheckoutCompleteDialog = (props) => {
  const back = () => {
    const bu = window.location.origin + "/ordering";
    window.location.replace(bu);
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      const bu = window.location.origin + "/ordering";
      window.location.replace(bu);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      fullScreen
      disableEscapeKeyDown
      disableBackdropClick
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => props.onClose()}
    >
      <DialogContent
        style={{
          backgroundColor: "#efeff0",
          paddingLeft: "35%",
          paddingRight: "35%",
          paddingTop: 80,
        }}
      >
        <Grid container>
          <Grid item sm={12} style={{ fontSize: 35, textAlign: "center" }}>
            <Typography style={{ color: "#39b54a" }}>Order Queued</Typography>
            <div style={{ textAlign: "center" }}>
              <Ionicon
                style={{
                  fill: "#39b54a",
                  width: 80,
                  height: 80,
                }}
                icon="ios-checkmark-circle-outline"
              />
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            style={{ marginBottom: 48, marginTop: 24, textAlign: "center" }}
          >
            <Typography
              variant="body1"
              style={{ marginBottom: 26 }}
            >{`Thank you for your order ${props.name}`}</Typography>
            <Typography variant="body1">Notes can be included here</Typography>
          </Grid>
          <Grid
            item
            sm={12}
            style={{ marginBottom: 10, marginTop: 10, textAlign: "center" }}
          >
            <div style={{ textAlign: "center", marginLeft: "42%" }}>
              <CountdownCircleTimer
                isPlaying
                duration={10}
                size={80}
                colors={[
                  ["#004777", 0.33],
                  ["#F7B801", 0.33],
                  ["#A30000", 0.33],
                ]}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>
          </Grid>

          <Grid item sm={12}>
            <Button
              fullWidth={true}
              onClick={() => back()}
              variant="contained"
              style={{
                backgroundColor: "#D71920",
                borderRadius: 0,
                padding: 16,
              }}
            >
              <ListItemText
                disableTypography
                style={{ margin: 0 }}
                primary={
                  <Typography
                    type="body2"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Back to Search
                  </Typography>
                }
              />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutCompleteDialog;
