export const sort_number = (a, b) => {
  if (a > b) {
    return -1;
  }

  if (a < b) {
    return 1;
  }
  return 0;
};
