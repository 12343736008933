import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Button,
  ListItemText,
} from "@material-ui/core/";
import InputField from "../../../commons/input.field";
import { phoneSanitizer } from "../../../utils/other/sanitizers";
import firebase from "../../../firebase";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeMillis: 60000,
};

remoteConfig.defaultConfig = {
  countries: false,
  default_zone: false,
};

const MsisdnDialog = ({
  open,
  onRetry,
  onClose,
  msisdn,
  country,
  pendingLoading,
}) => {
  const [smsNumber, setSmsNumber] = useState("");

  useEffect(() => {
    if (msisdn) {
      setSmsNumber(msisdn);
    }
  }, [msisdn]);

  const onConfirm = () => {
    const { dial_code } = country;

    if (dial_code) {
      const sms_number = phoneSanitizer(dial_code + smsNumber);

      onRetry(sms_number.replace("+", ""));
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      TransitionComponent={Transition}
      onClose={() => onClose()}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          minWidth: 500,
        },
      }}
    >
      <DialogContent
        style={{
          backgroundColor: "#efeff0",
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingTop: "2%",
        }}
      >
        <div style={{ minWidth: 400 }}>
          <InputField
            placeholder="Enter sms number"
            value={smsNumber}
            required={true}
            setValue={(event) => setSmsNumber(event)}
          />
        </div>
        <Button
          fullWidth={true}
          onClick={() => {
            if (!pendingLoading) {
              onConfirm();
            }
          }}
          variant="contained"
          style={{
            backgroundColor: "#D71920",
            borderRadius: 5,
            padding: 10,
          }}
        >
          <ListItemText
            disableTypography
            style={{ margin: 0 }}
            primary={
              <Typography
                type="body2"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                {pendingLoading ? "Loading..." : "Confirm"}
              </Typography>
            }
          />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default MsisdnDialog;
