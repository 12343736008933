/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  IconButton,
  ListItem,
  Button,
  List,
  Paper,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Card,
  Grid,
  CardHeader,
} from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircle";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import { makeStyles } from "@material-ui/core/styles";
import Ionicon from "react-ionicons";
import Loader from "react-loader-spinner";
import moment from "moment-timezone";

// Configs
import { BASE_URL, red } from "../app.config";
import InputField from "../commons/input.field";
import { Redeem } from "@material-ui/icons";
import { put } from "../utils/fetch";

const Cart = (props) => {
  const classes = useStyles();
  const [cart, setCart] = useState(props.cart);
  const [instructions, setInstructions] = useState(props.instruction);
  const [comment, setComment] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [proceedMessage, setProceedMessage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCart(props.cart);
  }, [props.cart]);

  useEffect(() => {
    if (instructions) {
      if (props.checkout === true) {
        props.setLoading(true);
        const interval = setTimeout(() => {
          checkoutUpdate("Order instruction updated");
        }, 2000);
        return () => clearTimeout(interval);
      }
    }
  }, [instructions]);

  const removeCartItem = async (index) => {
    let _cart = cart;
    if (cart[index].type === "voucher") {
      props.restoreVoucher(cart[index]);
    }
    _cart.splice(index, 1);
    setCart((cart) => [...cart]);
    props.updateCart(cart, true);
  };

  const getCartItemAmount = (item) => {
    let amount = item.price;
    let currencies = Object.keys(item.prices);
    let amounts = item.prices && { ...item.prices };
    item.options.map((option) => {
      currencies.map(
        (currency) =>
          (amounts[currency] +=
            option.prices[currency] * (option.quantity || 1))
      );
      amount += option.price * (option.quantity || 1) || 0;
    });
    return { amount, amounts };
  };

  const updateCartItem = (item, index) => {
    let _cart = cart;
    item.amount = getCartItemAmount(item).amount;
    item.amounts = getCartItemAmount(item).amounts;
    item.time_item_updated = moment().format("YYYY-MM-DD HH:mm:ss");
    _cart[index] = item;
    setCart((crt) => [..._cart]);
    props.updateCart(_cart);
  };

  const checkoutUpdate = (update_message) => {
    let line_items = cart.map((item) => {
      if (item.total_item_price) delete item.total_item_price;
      let total_item_price;

      if (item.amounts) {
        total_item_price = item.amounts[props.currency] * item.quantity;
      } else {
        total_item_price = item.prices[props.currency] * item.quantity;
      }

      return {
        total_item_price,
        ...item,
        options: item.options?.map((option) => {
          return {
            ...option,
            type_id: option.id,
          };
        }),
      };
    });

    let data = {
      phone: props.phone,
      order_instructions: instructions,
      currency: props.currency,
      version: props.version,
      update_message,
      line_items: line_items,
    };
    setLoading(true);

    if (BASE_URL) {
      put(`${BASE_URL}/cart/${props.orderUUID}/items`, data)
        .then((response) => {
          props.setMyTotals({
            order_total_price: response.order_total_price,
            sub_total_price: response.sub_total_price,
            order_tax_value: response.order_tax_value,
          });

          if (response.checkout) {
            if (props.checkout === true) {
              props.collectionTimes(response.collectionTimes);
              props.confirm();
            } else {
              props.checkout(true);
            }
            setLoading(false);
          } else {
            setProceedMessage(response.message);
            if (props.checkout !== true) {
              setConfirm(true);
            }

            setLoading(false);
          }

          if (props.checkout === true) props.setLoading(false);
        })
        .catch((e) => {
          setProceedMessage({
            title: "Network Error, Please try again",
          });
          setLoading(false);
          setConfirm(false);
        });
    }
  };

  const checkout = () => {
    if (props.orderInstructions) props.orderInstructions(instructions);
    if (props.checkout === true) {
      checkoutUpdate("Items updated inside confirm cart");
    } else {
      if (props.proceed) {
        props.checkout(true);
      } else {
        checkoutUpdate("Items updated inside checkout cart");
      }
    }
  };

  return (
    <div
      style={{
        overflowY: "scroll",
        backgroundColor: "#f4f4f4",
      }}
    >
      {((props.proceedMessage && confirm) || proceedMessage) && (
        <ListItem
          style={{
            backgroundColor: `${
              (props.proceedMessage && props.proceedMessage.bgcolour) ||
              proceedMessage.bgcolour
            }`,
            borderRadius: 4,
            marginBottom: 2,
          }}
        >
          <ListItemIcon>
            <Ionicon
              icon="ios-information-circle-outline"
              color="#000"
              fontSize="36px"
            />
          </ListItemIcon>
          <ListItemText
            primary={proceedMessage.title}
            secondary={
              proceedMessage.description && proceedMessage.description.en
            }
          />
        </ListItem>
      )}
      {cart.map((item, index) => (
        <CartItem
          item={item}
          key={Math.random()}
          editCart={props.editCart}
          index={index}
          updateCartItem={updateCartItem}
          removeCartItem={removeCartItem}
          classes={classes}
          checkout={props.checkout}
          currency={props.currency}
          isLoading={props.cartLoading || loading}
          availableCurrency={props.availableCurrency}
        />
      ))}

      <ListItem button onClick={() => setComment(!comment)}>
        <ListItemText
          style={{
            textDecorationStyle: "solid",
          }}
          className={classes.primary}
          primary="Add Comments"
        />
      </ListItem>
      {comment || instructions ? (
        <div style={{ marginRight: 8, marginLeft: 8 }}>
          <InputField
            placeholder="Comment"
            value={instructions}
            setValue={(value) => setInstructions(value)}
          />
        </div>
      ) : null}

      {props.myTotals.order_total_price > 0 && cart.length > 0 ? (
        <CartTotals
          myTotals={props.myTotals}
          classes={classes}
          pricePrecision={cart[0]?.pricePrecision}
          isLoading={props.cartLoading || loading}
        />
      ) : null}
      {props.checkout !== true && (
        <div
          onClick={props.vouchers > 0 ? props.viewVouchers : undefined}
          style={{
            display: "inline-flex",
            color: red,
            border: `solid ${red} thin`,
            width: "-webkit-fill-available",
            textAlign: "center",
            backgroundColor: "#ffff",
            paddingTop: 8,
            paddingBottom: 8,
            cursor: "pointer",
          }}
        >
          <Grid container>
            <Grid item sm={2}>
              <Redeem />
            </Grid>
            <Grid item sm={8}>
              <div style={{ fontWeight: "bold" }}>Add a voucher to cart</div>
            </Grid>
            <Grid item sm={2}>
              <div>
                <span
                  style={{
                    backgroundColor: red,
                    color: "#ffff",
                    borderRadius: 10,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 2,
                    paddingTop: 2,
                  }}
                >
                  {props.vouchers}
                </span>{" "}
                +
              </div>
            </Grid>
          </Grid>
        </div>
      )}

      <div className={props.checkout === true ? "confirm" : ""}>
        {props.cartLoading || loading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: props.proceedMessage && -30,
            }}
          >
            <Loader type="ThreeDots" color="#D71920" height="100" width="100" />
          </div>
        ) : props.checkout === true ? (
          <div style={{ textAlign: "center" }}>
            <Ionicon
              style={{
                fill: "green",
                width: 40,
                height: 40,
              }}
              icon="md-checkmark-circle"
            />
          </div>
        ) : (
          <Button
            fullWidth={true}
            onClick={() => checkout()}
            variant="contained"
            style={{ backgroundColor: "#D71920", borderRadius: 0 }}
          >
            <ListItemText
              disableTypography
              style={{ margin: 0 }}
              className={classes.primary}
              primary={
                <Typography
                  type="body2"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Checkout
                </Typography>
              }
            />
          </Button>
        )}
      </div>
    </div>
  );
};

const CartItem = (props) => {
  const currency = props.currency;
  let availableCurrency = props.availableCurrency;

  if (props.item.amounts) {
    props.item.amount = props.item.amounts[currency];
  } else if (props.item.prices) {
    props.item.amount = props.item.prices[currency];
  } else {
    props.item.amount = props.item.price;
  }

  if (!props.item.quantity) {
    props.item.quantity = 1;
  }

  const [quantity, setQuantity] = useState(props.item.quantity);

  const increaseQuantity = () => {
    let q = quantity;
    q++;
    setQuantity(q);
    let item = props.item;
    item.quantity = q;
    props.updateCartItem(item, props.index);
  };

  const decreaseQuantity = () => {
    let q = quantity;
    q = q > 1 ? q - 1 : 1;
    setQuantity(q);
    let item = props.item;
    item.quantity = q;
    props.updateCartItem(item, props.index);
  };

  const increaseOptionQuantity = (id) => {
    let item = props.item;
    item.options = item.options.map((p) => {
      if ((p.id || p.type_id) === id) {
        let option = p;
        let q = option.quantity || 1;
        q++;
        option.quantity = q;
        return option;
      }
      return p;
    });
    props.updateCartItem(item, props.index);
  };

  const decreaseOptionQuantity = (id) => {
    let item = props.item;
    item.options = item.options.map((p) => {
      if ((p.id || p.type_id) === id) {
        let option = p;
        let q = option.quantity || 1;
        q--;
        if (option.quantity < 1) return;
        option.quantity = q;
        return option;
      }
      return p;
    });
    props.updateCartItem(item, props.index);
  };

  const removeOption = (id) => {
    let item = props.item;

    if (!item.options) return;

    item.options = item.options.filter(
      (option) => (option.type_id || option.id) !== id
    );
    props.updateCartItem(item, props.index);
  };

  const updateCartItemAmount = (item) => {
    if (item.options?.length > 0) {
      let amount = availableCurrency
        ? item.prices
          ? item.prices[currency]
          : item.price
        : item.price || 0;
      item.options.map(
        (option) =>
          (amount +=
            (availableCurrency
              ? option.prices
                ? option.prices[currency]
                : option.price
              : option.price) * (option.quantity || 1) || 0)
      );
    }
  };

  useEffect(() => {
    updateCartItemAmount(props.item);
  }, []);

  return (
    <Card
      style={{ margin: 8, borderRadius: 8 }}
      className={props.classes.paper}
    >
      <CardHeader
        style={{ padding: 0 }}
        action={
          <IconButton
            disabled={props.item.type === "delivery_fee_item" && true}
            onClick={() => props.removeCartItem(props.index)}
          >
            <Ionicon icon="ios-close-circle-outline" />
          </IconButton>
        }
      />
      <List style={{ padding: 0, margin: 0, marginTop: -15 }}>
        <ListItem
          style={{
            padding: 0,
            paddingLeft: 16,
            paddingRight: 16,
            margin: 0,
            cursor: "pointer",
          }}
        >
          <ListItemText
            style={{ marginRight: 40 }}
            className={props.classes.primary}
            primary={
              <span style={{ paddingRight: 50 }}>
                {typeof props.item.name === "object"
                  ? props.item.name.en
                  : props.item.name}
              </span>
            }
          />
          <ListItemSecondaryAction>
            {props.item.price > 0 ? (
              <ListItemText
                primary={
                  availableCurrency
                    ? props.item.prices
                      ? Number(props.item.prices[currency]).toFixed(
                          props.item.pricePrecision
                        ) !== (0.0).toFixed(props.item.pricePrecision) &&
                        `${Number(props.item.prices[currency]).toFixed(
                          props.item.pricePrecision
                        )}`
                      : Number(props.item.price).toFixed(
                          props.item.pricePrecision
                        ) !== (0.0).toFixed(props.item.pricePrecision) &&
                        `${Number(props.item.price).toFixed(
                          props.item.pricePrecision
                        )}`
                    : Number(props.item.price).toFixed(2) !==
                        (0.0).toFixed(2) &&
                      `${Number(props.item.price).toFixed(
                        props.item.pricePrecision
                      )}`
                }
                className={props.classes.primary}
              />
            ) : null}
          </ListItemSecondaryAction>
        </ListItem>
        {props.item.options
          ? props.item.options.map((option, index) => (
              <ListItem style={{ height: 30 }} key={index}>
                <DeleteIcon
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    color: "#202020",
                    margin: "4px",
                  }}
                  onClick={() => {
                    if (option.type !== "size" && option.price)
                      removeOption(option.type_id || option.id);
                  }}
                />
                <RemoveIcon
                  onClick={() => {
                    if (option.type !== "size" && option.price)
                      decreaseOptionQuantity(option.type_id || option.id);
                  }}
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    color: "#202020",
                    margin: "4px",
                  }}
                />
                <AddIcon
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    color: "#202020",
                    margin: "4px",
                  }}
                  onClick={() => {
                    if (option.type !== "size" && option.price)
                      increaseOptionQuantity(option.type_id || option.id);
                  }}
                />
                <ListItemText
                  secondary={`${option.name.en} ${
                    option.quantity && option.type !== "size"
                      ? "x " + (option.quantity || 1)
                      : " "
                  }`}
                />
                <ListItemText
                  primary={
                    availableCurrency
                      ? option.prices
                        ? Number(option.prices[currency]).toFixed(
                            option.pricePrecision
                          ) !== (0.0).toFixed(option.pricePrecision) &&
                          `${Number(
                            option.prices[currency] * option.quantity
                          ).toFixed(option.pricePrecision)}`
                        : Number(option.price).toFixed(
                            option.pricePrecision
                          ) !== (0.0).toFixed(option.pricePrecision) &&
                          `${Number(option.price * option.quantity).toFixed(
                            option.pricePrecision
                          )}`
                      : Number(option.price).toFixed(option.pricePrecision) !==
                          (0.0).toFixed(option.pricePrecision) &&
                        `${Number(option.price * option.quantity).toFixed(
                          option.pricePrecision
                        )}`
                  }
                  style={{ textAlign: "end" }}
                  className={props.classes.primary}
                />
              </ListItem>
            ))
          : null}
        {props.item.instructions ? (
          <ListItem>
            <ListItemText secondary={props.item.instructions} />
          </ListItem>
        ) : null}
        <hr style={{ marginRight: 16, marginLeft: 16 }} />
        <ListItem style={{ paddingTop: 0 }}>
          <List style={{ padding: 0, margin: 0, marginLeft: -40 }}>
            <ListItem
              style={{ width: "100%", paddingTop: 0, paddingBottom: 0 }}
            >
              <Button
                className={props.classes.hover}
                disabled={props.item.type === "delivery_fee_item" && true}
                onClick={() => decreaseQuantity()}
              >
                <Ionicon
                  icon="ios-remove-circle-outline"
                  className={props.classes.primary}
                />
              </Button>
              <ListItemText
                primary={quantity}
                className={props.classes.primary}
              />
              <Button
                className={props.classes.hover}
                disabled={props.item.type === "delivery_fee_item" && true}
                onClick={() => increaseQuantity()}
              >
                <Ionicon
                  icon="ios-add-circle-outline"
                  className={props.classes.primary}
                />
              </Button>
            </ListItem>
          </List>
          <ListItemText
            primary={
              props.isLoading ? (
                <Loader type="ThreeDots" color="#000" height="10" width="50" />
              ) : (
                props.item.total_item_price
              )
            }
            style={{ textAlign: "end" }}
            className={props.classes.primary}
          />
        </ListItem>
      </List>
    </Card>
  );
};

const CartTotals = ({ classes, pricePrecision, myTotals, isLoading }) => {
  return (
    <Paper className={classes.paper} style={{ margin: 8 }}>
      <List>
        <ListItem style={{ paddingRight: 16, paddingBottom: 0 }}>
          <ListItemText primary="Sub-Total" />
          <ListItemText
            primary={
              isLoading ? (
                <Loader type="ThreeDots" color="#000" height="10" width="100" />
              ) : (
                Number(myTotals.sub_total_price).toFixed(pricePrecision)
              )
            }
            style={{ textAlign: "end" }}
            className={classes.primary}
          />
        </ListItem>
        <ListItem style={{ paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>
          <ListItemText primary="Vat" />
          <ListItemText
            primary={
              isLoading ? (
                <Loader type="ThreeDots" color="#000" height="10" width="100" />
              ) : (
                Number(myTotals.order_tax_value).toFixed(pricePrecision)
              )
            }
            style={{ textAlign: "end" }}
            className={classes.primary}
          />
        </ListItem>
        <hr style={{ marginRight: 16, marginLeft: 16 }} />
        <ListItem style={{ paddingRight: 16, paddingTop: 0 }}>
          <ListItemText primary="Total" className={classes.primary} />
          <ListItemText
            primary={
              isLoading ? (
                <Loader type="ThreeDots" color="#000" height="10" width="100" />
              ) : (
                Number(myTotals.order_total_price).toFixed(pricePrecision)
              )
            }
            style={{ textAlign: "end" }}
            className={classes.primary}
          />
        </ListItem>
      </List>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  flex: {
    flex: 1,
  },
  body: {
    flex: 1,
    alignSelf: "center",
  },
  toolbar: theme.mixins.toolbar,
  paper: {
    boxShadow: "none",
  },
  logo: {
    alignSelf: "center",
  },
  primary: {
    fontWeight: "bold",
  },
  hover: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
}));

export default Cart;
