/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import CurrentOrders from "./orders/current";
import CollectionLocations from "./locations/collection";
import DeliveryLocations from "./locations/delivery";
import { BASE_URL } from "../../app.config";
import LastOrder from "./orders/last";
import ActiveTicket from "./active.ticket";

import { get } from "../../utils/fetch";
import firebase from "../../firebase";
import { sort_number } from "../../utils/other/sorting";
import PendingOrderAlert from "./orders/pendingAlert";

const Landing = (props) => {
  const classes = useStyles();

  const [show, setShow] = useState("delivery");
  const [isLocation, setIsLocation] = useState(false);
  const [isActiveOrder, setIsActiveOrder] = useState(true);
  const [isActiveTicket, setIsActiveTicket] = useState(false);
  const [isLocations, setIsLocations] = useState(true);

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [kerbsideLocations, setKerbsideLocations] = useState([]);
  const [instoreLocations, setInstoreLocations] = useState([]);

  const [currentOrders, setCurrentOrders] = useState([]);
  const [currentOrdersLoading, setCurrentOrdersLoading] = useState(false);
  const [pendingPayment, setPendingPayemnt] = useState(false);
  const [pendingOrder, setPendingOrder] = useState(false);

  const [activeTickects, setActiveTickects] = useState([]);
  const [activeTicketsLoading, setActiveTicketsLoading] = useState(false);

  const [presets, setPresets] = useState([]);

  const getActiveTickets = async (caller) => {
    setActiveTickects([]);
    if (caller) {
      firebase
        .database()
        .ref("tickets")
        .orderByChild("customer/phone")
        .equalTo(caller)
        .on("value", (snapshot) => {
          const _tickets = [];
          snapshot.forEach((snap) => {
            const val = snap.val();
            if (val) {
              _tickets.push(val);
            }
          });

          if (_tickets.length > 0) {
            setIsActiveTicket(true);
            setActiveTickects([..._tickets]);
          }
          setActiveTicketsLoading(false);
        });
    }
  };

  const getCurrentOrders = async (caller) => {
    setCurrentOrders([]);
    if (caller) {
      const cos = await get(`${BASE_URL}/active_orders/${caller}/ao`);
      if (Array.isArray(cos)) {
        for (const order of cos) {
          if (order.order_source !== "Call Center") {
            const oos = order.order_status;

            if (oos !== "Anulled" && oos !== "Cancelled") {
              setPendingPayemnt(true);
              setPendingOrder(order);
              break;
            }
          }
        }

        setCurrentOrders(cos);

        const url = `${BASE_URL}/v1/general/get_message_presets/${props.countryCode}`;

        const response = await get(url);
        setPresets(response);
      } else {
        setIsActiveOrder(false);
      }
    }
    setCurrentOrdersLoading(false);
  };

  const getCustomerStats = async (caller) => {
    try {
      setIsLocation(false);
      setDeliveryLocations([]);
      setInstoreLocations([]);
      setKerbsideLocations([]);

      getActiveTickets(caller);
      getCurrentOrders(caller);

      const s_locations = await get(
        `${BASE_URL}/locations/get_locations/${caller}`
      );

      if (s_locations.length > 0) {
        let _delivery_locations = [],
          _k_locations = [],
          _i_locations = [];
        s_locations.map((location) => {
          if (location.type === "delivery") {
            _delivery_locations.push(location);
          } else {
            location.collection_type = location.type;
            if (location.type === "kerbside") {
              _k_locations.push(location);
            } else {
              _i_locations.push(location);
            }
          }
        });

        _delivery_locations = _delivery_locations.sort((a, b) => {
          return sort_number(a.total_orders, b.total_orders);
        });

        _k_locations = _k_locations.sort((a, b) => {
          return sort_number(a.total_orders, b.total_orders);
        });

        _i_locations = _i_locations.sort((a, b) => {
          return sort_number(a.total_orders, b.total_orders);
        });

        setDeliveryLocations(_delivery_locations);
        setKerbsideLocations(_k_locations);
        setInstoreLocations(_i_locations);
        setIsLocation(true);
      } else {
        setIsLocations(false);
      }

      setLocationsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    setCurrentOrdersLoading(true);
    setActiveTicketsLoading(true);
    setLocationsLoading(true);

    const delayDebounceFn = setTimeout(() => {
      getCustomerStats(props.phone);
    }, 100);

    return () => clearTimeout(delayDebounceFn);
  }, [props.phone]);

  return (
    <div style={{ marginLeft: 2 }}>
      <Grid item sm={12} style={{ marginTop: 15 }}>
        {isLocation && (
          <Typography
            variant="h5"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "2em",
              marginBottom: 20,
            }}
          >
            {props.callingNumber}
          </Typography>
        )}

        <div style={{ marginBottom: 20 }}>
          {isActiveTicket && (
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Active Tickets
            </Typography>
          )}

          {activeTicketsLoading && (
            <div style={{ textAlign: "center" }}>
              <Loader type="Circles" color="#D71920" height="50" width="50" />
            </div>
          )}

          {activeTickects.map((ticket, i) => (
            <ActiveTicket key={i} ticket={ticket} />
          ))}
        </div>

        <div style={{ marginBottom: 20 }}>
          {isActiveOrder && (
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Active Orders
            </Typography>
          )}

          {currentOrdersLoading && (
            <div style={{ textAlign: "center" }}>
              <Loader type="Circles" color="#D71920" height="50" width="50" />
            </div>
          )}

          {currentOrders.map((currentOrder, i) => (
            <CurrentOrders
              key={i}
              callerID={props.callingNumber}
              order={currentOrder}
              country={props.country}
              presets={presets}
              currentOrdersLoading={props.currentOrdersLoading}
              addOrderDetails={(_order) => props.setOrderDetails(_order)}
              openOrderDialog={() => props.setOrderDialogOpen(true)}
              viewLogs={() => props.viewLogs(true)}
            />
          ))}
        </div>

        <div style={{ marginBottom: 20 }}>
          {props.user?.last_order && (
            <>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Last Order
              </Typography>
              <LastOrder
                callerID={props.callingNumber}
                order={props.user.last_order}
                addOrderDetails={(_order) => props.setOrderDetails(_order)}
                openOrderDialog={() => props.setOrderDialogOpen(true)}
                viewLogs={() => props.viewLogs(true)}
              />
            </>
          )}
        </div>
        {isLocations && (
          <div>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Locations
            </Typography>
            <Paper className={classes.paper}>
              <div style={{ display: "inline-flex" }}>
                {deliveryLocations.length > 0 && (
                  <div
                    onClick={() => setShow("delivery")}
                    style={{
                      marginRight: 20,
                      fontSize: "1.1em",
                      fontWeight: "bold",
                      borderBottom:
                        show === "delivery" && "solid #D71920 thick",
                      paddingBottom: 10,
                    }}
                  >
                    Delivery
                  </div>
                )}

                {kerbsideLocations.length > 0 && (
                  <div
                    onClick={() => setShow("kerbside")}
                    style={{
                      marginRight: 20,
                      fontSize: "1.1em",
                      fontWeight: "bold",
                      borderBottom:
                        show === "kerbside" && "solid #D71920 thick",
                      paddingBottom: 10,
                    }}
                  >
                    Kerbside
                  </div>
                )}

                {instoreLocations.length > 0 && (
                  <div
                    onClick={() => setShow("instore")}
                    style={{
                      fontSize: "1.1em",
                      fontWeight: "bold",
                      borderBottom: show === "instore" && "solid #D71920 thick",
                      paddingBottom: 10,
                    }}
                  >
                    In Store
                  </div>
                )}
              </div>
            </Paper>
          </div>
        )}

        {locationsLoading && (
          <div style={{ textAlign: "center" }}>
            <Loader type="Circles" color="#D71920" height="50" width="50" />
          </div>
        )}

        {show === "delivery" ? (
          <DeliveryLocations
            callerID={props.callingNumber}
            deliveryLocations={deliveryLocations}
            onAddLocation={props.onAddLocation}
            onEditLocation={props.onEditLocation}
            menuLoading={props.menuLoading}
            zoneLoading={props.zoneLoading}
            openOrderDialog={() => props.setOrderDialogOpen(true)}
            verifyCustomerLocation={props.verifyCustomerLocation}
            openCustomerDialog={() => props.setCustomerDialogOpen(true)}
          />
        ) : (
          <CollectionLocations
            callerID={props.callingNumber}
            menuLoading={props.menuLoading}
            zoneLoading={props.zoneLoading}
            collectionLocations={
              show === "instore" ? instoreLocations : kerbsideLocations
            }
            onAddLocation={props.onAddLocation}
            onEditLocation={props.onEditLocation}
            openOrderDialog={() => props.setOrderDialogOpen(true)}
            openCustomerDialog={() => props.setCustomerDialogOpen(true)}
          />
        )}
      </Grid>

      {!locationsLoading && (
        <Button
          onClick={() => props.addCustomer(isLocation)}
          variant="contained"
          className="add-button"
          style={{
            backgroundColor: "#D71920",
            color: "#fff",
          }}
          aria-label="edit"
        >
          <Add style={{ fontSize: 55 }} />
        </Button>
      )}
      {pendingOrder && (
        <PendingOrderAlert
          open={pendingPayment}
          close={() => setPendingPayemnt(false)}
          order={pendingOrder}
          onViewOrder={() => {
            props.setOrderDetails(pendingOrder);
            props.setOrderDialogOpen(true);
            setPendingPayemnt(false);
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  paper: {
    margin: 8,
    marginLeft: 0,
    cursor: "pointer",
    boxShadow: "none",
    borderRadius: 0,
    paddingTop: 16,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: "#EEEEEE",
    border: "solid #CED4DA thin",
  },
}));

export default Landing;
