import React from "react";
import { TextField } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

const InputField = (props) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      margin="normal"
      size="small"
      required={props.required}
      fullWidth
      id={props.placeholder}
      label={props.placeholder}
      name={props.placeholder}
      InputProps={{
        classes: {
          notchedOutline: props.error ? classes.notchedOutline : null,
        },
      }}
      style={{ borderRadius: 10 }}
      value={props.value}
      onChange={(event) => props.setValue(event.target.value)}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#D71920",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderColor: "red !important",
  },
}));

export default InputField;
