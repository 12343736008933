import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core/";
import "../App.css";

const SelectField = (props) => {
  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        onChange={(e) => props.setValue(e.target.value)}
        label={props.label}
      >
        {props.menuitem.map((value) => (
          <MenuItem className="action-option" value={value} key={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
