/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Card,
  FormControl,
  CardContent,
  Select,
  Paper,
  IconButton,
  MenuItem,
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Ionicon from "react-ionicons";
import Loader from "react-loader-spinner";
import StarRatingComponent from "react-star-rating-component";
import { MoreVert, Redeem } from "@material-ui/icons";
import DehazeIcon from "@material-ui/icons/Dehaze";
import moment from "moment-timezone";
import * as EmailValidator from "email-validator";
import logo from "../assets/dadv2.png";

// Components
import DaDMenu from "./dadmenu";
import Cart from "./cart";
import ProductDialog from "./product.dialog";
import CustomerDialog from "./customer.dialog";
import CheckoutDialog from "./checkout.dialog";
import CheckoutCompleteDialog from "./checkout.complete.dialog";
import OrderDialog from "./order.dialog";
import PreviousOrderDialog from "./previous.order.dialog";
import CustomerEditConfirmDialog from "./customer.edit.confirm.dialog";
import SelectComplexDialog from "./select.complex.dialog";

// Configs
import { BASE_URL, green } from "../app.config";
import firebase from "../firebase";
import { phoneSanitizer } from "../utils/other/sanitizers";
import DashboardDrawer from "./drawer";
import { useSelector } from "react-redux";
import ResetPassword from "./reset.password.dialog";
import { script } from "../utils/other/script";
import Landing from "./landing";
import Profile from "./landing/profile";
import VouchersDialog from "./voucher";
import InputField from "../commons/input.field";
import { get, post, put } from "../utils/fetch";
import Feedback from "../commons/feeback";
import OrderLogs from "./landing/orders/log";
import OutOfZone from "../commons/out_of_zone";

const _callerId = window.location.search.split("=")[1]
  ? window.location.search.split("=")[1]
  : "";

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeMillis: 60000,
};

remoteConfig.defaultConfig = {
  countries: false,
  default_zone: false,
};

const Dashboard = () => {
  const source = "Call Center";
  const version = "v2.6.53";
  const classes = useStyles();

  const agent = useSelector((state) => state.auth.user);

  const [countryCode, setCountryCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [menuLoading, setMenuLoading] = useState(false);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [isCustomer, setIsCustomer] = useState();

  const [orderDetails, setOrderDetails] = useState();
  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [callingNumber, setCallingNumber] = useState(_callerId);
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState();
  const [vouchers, setVouchers] = useState([]);
  const [callerIdError, setCallerIdError] = useState("");
  const [defaultLocation, setDefaultLocation] = useState();
  const [defaultZone, setDefaultZone] = useState();
  const [tax, setTax] = useState(0);
  const [country, setCountry] = useState({});
  const [removedTime, setRemovedTime] = useState("");
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [cartUpdate, setCartUpdate] = useState(true);

  useEffect(() => {
    if (agent && !agent.p_r) {
      setOpenResetPassword(true);
    }
  }, [agent]);

  const getCountryDetails = async () => {
    await remoteConfig.fetchAndActivate().catch((err) => {
      console.error(err);
    });

    const _country = JSON.parse(remoteConfig.getValue("countries").asString());
    const _default_zone = JSON.parse(
      remoteConfig.getValue("default_zone").asString()
    );

    if (_default_zone) {
      setDefaultZone(_default_zone);

      const dl = {
        lat: Number(_default_zone.zone.zone_location.lat),
        lon: Number(_default_zone.zone.zone_location.lon),
      };
      setDefaultLocation(dl);
    }

    if (_country) {
      setCountry(_country);
      setCountryCode(_country.country_code);
      script(_country.api_key);
    }
  };

  useEffect(() => {
    getCountryDetails();
  }, []);

  const getCustomer = async (caller) => {
    try {
      if (country.web_regex) {
        caller = caller.includes("+")
          ? phoneSanitizer(caller)
          : phoneSanitizer(country.dial_code + caller);

        setPhone(caller);

        const isPhone = eval(country.web_regex).test(caller);

        if (isPhone) {
          const _user = await get(`${BASE_URL}/get_customer/${caller}?app=ao`);

          if (_user.name) {
            if (_user?.last_order?.message) {
              _user.last_order = false;
            }

            if (_user.prizes) {
              setVouchers(_user.prizes);
            }

            setUser(_user);
            setIsCustomer(true);
          } else {
            setIsCustomer(false);
            setCustomerDialogOpen(true);
          }
        } else {
          setIsCustomer(false);
          setCallerIdError("Invalid Caller Number");
        }
        setLoading(false);
      }
    } catch (error) {
      setCallerIdError(error.toString());
      setLoading(false);
      setIsCustomer(false);
    }
  };

  useEffect(() => {
    setIsCustomer(true);
    setCallerIdError("");
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      getCustomer(callingNumber);
    }, 100);

    return () => clearTimeout(delayDebounceFn);
  }, [country, callingNumber]);

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [confirmCustomerDialogOpen, setConfirmCustomerDialogOpen] =
    useState(false);
  const [verifyLocation, setVerifyLocation] = useState(false);
  const [customerEdit, setCustomerEdit] = useState(false);
  const [customerEditConfirm, setCustomerEditConfirm] = useState(false);
  const [customerEditCart, setCustomerEditCart] = useState(false);
  const [checkoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [previousOrderDialogOpen, setPreviousOrderDialogOpen] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [page, setPage] = useState("");
  const [menu, setMenu] = useState();
  const [changeMenu, setChangeMenu] = useState(false);
  const [zoneId, setZoneId] = useState("");
  const [zone, setZone] = useState(null);
  const [out_of_zone, setOutOfZone] = useState(false);
  const [specials, setSpecials] = useState();
  const [brands, setBrands] = useState();
  const [cart, setCart] = useState([]);
  const [customer, setCustomer] = useState();
  const [previousOrders, setPreviousOrders] = useState([]);
  const [previousOrder, setPreviousOrder] = useState();
  const [currency, setCurrency] = useState("");
  const [availableCurrency, setAvailableCurrency] = useState([]);
  const [orderUUID, setOrderUUID] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [myTotals, setMyTotals] = useState({
    order_total_price: 0,
    sub_total_price: 0,
    order_tax_value: 0,
  });

  const [orderResponse, setOrderResponse] = useState({});
  const [payments, setPayments] = useState("");
  const [proceed, setProceeding] = useState(false);
  const [proceedMessage, setProceedMessage] = useState();
  const [editCartProduct, setEditCartProduct] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [instruction, setOrderInstructions] = useState("");
  const [previousOrderLoading, setPreviousOrderLoading] = useState(false);
  const [openSelectComplexDialog, setOpenSelectComplexDialog] = useState(false);
  const [complexes, setComplexes] = useState([]);
  const [complex, setComplex] = useState();
  const [complexChanged, setComplexChanged] = useState(false);
  const [changeComplex, setChangeComplex] = useState(false);
  const [defaultBrandIndex, setDefaultBrandIndex] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openVoucherDialog, setOpenVoucherDialog] = useState(false);
  const [enableVouchers, setEnableVouchers] = useState(false);
  const [showVoucherInput, setShowVoucherInput] = useState(false);
  const [voucher_error, setVoucherError] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [viewLogs, setViewLogs] = useState(false);
  const [feedback, setFeedback] = useState("");

  const getPreviousOrders = () => {
    setPreviousOrderLoading(true);
    if (BASE_URL) {
      get(`${BASE_URL}/previous_orders/${phone}`)
        .then((response) => {
          if (!response.message) {
            setPreviousOrders([...response]);
            setPreviousOrderLoading(false);
          } else {
            setPreviousOrderLoading(false);
          }
        })
        .catch((error) =>
          setCallerIdError(
            "Network Error, please check your internet connection and try again!"
          )
        );
    }
  };

  const loadPreviousOrder = (order) => {
    order.cart_data.map((i) => {
      menu.items
        .filter((f) => f.uuid === i.uuid)
        .map((item) => {
          let _options = [];
          if (i.options) {
            i.options.map((o) => {
              menu.sizes
                .filter((f) => f.id === o.id)
                .map((opt) => _options.push(opt));
              menu.options
                .filter((f) => f.id === o.id)
                .map((opt) => _options.push(opt));
            });
          }

          item.brand = menu.brand[item.brand];
          item.options = i.options ? _options : [];
          item.quantity = item.quantity ? item.quantity : 1;

          let amount = item.price;
          let currencies = Object.keys(item.prices);
          let amounts = item.prices && { ...item.prices };

          i.options.map((opt) =>
            item.options
              .filter((f) => f.id === opt.id)
              .map((item) => {
                currencies.map(
                  (currency) =>
                    (amounts[currency] +=
                      item.prices[currency] * (item.quantity || 1))
                );
                amount += item.price * (item.quantity || 1);
              })
          );
          item.amount = amount;
          item.amounts = amounts;
          setCart((cart) => [...cart, item]);

          let product = {
            brand: item.brand,
            menu: menu,
            item: item,
            edit: true,
            open: openEditProduct,
            onAddCartIterm: onAddCartIterm,
            close: () => setOpenEditProduct(false),
            classes: classes,
            availableCurrency: availableCurrency,
            currency: currency,
          };
          setEditCartProduct((editCartProduct) => [
            ...editCartProduct,
            product,
          ]);
        });
    });
  };

  const getMenu = (brand, complex_uuid, deliveryMode, _brands, index) => {
    if (brand) {
      const url = `${BASE_URL}/menu/${brand.uuid}/${deliveryMode}/callcenter/${complex_uuid}`;

      get(url)
        .then((response) => {
          if (response) {
            if (response.specials) {
              setSpecials(response.specials);
            } else {
              setSpecials([]);
            }

            if (complexChanged) {
              setChangeMenu(true);
            }

            const mu = {
              brand: response.brand,
              categories: response.categories && response.categories,
              items: response.items && response.items,
              groups: response.groups && response.groups,
              sizes: response.sizes && response.sizes,
              options: response.options && response.options,
            };

            setMenu(mu);

            if (complexChanged) {
              setChangeMenu(false);
            }

            setDefaultBrandIndex(index);
            setBrands(_brands);
            setPage("menu");
            setMenuLoading(false);
          }
        })
        .catch((error) => {});
    }
  };

  const onAddLocation = (customer, order) => {
    setMenuLoading(true);
    setCallerIdError("");
    setComplexes([]);

    if (customer.delivery_location) {
      let url = `${BASE_URL}/zone?lat=${customer.coords.lat}&lon=${customer.coords.lon}&source=ao`;

      if (customer.zone_uuid) {
        url += "&zone_uuid=" + customer.zone_uuid;
      }

      if (customer.country_code) {
        url += "&country_code=" + customer.country_code;
      }

      if (customer.address_id) {
        url += "&address_id=" + customer.address_id;
      }

      if (phone) {
        url += "&phone=" + phone;
      }

      get(url).then((response) => {
        if (!response.out_of_zone) {
          addOrderLocation(response, customer, order);
        } else {
          setOutOfZone(true);
          setMenuLoading(false);
        }
      });
    } else if (customer.zone_uuid) {
      get(`${BASE_URL}/zone/${customer.zone_uuid}?source=ao`).then(
        (response) => {
          addOrderLocation(response, customer, order);
        }
      );
    } else if (customer.collection_location) {
      get(`${BASE_URL}/complexes/${customer.complex_uuid}`).then((response) => {
        if (response) {
          setZoneId(response.complex.id);
          setZone(response.complex);
          setTax(response.complex.tax_rate);
          setCustomer(customer);
          setAvailableCurrency(response.complex.available_currencies);
          setCurrency(response.complex.available_currencies[0]);

          let defaultBrand = response.complex.brands.filter(
            (f) => f.default === true
          )[0];
          defaultBrand = defaultBrand
            ? defaultBrand
            : response.complex.brands[0];

          setComplex(response.complex);

          const order_mode =
            customer.cart.delivery_mode === "instore" ? "in_store" : "kerbside";

          getMenu(
            defaultBrand,
            response.complex.uuid,
            order_mode,
            response.complex.brands,
            response.complex.brands.indexOf(defaultBrand)
          );
        }
      });

      get(`${BASE_URL}/complexes?country=${country.name}&source=ao`).then(
        (response) => {
          if (response) {
            setComplexes(response);
          }
        }
      );
    } else if (customer.selected_complex && customer.selected_complex.uuid) {
      setZoneId(customer.selected_complex.id);
      setZone(customer.selected_complex);
      setTax(customer.selected_complex.tax_rate);
      setCustomer(customer);
      setAvailableCurrency(customer.selected_complex.available_currencies);
      setCurrency(customer.selected_complex.available_currencies[0]);

      let defaultBrand = customer.selected_complex.brands.filter(
        (f) => f.default === true
      )[0];

      defaultBrand = defaultBrand
        ? defaultBrand
        : customer.selected_complex.brands[0];

      setComplex(customer.selected_complex);

      const order_mode =
        customer.cart.delivery_mode === "instore" ? "in_store" : "kerbside";

      getMenu(
        defaultBrand,
        customer.selected_complex.uuid,
        order_mode,
        customer.selected_complex.brands,
        customer.selected_complex.brands.indexOf(defaultBrand)
      );
    }
  };

  const onEditLocation = (customer) => {
    setZoneLoading(true);
    if (customer.complex_uuid) {
      get(`${BASE_URL}/complexes/${customer.complex_uuid}`).then((response) => {
        if (response) {
          setCustomerDialogOpen(true);
          setCustomer(customer);
          setZoneLoading(false);
          setComplex(response.complex);
        }
      });
    } else {
      get(
        `${BASE_URL}/zone?lat=${customer.coords.lat}&lon=${customer.coords.lon}&source=ao`
      ).then((response) => {
        customer.zone = response;
        setCustomer(customer);
        setCustomerDialogOpen(true);
        setZoneLoading(false);
      });
    }
  };

  const addOrderLocation = async (response, customer, order) => {
    if (response.zone) {
      setTax(response.zone.tax_rate);
      setZoneId(response.zone.id);
      setZone(response.zone);
      setCustomer(customer);

      if (response.zone.available_currencies) {
        setAvailableCurrency(response.zone.available_currencies);
        setCurrency(response.zone.available_currencies[0]);
      }

      if (order) {
        setOrderUUID(customer.cart.order_uuid);
        get(`${BASE_URL}/complexes/${customer.complex_uuid}`).then(
          (complex_response) => {
            if (complex_response) {
              setComplex(complex_response.complex);

              if (complex_response.complex.brands) {
                let defaultBrand = complex_response.complex.brands.filter(
                  (f) => f.default === true
                )[0];

                defaultBrand = defaultBrand
                  ? defaultBrand
                  : complex_response.complex.brands[0];

                getMenu(
                  defaultBrand,
                  complex_response.complex.uuid,
                  customer.cart.delivery_mode,
                  complex_response.complex.brands,
                  complex_response.complex.brands.indexOf(defaultBrand)
                );
              }

              importOrder(
                order,
                complex_response.complex.available_currencies,
                complex_response.complex.available_currencies[0]
              );
            }
          }
        );
      } else {
        let _complexes = [];
        Object.entries(response.zone.complex_type).map(([key, value]) => {
          if (value.complexes)
            value.complexes.map((_complex) => {
              _complexes.push({ ..._complex, complex_type: key });
            });
        });

        setComplexes((prevState) => [...prevState, ..._complexes]);
        setOpenSelectComplexDialog(true);
      }
    }
  };

  const importOrder = (order, _currencies, _currency) => {
    let items = [];

    if (order) {
      order.map((item) => {
        item.options = item.options ? item.options : [];
        item.quantity = item.quantity ? item.quantity : 1;
        items.push(item);

        let product = {
          brand: item.brand,
          menu: menu,
          item: item,
          edit: true,
          open: openEditProduct,
          onAddCartIterm: onAddCartIterm,
          close: () => setOpenEditProduct(false),
          classes: classes,
          availableCurrency: _currencies,
          currency: _currency,
        };

        setEditCartProduct((editCartProduct) => [...editCartProduct, product]);
      });
    }
    setCartUpdate(false);
    setProceeding(true);
    setCart(items);
  };

  const onAddCartIterm = (product, item, edit, expCheckout) => {
    setCartUpdate(true);
    setEditCartProduct((editCartProduct) => [...editCartProduct, product]);
    if (edit) {
      let ncart = cart;
      ncart
        .filter((f) => f.cart_item_id === item.cart_item_id)
        .map((i, index) => ncart.splice(ncart.indexOf(index), 1));

      ncart.push(item);
      setCart([...ncart]);
    } else {
      setCart((cart) => [...cart, item]);
    }

    if (expCheckout) {
      checkout(true);
    }
  };

  useEffect(() => {
    if (cart.length > 0 && orderUUID && cartUpdate) {
      setCartLoading(true);
      updateCartItems();
    }
  }, [cart, orderUUID, cartUpdate]);

  useEffect(() => {
    if (complex && !orderUUID && !customerDialogOpen) {
      createCart();
    }

    if (complex && complexChanged) {
      createCart();
    }
  }, [complex, orderUUID, complexChanged, customerDialogOpen]);

  const createCart = (recreate) => {
    setCartLoading(true);

    if (phone) {
      customer.cart.customer.phone = phone;
    } else {
      const _phone = customer.cart.customer.phone.includes("+")
        ? phoneSanitizer(customer.cart.customer.phone)
        : phoneSanitizer(country.dial_code + customer.cart.customer.phone);
      customer.cart.customer.phone = _phone;
      setPhone(_phone);
    }

    if (customer.cart.customer.email) {
      if (!EmailValidator.validate(customer.cart.customer.email)) {
        customer.cart.customer.email = "";
      }
    }

    let cart_zone;
    if (customer.cart.delivery_mode.toLowerCase() === "delivery") {
      cart_zone = {
        zone_uuid: zone.uuid,
        zone_name: zone.name.en,
        complex_type: complex.complex_type,
      };
    }

    if (agent.name) {
      const dt = {
        ...customer.cart,
        order_source: source,
        version: version,
        agent_name: agent.name,
        agent_email: agent.email,
        complex_name: complex.name,
        complex_uuid: complex.uuid,
        contact_number: complex.contact_number,
        currency: currency,
        ...cart_zone,
      };

      setCustomer({ ...customer, cart: dt });

      post(`${BASE_URL}/cart`, dt)
        .then((response) => {
          if (response) {
            setComplexChanged(false);
            setProceeding(true);

            if (response.order_uuid) {
              setOrderUUID(response.order_uuid);
              setOrderNumber(response.order_number);
              setCartLoading(false);
              if (recreate) {
                updateCartItems();
              }
            } else {
              setProceedMessage(response.message);
              setCartLoading(false);
              setMenuLoading(false);
            }
          } else {
            setProceeding(false);
            setCartLoading(false);

            setProceedMessage({
              title: "An error occurred, please try again!",
            });
          }
        })
        .catch((e) => {
          setProceeding(false);
          setCartLoading(false);

          setProceedMessage({
            title: "Network Error, Please try again",
          });
        });
    } else {
      setProceedMessage({
        title: "Agent name is missing, please contact supervisor",
      });
      setCartLoading(false);
      setMenuLoading(false);
    }
  };

  const updateCartItems = () => {
    const update_time = moment().format("YYYY-MM-DD HH:mm:ss");
    let line_items = cart.map((item) => {
      if (item.total_item_price) delete item.total_item_price;

      const total_item_price = item.amounts
        ? item.amounts[currency] * item.quantity
        : item.price * item.quantity;

      return {
        total_item_price,
        ...item,
        update_time,

        options: item.options?.map((option) => {
          return {
            ...option,
            type_id: option.id,
          };
        }),
      };
    });

    let _phone;
    if (phone) {
      _phone = phone;
    } else {
      _phone = customer.cart.customer.phone.includes("+")
        ? phoneSanitizer(customer.cart.customer.phone)
        : phoneSanitizer(country.dial_code + customer.cart.customer.phone);
    }

    const data = {
      phone: _phone,
      currency: currency,
      version: version,
      item_removed_time: removedTime,
      update_message: "Adding new item to cart",
      line_items: line_items,
    };

    put(`${BASE_URL}/cart/${orderUUID}/items`, data)
      .then((response) => {
        setOrderResponse({ ...response });
        setCartUpdate(false);
        setCart([...response.cart]);

        setMyTotals({
          order_total_price: response.order_total_price,
          sub_total_price: response.sub_total_price,
          order_tax_value: response.order_tax_value,
        });

        if (response.payments) {
          setPayments([...response.payments]);
        }

        if (response.checkout) {
          setProceeding(response.checkout);
          setProceedMessage(response.message);
        } else {
          if (
            response.message.title.toLowerCase().includes("order not found")
          ) {
            createCart(true);
          } else {
            setProceeding(response.checkout);
            setProceedMessage(response.message);
          }
        }
        setCartLoading(false);
      })
      .catch((e) => {
        setProceeding(false);
        setCartLoading(false);
        setProceedMessage({
          title: "Network Error, Please try again",
        });
      });
  };

  const orderInstructions = (instruction) => {
    setOrderInstructions(instruction);
  };

  const complete = (result, order) => {
    setOrderNumber(order);
    setSuccessDialogOpen(result);
    setOrderUUID();
    setCart([]);
  };

  const checkout = (open) => {
    setCheckoutDialogOpen(open);
  };

  const addCustomer = (is_location) => {
    if (defaultLocation && defaultZone) {
      if (is_location) {
        if (!menuLoading) {
          setConfirmCustomerDialogOpen(true);
        }
      } else {
        setCustomerDialogOpen(true);
      }
    }
  };

  const addComplex = async (_complex) => {
    if (_complex) {
      setComplex({ ..._complex });
      if (changeComplex) {
        setComplexChanged(true);
      }

      if (!customerDialogOpen) {
        setCart((prevState) => {
          if (prevState.length > 0) {
            prevState = prevState.filter(
              (f) => f.name.en.toLowerCase() === "delivery fee"
            );
          } else {
            prevState = [];
          }

          return prevState;
        });

        setCustomer((prevState) => {
          prevState.complex = _complex.name.en
            ? _complex.name.en
            : _complex.name;
          return prevState;
        });

        let defaultBrand = _complex.brands.filter((f) => f.default === true)[0];
        defaultBrand = defaultBrand ? defaultBrand : _complex.brands[0];

        getMenu(
          defaultBrand,
          _complex.uuid,
          "delivery",
          _complex.brands,
          _complex.brands.indexOf(defaultBrand)
        );
      }

      setOpenSelectComplexDialog(false);
    }
  };

  const addVoucher = async (voucher) => {
    const v_brand = menu.brand.find((f) => f.uuid === voucher.brand_uuid);
    let v_menu;

    if (v_brand) {
      v_menu = menu;
      setOpenVoucherDialog(false);
    } else {
      let order_mode;
      if (customer.cart.delivery_mode === "instore") {
        order_mode = "in_store";
      } else {
        order_mode = customer.cart.delivery_mode;
      }

      const res = await get(
        `${BASE_URL}/menu/${voucher.brand_uuid}/${order_mode}/callcenter/${complex.uuid}`
      );

      if (res.items) {
        v_menu = res;
        setOpenVoucherDialog(false);
      } else {
        setFeedback(res);
        setOpenVoucherDialog(false);
        setShowFeedback(true);
      }
    }

    if (v_menu) {
      const item = v_menu.items.find((f) => f.uuid === voucher.item_uuid);

      if (item) {
        item.quantity = item.quantity ? item.quantity : 1;

        let amount = item.price;
        let currencies = Object.keys(item.prices);
        let amounts = item.prices && { ...item.prices };
        if (item.options) {
          item.options.map((item) => {
            currencies.map(
              (currency) =>
                (amounts[currency] +=
                  item.prices[currency] * (item.quantity || 1))
            );
            amount += item.price * (item.quantity || 1);
          });
        }

        item.amount = amount;
        item.amounts = amounts;
        item.prize_id = voucher.prize_id;
        item.voucher_id = voucher.voucher_id;
        setCart((cart) => [...cart, item]);
        setCartUpdate(true);

        const u_v = vouchers.filter((f) => f.prize_id !== voucher.prize_id);

        setVouchers([...u_v]);

        let product = {
          brand: item.brand,
          menu: v_menu,
          item: item,
          edit: false,
          open: openEditProduct,
          onAddCartIterm: onAddCartIterm,
          close: () => setOpenEditProduct(false),
          classes: classes,
          availableCurrency: availableCurrency,
          currency: currency,
        };

        setEditCartProduct((editCartProduct) => [...editCartProduct, product]);
      } else {
        setShowFeedback(true);
        setFeedback("Voucher item not found");
      }
    }
  };

  const submitVoucher = async () => {
    if (voucher) {
      const body = {
        phone,
        voucher_id: voucher,
      };

      const res = await post(`${BASE_URL}/submit_voucher`, body);
      if (res !== "Voucher submitted successfully") {
        setVoucherError(res);
      } else {
        const res = await get(`${BASE_URL}/customer_prices/${phone}`);

        if (Array.isArray(res)) {
          setVouchers([...res]);
        }

        setShowVoucherInput(false);
      }
    }
  };

  const restoreVoucher = async (item) => {
    const body = {
      phone,
      prize_id: item.prize_id,
      voucher_id: item.voucher_id,
    };

    const res = await post(`${BASE_URL}/restore_prize`, body);
    if (res) {
      setVouchers([...res]);
    }
  };

  const viewVouchers = async () => {
    vouchers.map(({ brand_uuid, brand_name }, i) => {
      vouchers[i].enabled = false;

      const isa = complex.brands.find(({ uuid }) => uuid === brand_uuid);

      if (isa) {
        vouchers[i].enabled = true;
      } else if (brand_name.toLowerCase() === "dial a delivery") {
        vouchers[i].enabled = true;
      }
    });

    setVouchers([...vouchers]);

    setOpenVoucherDialog(true);
    setEnableVouchers(true);
  };

  const verifyCustomerLocation = (location) => {
    if (location) {
      setCustomer(location);
      setCustomerDialogOpen(true);
      setVerifyLocation(true);
    }
  };

  const customerCurrencyMenuCartView = () => {
    return (
      <main style={{ display: "inline-flex", width: "100%" }}>
        <Grid
          item
          sm={2}
          style={{
            flexBasis: "19%",
            maxWidth: "19%",
          }}
        >
          <Grid container>
            <Grid item sm={12}>
              <Card className={classes.card}>
                <div className={classes.cardHeader}>COMPLEX</div>
                <CardContent
                  className={classes.cardContent}
                  style={{ paddingBottom: "0 !important" }}
                >
                  <Paper
                    elevation={0}
                    className={classes.paper}
                    style={{
                      paddingBottom: 0,
                      margin: 0,
                      paddingLeft: 5,
                      paddingRight: 0,
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item sm={9}>
                        {complex.name.en ? complex.name.en : complex.name}
                      </Grid>
                      <Grid item sm={3}>
                        <div align="right">
                          <Ionicon
                            style={{
                              fill: "black",
                              width: 30,
                              height: 30,
                            }}
                            icon="md-arrow-dropdown"
                            onClick={() => {
                              setCustomerEditConfirm(true);
                              setChangeComplex(true);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <div>
            <Card className={classes.card}>
              <div className={classes.cardHeader}>
                <Grid container spacing={3}>
                  <Grid item sm={8}>
                    CUSTOMER
                  </Grid>
                  <Grid item sm={4}>
                    <IconButton
                      onClick={() => {
                        setCustomerEditConfirm(true);
                      }}
                      style={{ padding: 0, color: "#fff" }}
                    >
                      <MoreVert />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
              <CardContent className={classes.cardContent}>
                <Paper
                  elevation={0}
                  className={classes.paper}
                  style={{
                    padding: 0,
                    paddingLeft: 10,
                    margin: 0,
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item sm={12} style={{ marginBottom: 8 }}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "bold", marginRight: 16 }}
                      >
                        <strong>Customer Rating:</strong>
                      </Typography>
                      <StarRatingComponent
                        name="rate2"
                        editing={false}
                        starCount={5}
                        value={customer.rating ? customer.rating : 5}
                      />
                    </Grid>
                    <Grid item sm={12} style={{ marginBottom: 8 }}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "bold" }}
                      >
                        <strong>Customer Name:</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 400 }}
                      >
                        {customer.name}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 400 }}
                      >
                        <strong>Tel:</strong> {customer.cart.customer.phone}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 400 }}
                      >
                        <strong>Alt Tel:</strong>{" "}
                        {customer.cart.customer.alt_phone}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 400 }}
                      >
                        <strong>Email:</strong> {customer.cart.customer.email}
                      </Typography>
                    </Grid>
                    {customer.cart?.delivery_mode?.toLowerCase() ===
                      "delivery" && (
                      <Grid item sm={12} style={{ marginBottom: 8 }}>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          <strong>Address</strong>
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: 400 }}
                        >
                          {customer.address}
                          {customer.cart?.customer?.address_instructions && (
                            <p style={{ margin: 0 }}>
                              <strong>Instrctions:</strong>
                              {customer.cart?.customer.address_instructions}
                            </p>
                          )}
                          {customer.cart?.directions && (
                            <p style={{ margin: 0 }}>
                              <strong>Directions:</strong>
                              {customer.cart.directions}
                            </p>
                          )}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item sm={12} style={{ marginBottom: 8 }}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "bold" }}
                      >
                        <strong>ORDER TYPE</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: 400,
                          textTransform: "capitalize",
                        }}
                        noWrap
                      >
                        {customer.cart?.delivery_mode}
                      </Typography>
                    </Grid>
                    {customer.zone && (
                      <Grid item sm={12} style={{ marginBottom: 8 }}>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          <strong>ZONE</strong>
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: 400 }}
                        >
                          {customer.cart?.zone_name}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item sm={12} style={{ marginBottom: 8 }}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "bold" }}
                      >
                        <strong>COMPLEX</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 400 }}
                        noWrap
                      >
                        {complex?.name?.en || complex.name}
                      </Typography>
                    </Grid>

                    <Grid item sm={12}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "bold" }}
                      >
                        <strong>PREVIOUS ORDERS</strong>
                      </Typography>
                      {previousOrders.length > 0 ? (
                        <List
                          style={{
                            marginRight: 8,
                          }}
                        >
                          {previousOrders.map((order, i) => (
                            <ListItem
                              button
                              style={{
                                padding: 0,
                                borderBottom: "thin solid #808080",
                              }}
                              key={i}
                              onClick={() => {
                                setPreviousOrder(order);
                                setPreviousOrderDialogOpen(true);
                              }}
                            >
                              <ListItemText primary={order.placed} />
                              <ListItemSecondaryAction
                                style={{
                                  marginRight: -16,
                                }}
                              >
                                <Ionicon icon="ios-arrow-dropright" />
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      ) : previousOrderLoading ? (
                        <Loader
                          type="Oval"
                          color="grey"
                          width="30"
                          height="30"
                        />
                      ) : (
                        <div>
                          <Ionicon
                            style={{
                              fill: "grey",
                              width: 40,
                              height: 40,
                            }}
                            icon="ios-refresh"
                            onClick={() => getPreviousOrders()}
                          />
                        </div>
                      )}
                    </Grid>

                    {showVoucherInput ? (
                      <Grid
                        item
                        sm={12}
                        style={{
                          marginRight: 10,
                        }}
                      >
                        {voucher_error && (
                          <div style={{ fontSize: 13 }}>{voucher_error}</div>
                        )}
                        <InputField
                          value={voucher}
                          placeholder="Enter Code"
                          error={voucher_error}
                          setValue={setVoucher}
                        />
                        <div
                          style={{
                            color: "#ffff",
                            textAlign: "center",
                            backgroundColor: green,
                            paddingTop: 4,
                            paddingBottom: 4,
                            cursor: "pointer",
                          }}
                        >
                          <div
                            onClick={submitVoucher}
                            style={{ fontWeight: "bold" }}
                          >
                            Submit
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        sm={12}
                        onClick={() => setShowVoucherInput(!showVoucherInput)}
                        style={{
                          display: "inline-flex",
                          color: "#ffff",
                          textAlign: "center",
                          backgroundColor: green,
                          paddingTop: 4,
                          paddingBottom: 4,
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                      >
                        <Grid container>
                          <Grid item sm={2}>
                            <Redeem />
                          </Grid>
                          <Grid item sm={8}>
                            <div style={{ fontWeight: "bold" }}>
                              Enter Voucher
                            </div>
                          </Grid>
                          <Grid item sm={2}>
                            <Ionicon
                              style={{
                                fill: "#ffff",
                                width: 20,
                                height: 20,
                              }}
                              icon="ios-arrow-forward"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </CardContent>
            </Card>
          </div>
          <div
            className="back-button"
            align="left"
            style={{ marginTop: 10, marginLeft: 5 }}
          >
            <Ionicon
              style={{
                backgroundColor: "#d9534f",
                fill: "#ffff",
                width: 30,
                height: 30,
              }}
              icon="ios-arrow-back"
              onClick={() => {
                setPage("statistics");
                setCart([]);
                setOrderUUID();
                setCustomer([]);
                setMenu([]);
                setSpecials({});
                setCustomerEditCart(false);
                getCustomer(callingNumber);
                setComplexes([]);
                setComplex();

                if (cart && cart.find((f) => f.type === "voucher")) {
                  const _item = cart.find((f) => f.type === "voucher");

                  restoreVoucher(_item);
                }
              }}
            />
          </div>
        </Grid>

        <Grid item sm={7}>
          <Card
            className={classes.card}
            style={{ marginLeft: 2, marginRight: 2 }}
          >
            <CardContent className={classes.cardContent}>
              {customerEdit ? (
                <DaDMenu
                  menu={menu}
                  setMenu={setMenu}
                  change={false}
                  specials={specials}
                  zone_id={zoneId}
                  brands={brands}
                  value={defaultBrandIndex}
                  zone={zone}
                  complex={complex}
                  deliveryMode={customer.cart.delivery_mode}
                  onAddCartIterm={onAddCartIterm}
                  currency={currency}
                  availableCurrency={availableCurrency}
                />
              ) : (
                <DaDMenu
                  menu={menu}
                  setMenu={setMenu}
                  specials={specials}
                  change={changeMenu}
                  zone_id={zoneId}
                  zone={zone}
                  complex={complex}
                  value={defaultBrandIndex}
                  brands={brands}
                  deliveryMode={customer.cart.delivery_mode}
                  onAddCartIterm={onAddCartIterm}
                  currency={currency}
                  availableCurrency={availableCurrency}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={3}>
          <Card className={classes.card}>
            <div className={classes.cardHeader}>CURRENCY</div>
            <CardContent
              className={classes.cardContent}
              style={{ paddingBottom: "0 !important" }}
            >
              <Paper
                elevation={0}
                className={classes.paper}
                style={{
                  paddingBottom: 0,
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    sm={3}
                    style={{
                      paddingTop: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {customer && currency ? currency : ""}
                  </Grid>
                  {availableCurrency.length > 1 && !cartLoading && (
                    <Grid
                      item
                      sm={9}
                      style={{
                        textAlign: "end",
                        paddingTop: 0,
                        fontWeight: "bold",
                      }}
                    >
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value="{age}"
                          disableUnderline
                          onChange={(_) => {
                            let ac = availableCurrency.filter(
                              (f) => f !== currency
                            )[0];

                            setCurrency(ac);
                            setCartUpdate(true);
                          }}
                        >
                          {availableCurrency &&
                            availableCurrency.map((c) => {
                              if (c !== currency)
                                return (
                                  <MenuItem
                                    key={c}
                                    value={c}
                                    className={classes.hover}
                                  >
                                    {c}
                                  </MenuItem>
                                );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <div className={classes.cardHeader}>CART</div>
            <CardContent className={classes.cardContent}>
              <Cart
                cart={cart}
                currency={currency}
                availableCurrency={availableCurrency}
                updateCart={(cart, removed) => {
                  if (removed) {
                    setRemovedTime(moment().format("YYYY-MM-DD HH:mm:ss"));
                  }
                  setCartUpdate(true);
                  setCart([...cart]);
                }}
                tax={tax}
                editCart={(item) => {
                  setEditItem({ ...item });
                  setOpenEditProduct(true);
                }}
                myTotals={myTotals}
                setMyTotals={setMyTotals}
                checkout={checkout}
                proceed={proceed}
                orderUUID={orderUUID}
                version={version}
                proceedMessage={proceedMessage}
                zone={customer.zone}
                orderInstructions={orderInstructions}
                cartLoading={cartLoading}
                phone={phone}
                user={user}
                restoreVoucher={restoreVoucher}
                vouchers={vouchers.length}
                viewVouchers={viewVouchers}
              />
            </CardContent>
          </Card>
        </Grid>
      </main>
    );
  };

  const view = () => {
    let display;
    let t = {};
    let prevOrders =
      previousOrders &&
      previousOrders.filter((e) => !(t[e.date] = e.date in t));
    switch (page) {
      case "menu":
        if (menu && brands && customer && specials && !customerDialogOpen) {
          if (customerEdit) {
            display = customerCurrencyMenuCartView(prevOrders);
          } else {
            display = customerCurrencyMenuCartView(prevOrders);
          }
        }

        break;
      default:
        display = (
          <Grid container>
            {!user && (
              <div style={{ textAlign: "center", width: "100%" }}>
                <TextField
                  fullWidth
                  value={callingNumber}
                  onChange={(e) => {
                    setCallingNumber(e.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.phone_search_root,
                      input: classes.phone_search_input,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.bootstrapFormLabel,
                  }}
                  style={{
                    marginBottom: 40,
                    marginTop: "10%",
                    marginLeft: "40%",
                  }}
                />

                {callerIdError && callingNumber && (
                  <h5
                    align="center"
                    style={{ margin: 0, fontSize: "1.2em", color: "red" }}
                  >
                    {callerIdError}
                  </h5>
                )}

                {loading && (
                  <div style={{ textAlign: "center", marginTop: 10 }}>
                    <Loader
                      type="Circles"
                      color="#D71920"
                      height="100"
                      width="100"
                    />
                  </div>
                )}
              </div>
            )}

            {!loading && user && (
              <>
                <Grid item sm={3}>
                  <Profile
                    _user={user}
                    version={version}
                    viewVouchers={() => setOpenVoucherDialog(true)}
                  />
                </Grid>
                <Grid item sm={9}>
                  <div className="drawer" onClick={() => setOpenDrawer(true)}>
                    <div style={{ marginRight: "-40%" }}>
                      <img src={logo} width="50px" alt="logo" />
                    </div>
                    <div style={{ marginTop: -15, marginRight: "-40%" }}>
                      <DehazeIcon style={{ fontSize: 30 }} />
                    </div>
                  </div>
                  <main className={classes.container}>
                    <TextField
                      fullWidth
                      value={callingNumber}
                      onChange={(e) => {
                        setCallingNumber(e.target.value);
                        setUser();
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.phone_search_root,
                          input: classes.phone_search_input,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.bootstrapFormLabel,
                      }}
                      style={{ marginBottom: 40, marginTop: "10%" }}
                    />

                    {isCustomer && (
                      <Landing
                        callingNumber={callingNumber}
                        phone={phone}
                        country={country}
                        user={user}
                        countryCode={countryCode}
                        setOrderDetails={setOrderDetails}
                        setOrderDialogOpen={setOrderDialogOpen}
                        onAddLocation={onAddLocation}
                        onEditLocation={onEditLocation}
                        menuLoading={menuLoading}
                        zoneLoading={zoneLoading}
                        openOrderDialog={setOrderDialogOpen}
                        verifyCustomerLocation={verifyCustomerLocation}
                        openCustomerDialog={setCustomerDialogOpen}
                        addCustomer={addCustomer}
                        viewLogs={setViewLogs}
                      />
                    )}
                    {openDrawer && (
                      <DashboardDrawer
                        open={openDrawer}
                        close={() => setOpenDrawer(false)}
                        resetPassword={() => setOpenResetPassword(true)}
                      />
                    )}
                  </main>
                </Grid>
              </>
            )}
          </Grid>
        );
        break;
    }

    return display;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        {view()}
        {menuLoading && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              right: "47%",
            }}
          >
            <Loader type="Circles" color="#D71920" height="100" width="100" />
          </div>
        )}
      </Grid>

      {previousOrder && (
        <PreviousOrderDialog
          open={previousOrderDialogOpen}
          onClose={() => setPreviousOrderDialogOpen(false)}
          order={previousOrder}
          callingNumber={callingNumber}
          tax={tax}
          loadPreviousOrder={() => loadPreviousOrder(previousOrder)}
        />
      )}

      {openEditProduct &&
        editCartProduct
          .filter((f) => f.item.id === editItem.id)
          .map((item, index) => (
            <main key={editItem.id + index}>
              <ProductDialog
                brand={item.brand}
                menu={item.menu}
                item={item.item}
                edit={true}
                open={openEditProduct}
                onAddCartIterm={item.onAddCartIterm}
                close={() => setOpenEditProduct(false)}
                classes={item.classes}
                availableCurrency={item.availableCurrency}
                currency={item.currency}
              />
            </main>
          ))}

      {orderDetails && (
        <OrderDialog
          open={orderDialogOpen}
          onClose={() => setOrderDialogOpen(false)}
          countryName={country.name}
          order={orderDetails}
          callingNumber={callingNumber}
          tax={tax}
        />
      )}

      {viewLogs && (
        <OrderLogs
          open={viewLogs}
          close={() => setViewLogs(false)}
          order={orderDetails}
        />
      )}

      {openSelectComplexDialog && (
        <SelectComplexDialog
          open={openSelectComplexDialog}
          complexes={complexes}
          classes={classes}
          complex={complex}
          close={() => {
            setMenuLoading(false);
            setOpenSelectComplexDialog(false);
            setCustomer({});
          }}
          submit={(c) => addComplex(c)}
        />
      )}

      {((customerDialogOpen && isCustomer) ||
        (isCustomer === false && defaultLocation && defaultZone) ||
        verifyLocation) && (
        <CustomerDialog
          open={customerDialogOpen}
          onClose={() => {
            setCustomerDialogOpen(false);
            setCustomerEdit(false);
            setPage("statistics");
            setCart([]);
            setCustomer([]);
            setMenu([]);
            setSpecials({});
            setCustomerEditCart(false);
          }}
          classes={classes}
          edit={() => {
            setCustomerEditConfirm(false);
            setCustomerDialogOpen(false);
            setCustomerEdit(true);
            setMenu({});
            setSpecials([]);
            setCart([]);
          }}
          customerEdit={true}
          customerEditCart={customerEditCart}
          callingNumber={callingNumber}
          phone={phone}
          country={country}
          countryCode={countryCode}
          countryName={country.name}
          onAddLocation={onAddLocation}
          defaultLocation={defaultLocation}
          defaultZone={defaultZone}
          client={customer}
          complex={complex}
          complexes={complexes}
          selectComplex={(_complexes) => {
            setComplexes([..._complexes]);
            setOpenSelectComplexDialog(true);
          }}
        />
      )}

      {customerEditConfirm && (
        <CustomerEditConfirmDialog
          open={customerEditConfirm}
          body={changeComplex ? "Changing a complex" : "Customer edit"}
          no={() => {
            if (changeComplex) {
              setOpenSelectComplexDialog(false);
            }

            setCustomerEditConfirm(false);
            setCustomerDialogOpen(false);
            setCustomerEdit(false);
            setPreviousOrders(previousOrders);
            setCart(cart);
            setMenu(menu);
            setSpecials(specials);
          }}
          yes={() => {
            if (cart && cart.find((f) => f.type === "voucher")) {
              const _item = cart.find((f) => f.type === "voucher");

              restoreVoucher(_item);
            }

            setCustomerEditConfirm(false);

            if (changeComplex) {
              setOpenSelectComplexDialog(true);
            } else {
              setCustomerDialogOpen(true);
              setCustomerEditCart(true);
              setPreviousOrders([]);
              setChangeMenu(true);
              setMenu({});
              setSpecials([]);
            }

            setCart([]);
          }}
        />
      )}

      {confirmCustomerDialogOpen && (
        <CustomerEditConfirmDialog
          open={confirmCustomerDialogOpen}
          newAddress={confirmCustomerDialogOpen}
          body={"You want to add a new address when one or more already exist"}
          no={() => {
            setCustomerEditConfirm(false);
            setCustomerDialogOpen(false);
            setConfirmCustomerDialogOpen(false);
          }}
          yes={() => {
            setCustomerDialogOpen(true);
            setConfirmCustomerDialogOpen(false);
          }}
        />
      )}

      {checkoutDialogOpen && (
        <CheckoutDialog
          orderResponse={orderResponse}
          complete={complete}
          customer={customer}
          user={user}
          country={country}
          orderNumber={orderNumber}
          complex={complex}
          myTotals={myTotals}
          setMyTotals={setMyTotals}
          phone={phone}
          version={version}
          instruction={instruction}
          tax={tax}
          cart={cart}
          setShowFeedback={setShowFeedback}
          setFeedback={setFeedback}
          currency={currency}
          availableCurrency={availableCurrency}
          updateCartItems={updateCartItems}
          classes={classes}
          open={checkoutDialogOpen}
          cartLoading={cartLoading}
          close={() => {
            setCheckoutDialogOpen(false);
          }}
          abandon={() => {
            const bu = window.location.origin + "/ordering";
            window.location.replace(bu);
          }}
          payments={payments}
          callerId={callingNumber}
          proceed={proceed}
          orderUUID={orderUUID}
          resetUUID={() => setOrderUUID("")}
          updateCart={(cart, removed) => {
            if (removed) {
              setRemovedTime(moment().format("YYYY-MM-DD HH:mm:ss"));
            }
            setCartUpdate(true);
            setCart([...cart]);
          }}
        />
      )}

      {successDialogOpen && (
        <CheckoutCompleteDialog
          orderNumber={orderNumber}
          name={customer.name}
          onClose={() => {
            setCheckoutDialogOpen(false);
            setSuccessDialogOpen(false);
            setIsCustomer(false);
            setCustomerDialogOpen(false);
            setPage("statistics");
            setCart([]);
            setCallingNumber("");
            setPhone("");
            setProceedMessage();
            setMenu({});
            setComplex();
            setComplexes([]);
            setOrderUUID("");
            setOrderNumber("");
            setCustomer();
            setUser();
          }}
          open={successDialogOpen}
          countryCode={countryCode}
        />
      )}

      {openResetPassword && (
        <ResetPassword
          open={openResetPassword}
          onClose={() => setOpenResetPassword(false)}
        />
      )}

      {openVoucherDialog && (
        <VouchersDialog
          open={openVoucherDialog}
          vouchers={vouchers}
          classes={classes}
          close={() => {
            setOpenVoucherDialog(false);
          }}
          addVoucher={addVoucher}
          enabled={enableVouchers}
        />
      )}

      {showFeedback && (
        <Feedback
          open={showFeedback}
          body={feedback}
          classes={classes}
          ok={() => {
            setShowFeedback(false);
          }}
        />
      )}

      {out_of_zone && (
        <OutOfZone
          open={out_of_zone}
          body="This address is out of zone, please add a new address!"
          classes={classes}
          ok={() => {
            setOutOfZone(false);
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#f4f4f4",
    minHeight: "100vh",
  },
  flex: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    flex: 1,
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 8,
    marginLeft: 0,
    cursor: "pointer",
  },
  card: {
    margin: 8,
    border: "1px solid gray",
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: 0,
  },
  cardCheckout: {
    margin: 0,
    border: "1px solid gray",
    backgroundColor: "#fff",
  },
  cardContent: {
    padding: "0px !important",
  },
  cardHeaderMain: {
    display: "flex",
    backgroundColor: "#474546",
    height: 48,
    paddingLeft: 24,
    color: "#fff",
    fontWeight: "bold",
  },
  cardHeader: {
    display: "flex",
    backgroundColor: "#474546",
    height: 30,
    paddingLeft: 24,
    paddingTop: 8,
    color: "#fff",
    fontWeight: "bold",
  },
  icon: {
    fontSize: "inherit",
    fontStyle: "inherit",
    padding: 0,
    margin: 0,
    marginBottom: -5,
    marginLeft: 2,
  },
  map: {
    position: "relative",
    height: "50vh",
  },
  button: {
    fontStyle: "italic",
    fontSize: 12,
    padding: 0,
    margin: 0,
  },
  container: {
    marginLeft: 10,
    marginRight: 15,
  },
  phone_search_root: {
    maxWidth: "500px",
  },
  phone_search_input: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    width: "calc(100% - 50%)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  actionBtn: {
    backgroundColor: "#04a300",
    color: "white",
    maxWidth: "500px",
    margin: "auto",
    borderRadius: 15,
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  btnIcon: {
    color: "#fff",
    fontSize: 64,
  },
  customerDialog: {
    backgroundColor: "#efeff0",
  },
  customerDialogInput: {
    paddingLeft: 8,
    marginTop: 8,
    marginBottom: 16,
    backgroundColor: "transparent",
    border: "thin solid #b8b8b8",
    boxShadow: "none",
    borderRadius: 8,
  },
  customerDialogInputError: {
    paddingLeft: 8,
    marginTop: 8,
    marginBottom: 16,
    backgroundColor: "transparent",
    border: "thin solid red",
    boxShadow: "none",
    borderRadius: 8,
  },
  ofzcustomerDialogInput: {
    paddingLeft: 8,
    marginTop: 8,
    marginBottom: 16,
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: 8,
  },
  ofzcustomerDialogInputError: {
    paddingLeft: 8,
    marginTop: 8,
    marginBottom: 16,
    backgroundColor: "transparent",
    border: "thin solid red",
    boxShadow: "none",
    borderRadius: 8,
  },
  formControl: {
    margin: -8,
    minWidth: 60,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  hover: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
}));

export default Dashboard;
